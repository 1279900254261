import styled from 'styled-components';

export const StyledCollapse = styled.div`
  width: 100%;

  .collapse-header {
    width: 100%;
    border-bottom: 1px solid var(--grayscale-100);

    & > .button {
      width: 100%;
      justify-content: flex-start;
      padding: 0px;
    }
  }

  .collapse-content {
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    & > div {
      padding: 16px 0px;
    }
  }
`;
