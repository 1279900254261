import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';

import { setPageLoading } from 'store/slices/pageLoading';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Loading from 'components/molecule/Loading';
import { AvailableLoadingTypes } from 'components/molecule/Loading/types';
import Button from 'components/molecule/Button';

import { DocLoadingsSectionType } from './types';
import { docLoadingsSectionSizes, docLoadingsSectionTypes } from './utils';

import { StyledLoadings } from './styles';
import { customThemeColors } from 'styles/global-styles';

const Loadings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const renderLoadingTypes = (
    section: DocLoadingsSectionType,
    index: number,
  ) => {
    const type = section.typeName as keyof typeof AvailableLoadingTypes;

    return (
      <div key={index} className="loadings-content">
        <Text as="h6" color="grayscale-200">
          {t(section.label)}
        </Text>

        <Loading type={type} />
      </div>
    );
  };

  const renderLoadingSizes = (
    size: string,
    index: number,
    withText: boolean,
  ) => {
    const text: string | undefined = withText ? 'Loading...' : undefined;

    return (
      <div key={index} className="loadings-content">
        <Loading width={size} height={size} text={text} />
      </div>
    );
  };

  const renderLoadingColors = (colorName: string, index: number) => {
    const color = colorName as keyof typeof customThemeColors;

    return (
      <div key={index} className="loadings-content">
        <Text as="h6" color="grayscale-200">
          {colorName}
        </Text>

        <Loading color={color} />
      </div>
    );
  };

  const setPageLoadingView = () => {
    dispatch(setPageLoading(true));

    setTimeout(() => {
      dispatch(setPageLoading(false));
    }, 3000);
  };

  return (
    <StyledLoadings>
      <Text as="h2" className="title">
        {t('Loadings')}
      </Text>

      <div className="doc-loadings-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Types')}
          </Text>
          <Separator />

          <div className="loadings-wrapper">
            {docLoadingsSectionTypes.map(renderLoadingTypes)}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Sizes')}
          </Text>
          <Separator />

          <div className="loadings-wrapper">
            {docLoadingsSectionSizes.map((size, index) =>
              renderLoadingSizes(size, index, false),
            )}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Sizes (with Text)')}
          </Text>
          <Separator />

          <div className="loadings-wrapper">
            {docLoadingsSectionSizes.map((size, index) =>
              renderLoadingSizes(size, index, true),
            )}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Colors')}
          </Text>
          <Separator />

          <div className="loadings-wrapper">
            {Object.keys(customThemeColors).map((colorName, index) =>
              renderLoadingColors(colorName, index),
            )}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Button loading')}
          </Text>
          <Separator />

          <div className="loadings-wrapper">
            <div className="loadings-content">
              <Button disabled>
                <Loading width="18px" height="18px" color="dark-color" />
              </Button>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Page Loading')}
          </Text>
          <Separator />

          <div className="loadings-wrapper">
            <Button onClick={setPageLoadingView}>{t('Show')}</Button>
          </div>
        </section>
      </div>
    </StyledLoadings>
  );
};

export default Loadings;
