import styled from 'styled-components';

export const StyledEditors = styled.div.attrs(() => ({
  className: 'editors-page',
}))`
  padding: 16px;

  & > .title {
    margin-bottom: 32px;
  }

  section {
    margin-bottom: 32px;

    .section-contents {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-wrap: wrap;

      .section-content {
        width: 40%;
        padding: 8px;
        border-radius: 8px;
        margin-right: 32px;

        & > h6 {
          margin-bottom: 8px;
        }

        .section-group {
          padding-bottom: 64px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .editor-wrapper {
    .code-preview {
      margin-top: 20px;

      & textarea {
        width: 400px;
        height: 100px;
      }
    }
  }
`;
