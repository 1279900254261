import React from 'react';

import Image from 'components/molecule/Image';
import Icon from 'components/atom/Icon';

import { AvailableIcons } from 'components/atom/Icon/types';
import { AvailableAvatarThemeTypes } from 'styles/avatar-themes';

import { StyledAvatar } from './styles';

interface AvatarProps {
  size?: string;
  src?: string;
  icon?: keyof typeof AvailableIcons;
  theme?: keyof typeof AvailableAvatarThemeTypes;
}

const Avatar: React.FC<AvatarProps> = ({
  size = '44px',
  src,
  icon = 'group-fill',
  theme = 'default',
}) => {
  return (
    <StyledAvatar className={`avatar ${theme}`} size={size}>
      {src && <Image src={src} />}
      {!src && (
        <>
          <div className="icon-avatar"></div>
          <Icon name={icon} />
        </>
      )}
    </StyledAvatar>
  );
};

export default Avatar;
