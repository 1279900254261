import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atom/Icon';
import Separator from 'components/atom/Separator';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';

import { StyledInputs } from './styles';

const Inputs: React.FC = () => {
  const { t } = useTranslation();

  const [input1Value, setInput1Value] = useState('');
  const [input2Value, setInput2Value] = useState('');
  const [input3Value, setInput3Value] = useState('');
  const [input4Value, setInput4Value] = useState('');

  const handleChangeInput1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput1Value(event.target.value);
  };
  const handleChangeInput2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput2Value(event.target.value);
  };
  const handleChangeInput3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput3Value(event.target.value);
  };
  const handleChangeInput4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput4Value(event.target.value);
  };

  return (
    <StyledInputs>
      <Text as="h2" className="title">
        {t('Inputs')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Inputs')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Input props')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  id="input-test-1"
                  icon="eye-fill"
                  label={t('Label test')}
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  value={input1Value}
                  onChange={handleChangeInput1}
                  limit={10}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Input with error')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  id="input-test-2"
                  icon="eye-fill"
                  label={t('Label test')}
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  value={input2Value}
                  onChange={handleChangeInput2}
                  hasError={true}
                  errorMessage={t('Error message')}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Disabled')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  id="input-test-2"
                  icon="eye-fill"
                  label={t('Label test')}
                  placeholder={t('Placeholder...')}
                  value={input2Value}
                  onChange={handleChangeInput2}
                  disabled
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Rounded')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  rounded="true"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('With placeholder as label')}
              </Text>
              <div className="section-group">
                <Input
                  id="placeholder-as-label"
                  type="text"
                  placeholder={t('Placeholder...')}
                  placeholderAsLabel
                  value={input3Value}
                  onChange={handleChangeInput3}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('With custom focused label')}
              </Text>
              <div className="section-group">
                <Input
                  id="custom-focused-label"
                  type="text"
                  placeholder={t('Placeholder...')}
                  focusedLabel={t('Custom focused label')}
                  placeholderAsLabel
                  value={input4Value}
                  onChange={handleChangeInput4}
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Default')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  hasError={true}
                  errorMessage={t('Error message')}
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  disabled
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Gray')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  theme="gray"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="gray"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="gray"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Post')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  theme="post"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="post"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="post"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Shadow')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  theme="shadow"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="shadow"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="shadow"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Secondary dark')}
              </Text>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  theme="secondary-dark"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  actions={
                    <Button theme="link-primary" size="small">
                      <Icon name="arrow-right-line" color="primary-color" />
                    </Button>
                  }
                  value="test"
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="secondary-dark"
                />
              </div>
              <div className="section-group">
                <Input
                  type="text"
                  icon="eye-fill"
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="secondary-dark"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledInputs>
  );
};

export default Inputs;
