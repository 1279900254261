import React from 'react';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import { AvailableIcons } from 'components/atom/Icon/types';

import { StyledSelectContentTypeItem } from './styles';

interface SelectContentTypeItemProps {
  icon: keyof typeof AvailableIcons;
  label: string;
}

const SelectContentTypeItem: React.FC<SelectContentTypeItemProps> = ({
  icon,
  label,
}) => {
  return (
    <StyledSelectContentTypeItem>
      <Icon name={icon} color="white-color" />
      <Text as="p" weight="bold" color="white-color">
        {label}
      </Text>
    </StyledSelectContentTypeItem>
  );
};

export default SelectContentTypeItem;
