import styled from 'styled-components';

export const StyledFinishAtFilter = styled.div`
  .datepicker-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .datepicker {
      width: 200px;
      margin-right: 16px;
    }
  }
`;
