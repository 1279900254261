import { Mask } from 'react-text-mask';

const MINUTES_MASK: Mask = [/[0-5]/, /[0-9]/];

export const get24hourTimeMask = (rawValue: string): Mask => {
  return rawValue.charAt(0) === '2'
    ? [/[0-2]/, /[0-3]/, ':', ...MINUTES_MASK]
    : [/[0-1]/, /[0-9]/, ':', ...MINUTES_MASK];
};

export const get12hourTimeMask = (): Mask => {
  return [/[0-1]/, /[0-9]/, ':', ...MINUTES_MASK, ' ', /[a|p]m/i];
};
