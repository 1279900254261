import styled from 'styled-components';

interface StyledTableProps {
  textAlign: string;
  scrollable?: boolean;
  fontColor: string;
}

export const StyledTable = styled.div<StyledTableProps>`
  width: 100%;
  position: relative;
  border-radius: 8px;

  .table-wrapper {
    width: 100%;
    overflow-x: ${({ scrollable }) => (scrollable ? 'scroll' : 'visible')};

    table {
      width: ${({ scrollable }) => (scrollable ? 'max-content' : '100%')};

      thead {
        border-bottom: 1px solid var(--gray-color);
        th {
          min-width: 100px;
          padding: 20px 8px;
          text-align: ${({ textAlign }) => textAlign};
          color: var(--${({ fontColor }) => fontColor});
          vertical-align: middle;

          &:first-child {
            padding-left: 16px;
          }

          &:last-child {
            padding-right: 16px;
          }

          .column-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > .button {
              padding: 0px;
              height: max-content;
              width: 24px;
              min-width: 24px;
            }
          }
        }
      }

      tbody {
        tr {
          transition: background 0.2s;
          td {
            padding: 20px 8px;
            text-align: ${({ textAlign }) => textAlign};
            color: var(--${({ fontColor }) => fontColor});
            border-bottom: 1px solid var(--gray-color);
            vertical-align: middle;

            &:first-child {
              padding-left: 16px;
            }

            &:last-child {
              padding-right: 16px;
            }
          }
        }
      }
    }

    table.clickable {
      tbody {
        tr:hover {
          background: var(--gray-color);
          cursor: pointer;
        }
      }
    }
  }

  .empty-message-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
  }

  .pagination-control {
    margin-top: 36px;
    display: flex;
    justify-content: center;
  }

  .table-loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    display: flex;
    align-items: center;

    background: linear-gradient(
      30deg,
      rgba(255, 255, 255, 0.2),
      rgba(220, 254, 217, 0.2),
      rgba(210, 209, 255, 0.2),
      rgba(255, 248, 188, 0.2)
    );
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
`;
