import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';

import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';

import { StyledToasters } from './styles';

type TypeSuccessToaster = {
  title: string;
  description?: string;
  theme?: string;
  icon?: string;
  hideCloseButton?: boolean;
};

const Toasters: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSuccessToaster = ({
    title,
    description = '',
    theme = 'warning',
    icon = '',
    hideCloseButton = false,
  }: TypeSuccessToaster) => {
    dispatch(
      showMessage({
        title: title,
        description: description,
        theme: theme,
        icon: icon,
        time: 10000,
        hideCloseButton: hideCloseButton,
      }),
    );
  };

  return (
    <StyledToasters>
      <Text as="h2" className="title">
        {t('Toasters')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Toasters themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Success Title')}
              </Text>
              <div className="section-group">
                <Button
                  theme="primary"
                  onClick={() => {
                    handleSuccessToaster({
                      title: t('Success Title'),
                      theme: 'success',
                    });
                  }}
                >
                  {t('Toaster success')}
                </Button>
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Alert title and descrition')}
              </Text>
              <div className="section-group">
                <Button
                  theme="primary"
                  onClick={() => {
                    handleSuccessToaster({
                      title: t('Success Title'),
                      description: t('Description success title'),
                    });
                  }}
                >
                  {t('Toaster Alert')}
                </Button>
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Toaster without close button')}
              </Text>
              <div className="section-group">
                <Button
                  theme="primary"
                  onClick={() => {
                    handleSuccessToaster({
                      title: t('Success Title'),
                      description: t('Description success title'),
                      hideCloseButton: true,
                    });
                  }}
                >
                  {t('Toaster without close button')}
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Danger + icon')}
              </Text>
              <div className="section-group">
                <Button
                  theme="primary"
                  onClick={() => {
                    handleSuccessToaster({
                      title: t('Danger'),
                      description: t('Description danger'),
                      theme: 'danger',
                      icon: 'close',
                    });
                  }}
                >
                  {t('Toaster Danger')}
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Danger 100')}
              </Text>
              <div className="section-group">
                <Button
                  theme="primary"
                  onClick={() => {
                    handleSuccessToaster({
                      title: t('Theme Danger 100'),
                      theme: 'danger-100',
                      description: t('Description danger 100'),
                      icon: 'close',
                    });
                  }}
                >
                  {t('Danger 100')}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledToasters>
  );
};

export default Toasters;
