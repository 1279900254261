import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAccessToken } from 'services/auth';

import AppLoading from 'components/organism/AppLoading';

import { StyledAuthLayout } from './styles';

interface AuthLayoutProps {
  children?: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const accessToken = getAccessToken();

  useEffect(() => {
    if (accessToken) {
      navigate('/dashboard');
    }
  }, [accessToken, navigate]);

  if (accessToken) {
    return <AppLoading removeAnimation />;
  }

  return (
    <StyledAuthLayout className="auth-layout">{children}</StyledAuthLayout>
  );
};

export default AuthLayout;
