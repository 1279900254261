import styled from 'styled-components';

export const StyledGamificationConfig = styled.div`
  width: 100%;

  .modal-title {
    margin-bottom: 20px;
  }

  .participation-points-wrapper {
    & > h6 {
      margin-top: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 32px;
    }

    .points-range {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .points-icons {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--gray-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .range-wrapper {
        flex: 1;
        padding: 0px 32px;
      }
    }

    .points-count {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      h6 {
        margin-right: 4px;
        min-width: 40px;
        text-align: end;
      }
    }

    .see-results-wrapper {
      margin: 32px 0px;

      display: flex;
      align-items: center;

      .switch-button {
        margin-right: 12px;
      }
    }

    .save-button {
      margin-top: 32px;
      width: 100%;
    }
  }
`;
