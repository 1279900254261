import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Select from 'components/molecule/Select';

import { SelectedOptionValueType } from 'components/molecule/Select/types';

import { StyledSelects } from './styles';
import {
  optionsDisable,
  optionsLeft,
  optionsRigth,
  optionsSearcheSelect,
  optionsSearcheMultiple,
} from './utils';

const Selects: React.FC = () => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] =
    useState<SelectedOptionValueType>(null);

  const [selectedOption2, setSelectedOption2] =
    useState<SelectedOptionValueType>(null);

  const [selectedOption3, setSelectedOption3] =
    useState<SelectedOptionValueType>(optionsDisable[0]);

  const [selectedOption4, setSelectedOption4] =
    useState<SelectedOptionValueType>(null);

  const [selectedOption5, setSelectedOption5] =
    useState<SelectedOptionValueType>(null);
  return (
    <StyledSelects>
      <Text as="h2" className="title">
        {t('Selects')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Default select')}
              </Text>
              <div className="section-group">
                <Select
                  value={selectedOption}
                  setValue={setSelectedOption}
                  options={optionsLeft}
                  className="select-1"
                  theme="default"
                  placeholder={
                    <Text color="grayscale-400">{t('Select...')}</Text>
                  }
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Dark select')}
              </Text>
              <div className="section-group">
                <Select
                  value={selectedOption2}
                  setValue={setSelectedOption2}
                  options={optionsRigth}
                  className="select-1"
                  theme="dark"
                  placeholder={
                    <Text color="grayscale-200">{t('Select...')}</Text>
                  }
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Disable')}
              </Text>
              <div className="section-group">
                <Select
                  value={selectedOption3}
                  setValue={setSelectedOption3}
                  options={optionsDisable}
                  className="select-1"
                  theme="gray"
                  isDisabled
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Search')}
              </Text>

              <div className="section-group">
                <Select
                  value={selectedOption4}
                  setValue={setSelectedOption4}
                  options={optionsSearcheSelect}
                  className="select-1"
                  theme="default"
                  isSearchable
                  placeholder={
                    <Text color="grayscale-400">{t('Select...')}</Text>
                  }
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Multiple')}
              </Text>

              <div className="section-group">
                <Select
                  value={selectedOption5}
                  setValue={setSelectedOption5}
                  options={optionsSearcheMultiple}
                  className="select-1"
                  theme="default"
                  isSearchable
                  isMulti
                  placeholder={
                    <Text color="grayscale-400">{t('Select...')}</Text>
                  }
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledSelects>
  );
};

export default Selects;
