import styled from 'styled-components';

export const StyledTabMenu = styled.div`
  background: var(--gray-color);
  padding: 6px;
  border-radius: 100px;

  display: flex;
  align-items: center;

  .button {
    min-width: 90px;
    transition: all 0.2s;

    &.active {
      cursor: initial;
      pointer-events: none;
      border: none;
    }

    :active {
      border-color: var(--white-color);
    }
  }
`;
