import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { setTheme } from 'store/slices/theme';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Card from 'components/atom/Card';
import Select from 'components/molecule/Select';
import Button from 'components/molecule/Button';
import PageLoading from 'components/molecule/PageLoading';

import {
  SelectOption,
  SelectedOptionValueType,
} from 'components/molecule/Select/types';

import { AvailableColorTypes } from 'styles/global-styles';

import { StyledColors } from './styles';
import {
  docColorsSection,
  DocIconSectionType,
  DocItemColor,
  DocSubSectionItemColor,
} from './utils';

const options: readonly SelectOption[] = [
  {
    value: 'useflow-tech',
    label: (
      <Button size="small" theme="link-primary">
        Useflow Tech
      </Button>
    ),
  },
  {
    value: 'flow-vintage',
    label: (
      <Button size="small" theme="link-primary">
        Flow Vintage
      </Button>
    ),
  },
];

const Colors: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const useflowTechTheme = {
    primary_color_100: '#D2D1FF',
    primary_color_200: '#4746D8',
    primary_color_300: '#363AA3',
    primary_color_400: '#1C2359',
    secondary_color_100: '#FFAB2C',
    secondary_color_200: '#FF7A00',
    tertiary_color_1: '#6DDC47',
    tertiary_color_2: '#FF5348',
    tertiary_color_3: '#EF6B8A',
    support_pastel_color_1: '#FAF3EA',
    support_pastel_color_2: '#8F52CC',
  };

  const flowVintageTheme = {
    primary_color_100: '#c4ebe9',
    primary_color_200: '#52B3AF',
    primary_color_300: '#68B194',
    primary_color_400: '#2b664e',
    secondary_color_100: '#80A0F7',
    secondary_color_200: '#2b58d3',
    tertiary_color_1: '#6DDC47',
    tertiary_color_2: '#FF5348',
    tertiary_color_3: '#EF6B8A',
    support_pastel_color_1: '#FAF3EA',
    support_pastel_color_2: '#8F52CC',
  };

  const [selectedOption, setSelectedOption] = useState<SelectedOptionValueType>(
    options[0],
  );
  const [showLoading, setShowLoading] = useState(false);

  const handleChange = (option: SelectedOptionValueType) => {
    setShowLoading(true);

    setTimeout(() => {
      if (option && 'value' in option && option.value === 'useflow-tech') {
        dispatch(setTheme(useflowTechTheme));
      }
      if (option && 'value' in option && option.value === 'flow-vintage') {
        dispatch(setTheme(flowVintageTheme));
      }

      setShowLoading(false);
    }, 200);
  };

  return (
    <StyledColors>
      <Text as="h2" className="title">
        {t('Colors')}
      </Text>

      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Change theme')}
          </Text>
          <Separator />
          <div className="section-contents">
            <div className="section-content">
              <div className="section-group">
                <Select
                  value={selectedOption}
                  setValue={setSelectedOption}
                  options={options}
                  onChange={handleChange}
                  className="select-1"
                  theme="default"
                  placeholder={
                    <Text color="grayscale-400">{t('Select...')}</Text>
                  }
                />
              </div>
            </div>
          </div>
        </section>

        {docColorsSection.map((section: DocIconSectionType) => (
          <section key={section.label}>
            <Text as="h4" className="section-title">
              {t(section.label)}
            </Text>
            <Separator />
            <div className="section-contents">
              {section.colorsItems.map((subSection: DocSubSectionItemColor) => (
                <div key={subSection.label} className="item">
                  {subSection.items.map((item: DocItemColor) => (
                    <div key={item.label} className="section-content">
                      <Text as="h6" color="grayscale-200">
                        {t(item.label)}
                      </Text>
                      <div className="section-group">
                        <Card
                          color={item.color as keyof typeof AvailableColorTypes}
                        ></Card>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </section>
        ))}

        {showLoading && <PageLoading />}
      </div>
    </StyledColors>
  );
};

export default Colors;
