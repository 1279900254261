import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import FilterItem from '../FilterItem';
import Button from 'components/molecule/Button';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';

import { StyledStatusFilter } from './styles';

export interface StatusFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const totalFilterItems = 6;

  const [isOpen, setIsOpen] = useState(filter.status.length > 0);

  const handleClear = () => {
    const newFilter = { ...filter, status: [] };
    setFilter(newFilter);
  };

  const handleCheckAll = () => {
    const newFilter = {
      ...filter,
      status: [
        'active',
        'scheduled',
        'draft',
        'finished',
        'canceled',
        'finishing',
      ],
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleToggleSelection = (select: boolean, item: string) => {
    const newStatus = [...filter.status];

    if (select) {
      newStatus.push(item);
    } else {
      newStatus.splice(newStatus.indexOf(item), 1);
    }

    setFilter({ ...filter, status: newStatus });
  };

  return (
    <StyledStatusFilter>
      <FilterItem
        title={t('Status')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={isOpen && filter.status.length > 0}
        onClear={handleClear}
        showCheckAllButton={isOpen && filter.status.length < totalFilterItems}
        onCheckAll={handleCheckAll}
      >
        <div className="button-list">
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.status.indexOf('active') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(
                filter.status.indexOf('active') === -1,
                'active',
              )
            }
          >
            {t('Active')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.status.indexOf('scheduled') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.status.indexOf('scheduled') === -1,
                'scheduled',
              )
            }
          >
            {t('Scheduled')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.status.indexOf('draft') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(
                filter.status.indexOf('draft') === -1,
                'draft',
              )
            }
          >
            {t('Draft')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.status.indexOf('finishing') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.status.indexOf('finishing') === -1,
                'finishing',
              )
            }
          >
            {t('Finishing')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.status.indexOf('finished') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.status.indexOf('finished') === -1,
                'finished',
              )
            }
          >
            {t('Finished')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.status.indexOf('canceled') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.status.indexOf('canceled') === -1,
                'canceled',
              )
            }
          >
            {t('Canceled')}
          </Button>
        </div>
      </FilterItem>
    </StyledStatusFilter>
  );
};

export default StatusFilter;
