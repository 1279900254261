import React, { useRef, useState, useEffect } from 'react';

import Button from 'components/molecule/Button';

import { AvailableThemeTypes } from 'styles/button-themes';

import { StyledCollapse } from './styles';

interface CollapseProps {
  isOpen: boolean;
  setIsOpen: () => void;
  children: React.ReactNode;
  collapseHeader: React.ReactNode;
  headerButtonTheme?: keyof typeof AvailableThemeTypes;
}

const Collapse: React.FC<CollapseProps> = ({
  isOpen,
  setIsOpen,
  children,
  collapseHeader,
  headerButtonTheme = 'link-dark',
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number | undefined>(0);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <StyledCollapse className="collapse">
      <div className="collapse-header">
        <Button theme={headerButtonTheme} size="small" onClick={setIsOpen}>
          {collapseHeader}
        </Button>
      </div>
      <div className="collapse-content" style={{ height }}>
        <div ref={ref}>{children}</div>
      </div>
    </StyledCollapse>
  );
};

export default Collapse;
