import React from 'react';
import { useTranslation } from 'react-i18next';

import { appName, appVersion, environment } from 'settings';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { StyledFooter } from './styles';

interface FooterProps {
  organizationName: string;
}

const Footer: React.FC<FooterProps> = ({ organizationName }) => {
  const { t } = useTranslation();

  const environmentLabel = environment !== 'prod' ? `(${environment})` : '';

  return (
    <StyledFooter background={environmentLabel ? 'danger-color-300' : 'none'}>
      <div className="icon-wrapper">
        <Icon name="logo" />
      </div>
      <div className="text-wrapper">
        <Text as="small" weight="bold" color="white-color">
          {organizationName}
        </Text>
        <Text as="small" color="white-color">
          {t('Using')} {appName} v{appVersion} {environmentLabel}
        </Text>
      </div>
    </StyledFooter>
  );
};

export default Footer;
