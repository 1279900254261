import styled from 'styled-components';

export const StyledMuralTableContent = styled.div`
  display: flex;
  align-items: center;

  .image {
    margin-right: 12px;
    min-width: 124px;
  }

  .texts {
    .title {
      display: -webkit-box;
      max-width: 264px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .description {
      margin-top: 4px;
    }
  }
`;
