import styled from 'styled-components';

export const StyledMessageSurveyContent = styled.div`
  .card {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;

    .icon {
      margin-right: 6px;
    }
  }

  .title {
    margin-bottom: 16px;
    .input-content {
      padding: 0px;

      input {
        font-size: 38px;
      }
    }
  }

  .description {
    .textarea-content {
      padding: 0px;
    }
  }
`;
