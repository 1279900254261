import { useCallback, useEffect } from 'react';

import { CRISP_ID } from 'settings';

import {
  CRISP_SCRIPT_URL,
  isCrispChatLoaded,
  isCrispScriptLoaded,
} from 'hooks/general/useCrispConfig/utils';

import { useAppSelector } from 'store/hooks';

import { getISO6391Code } from 'utils/language';

import { CrispConfig, WindowWithCrisp } from './types';

// "injects" Crisp config on Window locally
// when Crisp is loaded by their JS, it creates some variables in runtime
// some of them we will use, so we should type it to TS does not cry :P
declare const window: WindowWithCrisp;

const useCrispConfig = () => {
  const client = useAppSelector((state) => state.client);
  const theme = useAppSelector((state) => state.organization.color_scheme);

  const setCrispScript = () => {
    if (isCrispScriptLoaded()) return;

    // see: https://github.com/Mergifyio/react-crisp/blob/master/src/Crisp.jsx
    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = CRISP_SCRIPT_URL;
    script.async = true;
    document.head.appendChild(script);
  };

  const setCrispConfig = (crispConfig: CrispConfig): void => {
    // should we set CRISP on safe mode?
    // safe mode disables warnings and errors
    if (window.$crisp !== undefined) {
      window.$crisp.push(['safe', crispConfig.safeMode]);
    }

    // exposed CRISP configs
    if (!window.CRISP_WEBSITE_ID) {
      window.CRISP_WEBSITE_ID = crispConfig.crispId;
    }
    window.CRISP_RUNTIME_CONFIG = crispConfig.runtimeConfig;

    // if it is already loaded, we should only reload state of chat
    // this avoids multiple re-renderings of CRISP chat,
    // and it will refresh CRISP with our defined settings in runtime
    if (isCrispChatLoaded() && window.$crisp) {
      window.$crisp.push(['do', 'session:reset']);
    }
  };

  const loadCrisp = useCallback((config: CrispConfig): void => {
    // runtime config should be first
    setCrispConfig(config);

    // then we should set script
    setCrispScript();
  }, []);

  useEffect(() => {
    const language: string = client?.language || 'en-us';

    if (language) {
      loadCrisp({
        crispId: CRISP_ID,
        safeMode: true,
        runtimeConfig: {
          locale: getISO6391Code(language),
        },
      });
    }
  }, [client, theme, loadCrisp]);

  return null;
};

export default useCrispConfig;
