import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/molecule/Dropdown';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { StyledMuralTableActions } from './styles';

interface MuralTableActionsProps {
  contentId: string;
  contentType: string;
  contentSubtype: string;
}

const MuralTableActions: React.FC<MuralTableActionsProps> = ({
  contentId,
  contentType,
  contentSubtype,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <StyledMuralTableActions>
      <Dropdown
        open={open}
        setOpen={setOpen}
        toggleElement={<Icon name="more-2" />}
        toggleButtonTheme="link-page-button-gray-primary"
        dropdownContentColor="dark-color"
        contentPlace="right"
      >
        <Button
          theme="dark"
          leftIcon="pencil-fill"
          radius="0px"
          to={`/mural/content/${contentType}/${contentSubtype}/${contentId}`}
        >
          {t('Edit')}
        </Button>
        {/* <Button theme="dark" leftIcon="delete-bin-6-fill" radius="0px">
          {t('Delete')}
        </Button>
        <Button theme="dark" leftIcon="send" radius="0px">
          {t('Publish')}
        </Button> */}
      </Dropdown>
    </StyledMuralTableActions>
  );
};

export default MuralTableActions;
