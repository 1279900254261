import styled from 'styled-components';

export const StyledButtons = styled.div`
  padding: 16px;

  div > .text {
    margin-bottom: 16px;
  }

  .button {
    margin-bottom: 8px;
  }

  .title {
    margin-bottom: 32px;
  }

  section {
    margin-bottom: 32px;

    .section-contents {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-wrap: wrap;

      .section-content {
        padding: 8px;
        border-radius: 8px;
        margin-right: 32px;
        margin-bottom: 16px;

        .section-group {
          display: flex;
          align-items: center;

          .button {
            margin-right: 8px;
          }
        }
      }
    }
  }
`;
