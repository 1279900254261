import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  CircleF,
  GoogleMap,
  MarkerF,
  useLoadScript,
} from '@react-google-maps/api';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import Loading from 'components/molecule/Loading';

import { GOOGLE_MAPS_API_KEY } from 'settings';
import {
  mapContainerStyle,
  mapLoaderLibraries,
  mapOptions,
  MAP_DEFAULT_CENTER,
  markerCircleOptions,
  markerOptions,
} from './settings';
import { StyledMap } from './styles';
import { Location } from './types';
import {
  convertPlaceResultToLocation,
  getGoogleMapLanguageCode,
  getGoogleMapRegionCode,
  getPlaceResultsFromQuery,
  removeExtraPacContainers,
} from './utils';

interface MapProps {
  id?: string;
  className?: string;
  zoomControls?: boolean;
  searchControls?: boolean;
  markerCircleRadius?: number;
  location?: Location | null;
  onChangeLocation?: (location: Location) => void;
  disabled?: boolean;
}

const Map: React.FC<MapProps> = ({
  id,
  className,
  zoomControls = true,
  searchControls = true,
  markerCircleRadius = 50,
  location = null,
  onChangeLocation,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  const { isLoaded, loadError } = useLoadScript({
    id: `map-${i18n.language}`,
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: mapLoaderLibraries,
    preventGoogleFontsLoading: false,
    language: getGoogleMapLanguageCode(i18n.language),
    region: getGoogleMapRegionCode(i18n.language),
  });

  /*
    REACT STATES: Customized controls
  */

  const [searchAddressInputValue, setSearchAddressInputValue] =
    useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    location,
  );
  const [initialZoom, setMapInitialZoom] = useState<number>(5);

  /*
    REACT STATES: Google Map components
  */

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);

  /*
    CALLBACKS: Customized controls
  */

  const handleManualQueryPlace = async () => {
    // see: https://github.com/JustFly1984/react-google-maps-api/issues/2331
    const autoCompleteInputElement: HTMLInputElement =
      document.getElementsByClassName(
        'pac-target-input',
      )[0] as HTMLInputElement;

    const results = document.getElementsByClassName('pac-item-query');
    if (!results.length) return;

    // let's get first typed-autocompleted text & predicted address
    const completedText: string = results[0].textContent || '';
    const predictedText: string = results[0].nextSibling?.textContent || '';

    // then, we will replace autocomplete input with those texts
    const firstSuggestionText: string = predictedText
      ? `${completedText}, ${predictedText}`
      : completedText;
    autoCompleteInputElement.value = firstSuggestionText;
    autoCompleteInputElement.blur();

    // with input filled, we will query again Google to get result data
    const foundPlaces = await getPlaceResultsFromQuery(firstSuggestionText);
    if (foundPlaces && foundPlaces.length) {
      handleUpdateSelectedLocation(foundPlaces[0]);
    }
  };

  const handleManualPointPlace = (latLng: google.maps.LatLng | null) => {
    if (!latLng) return;

    // as user pointed it somewhere, we just have Lat/Lng to build Location...
    const lat: number = latLng.lat();
    const lng: number = latLng.lng();
    const currentLocation = `${lat.toFixed(5)}, ${lng.toFixed(5)}`;
    const newLocation: Location = { lat, lng, location: currentLocation };

    // ...and then use it to update address and others related to location
    setSearchAddressInputValue(newLocation.location);
    setSelectedLocation(newLocation);
    if (onChangeLocation) {
      onChangeLocation(newLocation);
    }
  };

  const handleChangeMapZoomLevel = (scaleFactor: number) => {
    const zoom: number | undefined = map?.getZoom();
    if (zoom) map?.setZoom(zoom + scaleFactor);
  };

  const handleUpdateSelectedLocation = (
    place: google.maps.places.PlaceResult | null,
  ) => {
    // fit map center bounds to show selected location
    const lat: number | undefined = place?.geometry?.location?.lat();
    const lng: number | undefined = place?.geometry?.location?.lng();
    if (map && lat && lng && map.getCenter()) {
      map.setCenter({ lat, lng });
      map.setZoom(18);
    }

    // we have a google api PlaceResult, but we need an compatible Location obj
    const currentLocation: Location | null =
      convertPlaceResultToLocation(place);
    if (currentLocation) {
      setSelectedLocation(currentLocation);
      setSearchAddressInputValue(currentLocation.location);
      if (onChangeLocation) {
        onChangeLocation(currentLocation);
      }
    }
  };

  /*
    CALLBACKS: Google Map components
  */
  const setMapPosition = React.useCallback(
    (map: google.maps.Map, position: google.maps.LatLngLiteral) => {
      map.fitBounds(
        new window.google.maps.LatLngBounds(selectedLocation || position),
      );

      const zoom: number | undefined = map.getZoom();
      if (zoom) map.setZoom(zoom - 3);

      setMap(map);
    },
    [selectedLocation],
  );

  const onLoadMap = React.useCallback(
    (map: google.maps.Map) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentCenter = { lat: latitude, lng: longitude };

          setMapPosition(map, currentCenter);
        },
        () => {
          setMapPosition(map, MAP_DEFAULT_CENTER);
        },
      );

      // fixes: https://github.com/JustFly1984/react-google-maps-api/issues/3087
      setTimeout(() => setMapInitialZoom(17), 1000);
    },
    [setMapPosition],
  );

  const onUnmountMap = React.useCallback(() => {
    setMap(null);
  }, []);

  const handleMouseEventChangeLocation = (
    mapsEvent: google.maps.MapMouseEvent,
  ) => {
    handleManualPointPlace(mapsEvent.latLng);
  };

  const handleCircleOnUnmount = () => setCircle(null);

  const handleCircleOnLoad = (circle: google.maps.Circle) => setCircle(circle);

  const handleAutocompleteOnUnmount = () => setAutoComplete(null);

  const handleAutocompleteOnLoad = (ref: google.maps.places.Autocomplete) => {
    removeExtraPacContainers();
    setAutoComplete(ref);
  };

  const handleAutocompleteOnPlaceChanged = async () => {
    if (!autoComplete) return;

    const place: google.maps.places.PlaceResult = autoComplete.getPlace();
    const autoCompleteInputElement: HTMLInputElement =
      document.getElementsByClassName(
        'pac-target-input',
      )[0] as HTMLInputElement;

    // user clicked in an result from autocomplete?
    if (place.place_id !== undefined) {
      // we should just handle update of selected location normally
      autoCompleteInputElement.blur();
      handleUpdateSelectedLocation(place);
      return;
    }

    // if we pass to here, it means user pressed ENTER instead of select result
    // so, we will read input from search and do a manual query to get location
    await handleManualQueryPlace();
  };

  useEffect(() => {
    if (location && map?.getCenter()) {
      setSelectedLocation(location);
      map?.setCenter({ lat: location?.lat, lng: location?.lng });
      setSearchAddressInputValue(location.location);
    }
  }, [location, map]);

  // see: https://react-google-maps-api-docs.netlify.app/#!/useLoadScript
  const renderMap: JSX.Element = (
    <>
      {zoomControls && map && (
        <div className="map-zoom-controls">
          <Button
            className="button-zoom-in"
            onClick={() => handleChangeMapZoomLevel(1)}
            size="small"
          >
            <Icon name="add" />
          </Button>
          <Button
            className="button-zoom-out"
            onClick={() => handleChangeMapZoomLevel(-1)}
            size="small"
          >
            <Icon name="less" />
          </Button>
        </div>
      )}

      <GoogleMap
        id={'google-map' || `${id}-map`}
        mapContainerClassName="map-container"
        mapContainerStyle={mapContainerStyle}
        options={mapOptions}
        center={MAP_DEFAULT_CENTER}
        zoom={initialZoom}
        onLoad={onLoadMap}
        onUnmount={onUnmountMap}
        // onClick={handleMouseEventChangeLocation}
      >
        {markerCircleRadius && (
          <CircleF
            radius={markerCircleRadius}
            options={markerCircleOptions}
            onLoad={handleCircleOnLoad}
            onUnmount={handleCircleOnUnmount}
          />
        )}
        {selectedLocation !== null && (
          <MarkerF
            position={{
              lat: selectedLocation.lat,
              lng: selectedLocation.lng,
            }}
            options={markerOptions}
            onPositionChanged={() => {
              setSelectedLocation((lastState) => {
                if (lastState) {
                  circle?.setCenter({
                    lat: lastState.lat,
                    lng: lastState.lng,
                  });
                }
                return lastState;
              });
            }}
            onDragEnd={handleMouseEventChangeLocation}
          />
        )}
      </GoogleMap>

      {searchControls && map && (
        <div className="map-search-controls">
          <Autocomplete
            onLoad={handleAutocompleteOnLoad}
            onUnmount={handleAutocompleteOnUnmount}
            onPlaceChanged={handleAutocompleteOnPlaceChanged}
          >
            <Input
              id="search-address-input"
              placeholder={t('Address')}
              value={searchAddressInputValue}
              onChange={(evt) => setSearchAddressInputValue(evt.target.value)}
              onFocus={() => removeExtraPacContainers()}
              disabled={disabled}
            />
          </Autocomplete>
          <Button
            id="search-check-button"
            onClick={() => handleManualQueryPlace()}
            disabled={disabled}
          >
            {t('Check')}
          </Button>
        </div>
      )}
    </>
  );
  return (
    <StyledMap className={`map ${className}`}>
      <div className="map-content">
        {isLoaded ? renderMap : <Loading text={t('Loading map...')} />}

        {loadError && (
          <Text align="center" as="p">
            {t('Could not load map. Please try again later.')}
          </Text>
        )}
      </div>
    </StyledMap>
  );
};

export default memo(Map);
