export const schedulerInitialState = {
  scheduler_start_date_at: {
    value: undefined,
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  scheduler_finish_date_at: {
    value: undefined,
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  content_start_time_at: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  content_finish_time_at: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  content_duration: {
    value: '0',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  content_week_days: {
    value: [],
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  scheduler_var: {
    value: '1',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  scheduler_type: {
    value: 'days',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
};
