import styled from 'styled-components';

export const StyledRange = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 28px;

  .outside-track {
    height: 32px;
    display: flex;
    width: 100%;

    .inside-track {
      height: 12px;
      width: 100%;
      border-radius: 22px;
      align-self: center;
    }
  }

  .thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white-color);
    outline: none;
  }

  &.large {
    .outside-track {
      .inside-track {
        height: 32px;
      }
    }

    .thumb {
      border-radius: 28px;
      height: 32px;
      width: 50px;
      border: none;
      left: -1px;
    }
  }
`;
