import styled from 'styled-components';

export const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);

  h3 {
    margin-right: 12px;
  }

  .page-content {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
