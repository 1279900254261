import styled from 'styled-components';

export const StyledEmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 248px;
  text-align: center;

  .icon {
    font-size: 22px;
    border: 1px solid var(--grayscale-100);
    border-radius: 50%;
    padding: 8px;
    margin-bottom: 20px;
  }

  .image {
    width: 170px;
    margin-bottom: 20px;
  }

  .description {
    margin-top: 4px;
  }

  .empty-message-content {
    margin-top: 20px;
  }
`;
