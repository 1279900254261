import styled from 'styled-components';

export const StyledIcons = styled.div`
  padding: 16px;

  div > .text {
    margin-bottom: 16px;
  }

  .section-title {
    margin-top: 32px;
  }

  .subsection-title {
    margin-top: 32px;
    margin-bottom: 24px !important;
  }

  .section-wrapper {
    margin-bottom: 20px;
  }

  .subsection-wrapper {
    display: flex;
    flex-direction: column;

    .subsection-content {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  .icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .icon-content {
      min-width: 200px;
      text-align: center;
      margin: 20px;

      .icon-name {
        margin-top: 5px;
      }
    }
  }
`;
