import styled from 'styled-components';

export const StyledOrganizationSelect = styled.div`
  max-width: 312px;

  .dropdown {
    width: 100%;

    &:not(.open) {
      overflow: hidden;
    }

    .dropdown-toggle {
      width: 100%;
      height: 71px;
      justify-content: flex-start;
      padding: 0px;
      border-radius: 0;

      * {
        color: var(--grayscale-400);
      }

      &:hover {
        background: var(--grayscale-100);
        * {
          color: var(--grayscale-400);
        }
      }

      #button-left-icon,
      #button-right-icon {
        display: none;
      }

      & > div {
        width: 100%;
        padding-right: 24px;
      }

      .selected-organization {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .organization-item {
          border: none;
          h6 {
            display: -webkit-box;
            max-width: 200px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .loading {
          width: 100%;
        }
      }

      .organization-item {
        pre {
          max-width: 22ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .dropdown-content {
      width: 100%;
      box-shadow: none;
    }
  }

  &.no-clickable {
    .dropdown-toggle {
      cursor: initial;
      &:hover {
        background: var(--gray-color);
      }
    }
  }

  .select {
    .react-select {
      .react-select__control {
        width: 100%;
        max-width: 312px;
        border-radius: 0px;
        height: 52px;
        max-height: 52px;
        padding: 0px;
        padding-right: 16px;
        border-color: var(--gray-color);
        border-top: 1px solid var(--grayscale-100);
        border-bottom: 1px solid var(--grayscale-100);
        background: var(--gray-color);
        cursor: text;

        .search-placeholder {
          display: flex;
          align-items: center;

          .icon {
            font-size: 18px;
            margin-right: 10px;
          }
        }

        .react-select__value-container {
          .react-select__input-container {
            padding-left: 20px;
          }

          .react-select__placeholder {
            padding-left: 20px;
          }
        }

        .react-select__indicators {
          display: none;
        }
      }

      .react-select__menu {
        margin: 0px;
        border-radius: 0px;
        box-shadow: none;

        .react-select__menu-list {
          padding: 0px;
          max-height: calc(100vh - 123px);
        }

        .organization-item {
          pre {
            max-width: 28ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
`;
