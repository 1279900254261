import React from 'react';

import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import { SelectOption } from 'components/molecule/Select/types';

import { EditorialProps } from 'apis/board/types';
import { parseSvg } from 'utils/parsers';
import { StyledEditorialIconContainer } from './styles';

export const getEditorialSelectLabel = (
  label: string,
  iconSvgCode: string,
  iconColor: string,
  backgroundColor: string,
) => {
  return (
    <Button
      className="editorial-select-option"
      theme="link-gray-primary"
      size="small"
    >
      <StyledEditorialIconContainer
        iconColor={iconColor}
        backgroundColor={backgroundColor}
      >
        {parseSvg(iconSvgCode)}
      </StyledEditorialIconContainer>

      <Text>{label}</Text>
    </Button>
  );
};

export const editorialSelectOptionFactory = (
  editorials: EditorialProps[] | null | undefined,
): SelectOption[] => {
  if (!editorials) return [];

  return editorials.map((editorial: EditorialProps) => ({
    value: editorial.id,
    label: getEditorialSelectLabel(
      editorial.title,
      editorial.icon,
      editorial.color,
      '#FFFFFF',
    ),
  }));
};
