import styled from 'styled-components';

export const StyledDirectPollConfig = styled.div`
  width: 100%;

  .direct-poll-header {
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grayscale-100);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin-bottom: 24px;
  }

  .textarea {
    margin-bottom: 24px;
  }

  .button {
    width: 100%;
  }
`;
