import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Alert from 'components/molecule/Alert';
import Button from 'components/molecule/Button';

import { StyledAlerts } from './styles';

const Alerts: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAlerts>
      <Text as="h2" className="title">
        {t('Alerts')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Alert themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Alert warning')}
              </Text>
              <div className="section-group">
                <Alert
                  icon="pin-side"
                  title="Alert title"
                  description="Alert description"
                >
                  <Button theme="link-danger" size="small">
                    Action
                  </Button>
                  <Button theme="link-primary" size="small">
                    Action
                  </Button>
                </Alert>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Alert success')}
              </Text>
              <div className="section-group">
                <Alert
                  icon="check"
                  title="Alert title"
                  description="Alert description"
                  theme="success"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Alert danger')}
              </Text>
              <div className="section-group">
                <Alert
                  icon="close"
                  title="Alert title"
                  description="Alert description"
                  theme="danger"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Alert danger 100')}
              </Text>
              <div className="section-group">
                <Alert
                  icon="close"
                  title="Alert title"
                  description="Alert description"
                  theme="danger-100"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledAlerts>
  );
};

export default Alerts;
