import { SegmentationOptionProps, SegmentationValueTypes } from './types';

export const getSegmentationDataValues = (
  type: string,
  values: SegmentationValueTypes,
) => {
  if (type === 'objects' || type === 'boolean') {
    const currentValues = values as SegmentationOptionProps[];
    return currentValues.map((item) => item.key) as string[];
  }
  return values as string[];
};
