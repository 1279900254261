import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import { MuralTableStatusProps } from './types';

import { StyledMuralTableStatus } from './styles';

const MuralTableStatus: React.FC<MuralTableStatusProps> = ({
  status,
  period,
}) => {
  const { t } = useTranslation();

  const getStatusLabel = () => {
    if (status === 'scheduled') {
      return (
        <div className="status-label">
          <Icon name="calendar-2-fill" />
          <Text color="grayscale-400" weight="700">
            {t('Scheduled')}
          </Text>
        </div>
      );
    }

    if (status === 'active') {
      return (
        <div className="status-label">
          <Icon name="circle" />
          <Text color="grayscale-400" weight="700">
            {t('Active')}
          </Text>
        </div>
      );
    }

    if (status === 'finished') {
      return (
        <div className="status-label">
          <Icon name="check-double" />
          <Text color="grayscale-400" weight="700">
            {t('Finished')}
          </Text>
        </div>
      );
    }

    if (status === 'finishing') {
      return (
        <div className="status-label">
          <Icon name="check" />
          <Text color="grayscale-400" weight="700">
            {t('Finishing')}
          </Text>
        </div>
      );
    }

    if (status === 'canceled') {
      return (
        <div className="status-label">
          <Icon name="close" />
          <Text color="grayscale-400" weight="700">
            {t('Canceled')}
          </Text>
        </div>
      );
    }

    if (status === 'draft') {
      return (
        <div className="status-label">
          <Icon name="pencil-fill" />
          <Text color="grayscale-400" weight="700">
            {t('Draft')}
          </Text>
        </div>
      );
    }
  };

  return (
    <StyledMuralTableStatus>
      {getStatusLabel()}
      <Text color="grayscale-200" className="period">
        {period}
      </Text>
    </StyledMuralTableStatus>
  );
};

export default MuralTableStatus;
