import styled from 'styled-components';

export const StyledSegmentationEditor = styled.div`
  .title {
    margin-bottom: 8px;
  }

  .subtitle {
    margin-bottom: 16px;
  }

  .segmentation-content {
    padding: 32px 0px;
  }

  .segmentation-actions {
    padding: 24px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }

    .left-actions {
      button:first-child {
        padding: 0px;
      }
    }
  }

  .loading {
    margin-top: 32px;
  }
`;
