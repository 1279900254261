import styled from 'styled-components';

export const StyledSearch = styled.div`
  width: 100%;

  .dropdown {
    .button {
      width: 100%;
      height: max-content;
      padding: 0px;
      padding-bottom: 8px;

      .button-content {
        width: 100%;
      }
    }

    .dropdown-content {
      width: 100%;
      padding: 0px;
      height: max-content;
      background: var(--white-color);

      .results-wrapper {
        max-height: 300px;
        overflow-y: scroll;

        .button {
          border-color: var(--grayscale-100) !important;
          border-bottom: 1px solid var(--grayscale-100) !important;
          padding: 16px;
          .icon {
            display: none;
          }

          &:last-child {
            border: none;
          }

          .button-content {
            text-align: start;
          }
        }

        h6 {
          text-align: center;
          padding: 16px;
        }

        .loading {
          max-height: 50px;
          overflow: hidden;
        }
      }
    }
  }
`;
