import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/molecule/Checkbox';
import FilterItem from '../FilterItem';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';

import { StyledPublicationFilter } from './styles';

export interface PublicationFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const PublicationFilter: React.FC<PublicationFilterProps> = ({
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  const totalFilterItems = 3;

  const [isOpen, setIsOpen] = useState(filter.publication.length > 0);

  const handleCheck = (checked: boolean, item: string) => {
    const newPublication = [...filter.publication];

    if (checked) {
      newPublication.push(item);
    } else {
      newPublication.splice(newPublication.indexOf(item), 1);
    }

    setFilter({ ...filter, publication: newPublication });
  };

  const handleClear = () => {
    const newFilter = { ...filter, publication: [] };
    setFilter(newFilter);
  };

  const handleCheckAll = () => {
    const newFilter = { ...filter, publication: ['short', 'article', 'url'] };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  return (
    <StyledPublicationFilter>
      <FilterItem
        title={t('Publication')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={filter.publication.length > 0}
        onClear={handleClear}
        showCheckAllButton={
          isOpen && filter.publication.length < totalFilterItems
        }
        onCheckAll={handleCheckAll}
      >
        <Checkbox
          text={'Post'}
          checked={filter.publication.indexOf('short') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'short')}
        />
        <Checkbox
          text={'Article'}
          checked={filter.publication.indexOf('article') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'article')}
        />
        <Checkbox
          text={'URL'}
          checked={filter.publication.indexOf('url') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'url')}
        />
      </FilterItem>
    </StyledPublicationFilter>
  );
};

export default PublicationFilter;
