import apiV2 from 'apis/apiV2';
import QueryString from 'qs';

import {
  MuralListParams,
  BoardContentPostSendData,
  BoardContentSurveySendData,
  GetListParams,
  SchedulePreviewParams,
} from './types';

const basePathname = '/admin/org';

export const getBoardContent = (
  organizationId: string,
  params: MuralListParams,
) => {
  return apiV2.get(`${basePathname}/${organizationId}/board/content/`, {
    params: {
      page: params.page,
      search: params.search,
      type: params.type,
      order: params.order,
      post: params.filter?.publication,
      survey: params.filter?.survey,
      checkin: params.filter?.checkin,
      status: params.filter?.status,
      start_at_period_begin: params.filter?.start_at_period_begin,
      start_at_period_end: params.filter?.start_at_period_end,
      finish_at_period_begin: params.filter?.finish_at_period_begin,
      finish_at_period_end: params.filter?.finish_at_period_end,
      tag: params.filter?.tag,
      can_comment: params.filter?.can_comment,
      comments_operator: params.filter?.comments_operator,
      comments_count: params.filter?.comments_count,
      owner: params.filter?.owner,
      editorial: params.filter?.editorial,
    },
    paramsSerializer: {
      serialize: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  });
};

// Post

export const getBoardContentPost = (organizationId: string, postId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/comm/post/${postId}`);

export const createBoardContentPost = (
  organizationId: string,
  data: BoardContentPostSendData,
) => apiV2.post(`${basePathname}/${organizationId}/comm/post/`, data);

export const updateBoardContentPost = (
  organizationId: string,
  postId: string,
  data: BoardContentPostSendData,
) => apiV2.put(`${basePathname}/${organizationId}/comm/post/${postId}`, data);

export const publishBoardContentPost = (
  organizationId: string,
  postId: string,
) =>
  apiV2.put(`${basePathname}/${organizationId}/comm/post/${postId}/published`);

// Content

export const getSegmentationOptions = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/segmentation/options/`);

export const getSegmentation = (
  organizationId: string,
  segmentationId: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/segmentation/${segmentationId}/entry/`,
  );

export const getNotificationConfig = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/notification/options/`);

export const getContentParentCandidates = (
  organizationId: string,
  params: MuralListParams,
  contentId?: string,
  contentType?: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/parent/content/?page=${
      params.page
    }&search=${params.search}&type=${
      params.type !== 'all' ? params.type : ''
    }&ordering=${params.order}&content_id=${contentId || ''}&content_type=${
      contentType || ''
    }&selected_content_id=${params.selected_content_id}`,
  );

export const getTags = (organizationId: string, page: number, search: string) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/tag/?page=${page}&search=${search}`,
  );

export const createTag = (organizationId: string, title: string) =>
  apiV2.post(`${basePathname}/${organizationId}/board/tag/`, {
    title,
  });

export const getOwners = (
  organizationId: string,
  page: number,
  search: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/comm/accounts/owner/?page=${page}&search=${search}`,
  );

// Survey

export const getSurveyConfig = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/comm/survey/config/`);

export const getBoardContentSurvey = (
  organizationId: string,
  surveyId: string,
) => apiV2.get(`${basePathname}/${organizationId}/comm/survey/${surveyId}`);

export const createBoardContentSurvey = (
  organizationId: string,
  data: BoardContentSurveySendData,
) => apiV2.post(`${basePathname}/${organizationId}/comm/survey/`, data);

export const updateBoardContentSurvey = (
  organizationId: string,
  surveyId: string,
  data: BoardContentSurveySendData,
) =>
  apiV2.put(`${basePathname}/${organizationId}/comm/survey/${surveyId}`, data);

export const publishBoardContentSurvey = (
  organizationId: string,
  surveyId: string,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/comm/survey/${surveyId}/published`,
  );

// Checkin

export const getCheckins = (organizationId: string, params: GetListParams) =>
  apiV2.get(`${basePathname}/${organizationId}/comm/checkin/`, {
    params,
  });

export const getBoardContentCheckin = (
  organizationId: string,
  postId: string,
) => apiV2.get(`${basePathname}/${organizationId}/comm/checkin/${postId}`);

export const createBoardContentCheckin = (
  organizationId: string,
  data: BoardContentPostSendData,
) => apiV2.post(`${basePathname}/${organizationId}/comm/checkin/`, data);

export const updateBoardContentCheckin = (
  organizationId: string,
  postId: string,
  data: BoardContentPostSendData,
) =>
  apiV2.put(`${basePathname}/${organizationId}/comm/checkin/${postId}`, data);

export const publishBoardContentCheckin = (
  organizationId: string,
  postId: string,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/comm/checkin/${postId}/published`,
  );

export const getSchedulePreview = (
  organizationId: string,
  params: SchedulePreviewParams,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/scheduler/preview`,
    {
      params,
    },
  );

export const getContentConfigs = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/content/configs/`);
