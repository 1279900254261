import React from 'react';
import { useTranslation } from 'react-i18next';

import Separator from 'components/atom/Separator';
import Text from 'components/atom/Text';
import Map from 'components/molecule/Map';

import { StyledMaps } from './styles';

const Maps: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledMaps>
      <Text as="h2" className="title">
        {t('Maps')}
      </Text>

      <div className="maps-wrapper">
        <section>
          {/* <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />

          <Map /> */}

          <Text as="h4" className="section-title map-temp">
            {t('Temp')}
          </Text>
          <Separator />

          <Map
            location={{
              lat: 40.99147,
              lng: -8.55165,
              location: 'Lugar tal',
            }}
          />
        </section>
      </div>
    </StyledMaps>
  );
};

export default Maps;
