import styled from 'styled-components';

export const StyledMuralTableCreatedBy = styled.div`
  .text {
    width: 100%;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
