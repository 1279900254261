import styled from 'styled-components';

export const StyledMuralFilter = styled.div`
  .mural-filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;

    h4 {
      margin-bottom: 8px;
    }
  }

  .mural-filter-content {
    margin: 0 0 42px 0;
    border-top: 1px solid var(--grayscale-100);

    .general-features-label {
      padding: 32px 0px;
    }

    .loading {
      margin-top: 16px;
    }
  }

  .mural-filter-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
