import styled from 'styled-components';

export const StyledPostContents = styled.div`
  width: 100%;
  height: 100%;

  .header {
    .main-left-actions,
    .main-right-actions {
      display: flex;
      align-items: center;
    }

    .main-left-actions {
      .gamification-button {
        padding-left: 0px;
      }
    }

    &.tab-header {
      .left-side {
        .button {
          padding-left: 0px;
          text-align: start;
          .icon {
            display: none;
          }
        }
      }
      .right-side {
        .button {
          padding-right: 0px;
          text-align: end;
          .icon {
            display: none;
          }
        }
      }
    }
  }

  .mural-content {
    width: 100%;
    height: calc(100% - 133px);

    display: flex;
    flex-direction: column;
    justify-content: center;

    .content,
    .actions {
      display: flex;
      justify-content: center;
    }

    .center {
      width: 100%;
      max-width: 750px;

      .left-side,
      .right-side {
        display: flex;
        align-items: center;
      }
    }

    .content {
      flex: 1;
      overflow-y: scroll;
      padding: 32px;
      width: 100%;

      .center {
        & > div {
          padding-bottom: 100px;
        }
      }
    }

    & > .actions {
      width: 100%;
      height: 108px;

      .center {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .switch-wrapper {
          display: flex;
          align-items: center;
          margin-right: 20px;
          .switch-button {
            margin-right: 12px;
          }
        }

        .tags-button {
          .button-content {
            display: flex;
            align-items: center;
          }
        }

        .dependency-button {
          margin-left: 10px;
        }

        .direct-poll-button {
          margin-right: 10px;
        }
      }
    }
  }
`;
