import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import SwitchButton from 'components/atom/SwitchButton';
import Textarea from 'components/molecule/Textarea';
import Button from 'components/molecule/Button';

import { ContentConfigsProps } from '../../types';

import { StyledDirectPollConfig } from './styles';

interface DirectPollConfigProps {
  isEnabled: boolean;
  message: string;
  onSave: (isEnabled: boolean, message: string) => void;
  contentConfigs: ContentConfigsProps;
}

const DirectPollConfig: React.FC<DirectPollConfigProps> = ({
  isEnabled,
  message,
  onSave,
  contentConfigs,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [votingIsEnabled, setVotingIsEnabled] = useState(isEnabled);
  const [votingMessage, setVotingMessage] = useState(
    !message ? '' : message.trim(),
  );
  const [messageError, setMessageError] = useState('');

  const handleChangeMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setVotingMessage(event.target.value);
    setMessageError('');
  };

  const handleSave = () => {
    onSave(votingIsEnabled, votingMessage);
    dispatch(closeModal());
  };

  return (
    <StyledDirectPollConfig>
      <div className="direct-poll-header">
        <Text as="h5" weight="700">
          {t('Enable voting')}
        </Text>
        <SwitchButton
          id="voting"
          checked={votingIsEnabled}
          onChange={(event) => setVotingIsEnabled(event.target.checked)}
        />
      </div>
      <Text>
        {t(
          'Collaborators will be able to vote for confirmation or denial with the established question.',
        )}
      </Text>
      <Textarea
        label={t('Enter poll question')}
        placeholder={contentConfigs.poll_placeholder}
        theme="gray"
        limit={150}
        autoResize
        value={votingMessage === null ? '' : votingMessage}
        onChange={handleChangeMessage}
        hasError={!!messageError}
        errorMessage={messageError}
      />
      <Button
        theme="primary"
        rounded="true"
        className="save-button"
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </StyledDirectPollConfig>
  );
};

export default DirectPollConfig;
