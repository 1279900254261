import React from 'react';
import ReactDatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';

import 'react-datepicker/dist/react-datepicker.css';

import { AvailableIcons } from 'components/atom/Icon/types';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { StyledDatePicker } from './styles';
import { getCurrentDateFormat, getCurrentDatePlaceholder } from './utils';

export const AvailableDatePickerThemes = {
  default: 'default',
  gray: 'gray',
  post: 'post',
  shadow: 'shadow',
  'secondary-dark': 'secondary-dark',
  dark: 'dark',
};

interface DatePickerProps {
  label?: string;
  id?: string;
  icon?: keyof typeof AvailableIcons;
  placeholder?: string;
  actions?: React.ReactNode;
  value?: Date;
  onChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  hasError?: boolean;
  errorMessage?: string;
  theme?: keyof typeof AvailableDatePickerThemes;
  disabled?: boolean | undefined;
  rounded?: string;
  className?: string;
  dateFormat?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  id,
  icon,
  placeholder,
  actions,
  value,
  onChange,
  minDate,
  maxDate,
  hasError = false,
  errorMessage,
  theme = 'default',
  disabled,
  rounded = 'false',
  className = '',
  dateFormat,
}) => {
  const handleChange = (date: Date) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <StyledDatePicker
      className={`datepicker ${theme} ${className}`}
      rounded={rounded === 'true'}
    >
      <label htmlFor={id}>
        {label && (
          <Text as="pre" color="grayscale-200" className="input-label">
            {label}
          </Text>
        )}
      </label>
      <div
        className={`input-content ${hasError ? 'has-error' : ''} ${
          disabled ? 'disabled' : ''
        }`}
      >
        {icon && <Icon name={icon} />}
        <ReactDatePicker
          selected={value}
          onChange={(date: Date) => handleChange(date)}
          popperClassName="datepicker-popper"
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          id={id || ''}
          placeholderText={placeholder || getCurrentDatePlaceholder()}
          dateFormat={dateFormat || getCurrentDateFormat()}
          autoComplete="off"
          customInput={
            <MaskedInput
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              keepCharPositions={true}
              guide={true}
            />
          }
        />
        {actions && <div className="actions">{actions}</div>}
      </div>
      {errorMessage && (
        <Text as="pre" color="danger-color" className="input-error-message">
          {errorMessage}
        </Text>
      )}
    </StyledDatePicker>
  );
};

export default DatePicker;
