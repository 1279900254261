import React, { useRef, useState, SyntheticEvent } from 'react';
import ReactCrop, {
  Crop,
  makeAspectCrop,
  centerCrop,
  PixelCrop,
} from 'react-image-crop';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView, closeModal } from 'store/slices/modal';

import Loading from 'components/molecule/Loading';
import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import ProgressBar from 'components/atom/ProgressBar';

import 'react-image-crop/dist/ReactCrop.css';

import { ImageCropProps, RemoveCoverConfirmProps } from './types';

import { StyledImageCrop, StyledRemoveCoverConfirm } from './styles';

const RemoveCoverConfirm: React.FC<RemoveCoverConfirmProps> = ({
  handleRemove,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledRemoveCoverConfirm>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {t('Remove image?')}
        </Text>
        <Text as="p">
          {t(
            'The image will be removed and cannot be recovered. Do you wish to continue?',
          )}
        </Text>
      </div>

      <Separator />

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button theme="danger-outline" onClick={handleRemove}>
          {t('Remove')}
        </Button>
      </div>
    </StyledRemoveCoverConfirm>
  );
};

const ImageCrop: React.FC<ImageCropProps> = ({
  src,
  aspect,
  fileNamePrefix = '',
  loading = false,
  onSave,
  onCancel,
  onRemove,
  showProgress = false,
  progress = 0,
  minHeight,
  minWidth,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const imageRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [localLoading, setLocalLoading] = useState(true);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    if (!aspect) return;

    const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

    const cropConfig = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(cropConfig);
    setLocalLoading(false);
  };

  const getCroppedImageFile = () => {
    return new Promise<File>((resolve, reject) => {
      try {
        const image = imageRef.current;

        if (!image || !completedCrop) return;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height,
          );

          canvas.toBlob(
            (blob) => {
              if (!blob) return;
              const file = new File(
                [blob],
                `${
                  fileNamePrefix ? `${fileNamePrefix}-` : ''
                }cropped-image.jpg`,
                {
                  type: 'image/jpeg',
                },
              );
              resolve(file);
            },
            'image/jpeg',
            0.92,
          );
        }
      } catch (error) {
        reject(t('An unexpected error occurred while saving the image.'));
      }
    });
  };

  const handleSave = () => {
    getCroppedImageFile()
      .then((file) => {
        if (onSave) {
          onSave(file);
        }
      })
      .catch((error) => {
        dispatch(
          showMessage({
            title: error,
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  };

  const handleCancel = () => {
    setCrop(undefined);
    setCompletedCrop(undefined);
    if (onCancel) {
      onCancel();
    }
  };

  const handleRemove = () => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: (
          <RemoveCoverConfirm
            handleRemove={() => {
              setCrop(undefined);
              setCompletedCrop(undefined);
              dispatch(closeModal());

              if (onRemove) {
                onRemove();
              }
            }}
          />
        ),
      }),
    );
  };

  return (
    <StyledImageCrop className="image-crop">
      <div className="crop-content">
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          aspect={aspect}
          onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
          minWidth={minWidth}
          minHeight={minHeight}
        >
          <img
            src={src}
            ref={imageRef}
            onLoad={onImageLoad}
            crossOrigin="anonymous"
          />
        </ReactCrop>
        {crop && !loading && (
          <div className="crop-actions">
            <div className="left-side">
              {onCancel && (
                <Button theme="link-danger" size="small" onClick={handleRemove}>
                  {t('Remove')}
                </Button>
              )}
            </div>
            <div className="right-side">
              {onCancel && (
                <Button
                  theme="link-dark-gray"
                  size="small"
                  onClick={handleCancel}
                >
                  {t('Cancel')}
                </Button>
              )}
              {onSave && (
                <Button
                  theme="primary-outline"
                  size="small"
                  onClick={handleSave}
                >
                  {t('Save')}
                </Button>
              )}
            </div>
          </div>
        )}
        {(loading || localLoading) && (
          <Loading className="image-crop-loading" />
        )}
      </div>

      {showProgress && <ProgressBar completed={progress} />}
    </StyledImageCrop>
  );
};

export default ImageCrop;
