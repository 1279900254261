export interface SubmittedImageProps {
  width: number;
  height: number;
}

export const getSubmittedImageProps = (file: File) => {
  return new Promise<SubmittedImageProps>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.src = event.target.result ? event.target.result.toString() : '';
      }
    };
    reader.readAsDataURL(file);
  });
};
