import styled from 'styled-components';

interface StyledDropdownProps {
  contentPlace: string;
  dropdownOpen: boolean;
}

export const StyledDropdown = styled.div<StyledDropdownProps>`
  position: relative;

  .dropdown-content {
    position: absolute;
    width: max-content;
    left: ${(props) => (props.contentPlace === 'left' ? '0px' : 'auto')};
    right: ${(props) => (props.contentPlace === 'right' ? '0px' : 'auto')};
    z-index: var(--z-index-level-2);

    transition: visibility 0.1s linear, opacity 0.1s linear;
    visibility: ${(props) => (props.dropdownOpen ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.dropdownOpen ? '1' : '0')};
  }
`;
