import styled from 'styled-components';

export const StyledStatusFilter = styled.div`
  .button-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 6px;

    .button {
      margin-right: 10px;
      margin-bottom: 10px;
      transition: 0s;

      .button-content {
        color: var(--grayscale-200);
      }

      &:hover {
        border-color: var(--grayscale-200);
      }

      &:active {
        background: var(--white-color);
        .button-content {
          color: var(--dark-color) !important;
        }
      }

      &.selected {
        border-color: var(--dark-color);
        .button-content {
          color: var(--dark-color);
        }
      }
    }
  }
`;
