import { dateAndTimeToApiDate } from 'utils/date';
import { schedulerInitialState } from '../PublicationDateModal/components/RecurrenceConfig/utils';

import { CoverParams, NotificationSendProps } from 'apis/board/types';
import { SurveyContentState } from './types';

export const surveyInitialState = {
  subtype: 'single',
  cover: {
    value: '',
    url: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  title: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  description: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  start_at: {
    value: undefined,
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  start_at_time: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  finish_at: {
    value: undefined,
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  finish_at_time: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  nps_first_label: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  nps_last_label: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  nps_first_value: 1,
  nps_last_value: 5,
  nps_scale: 5,
  options: [
    { id: 'poll-option-1', text: '', is_correct: false },
    { id: 'poll-option-2', text: '', is_correct: false },
  ],
  ...schedulerInitialState,
};

interface SurveyAPIDataFactoryConfigNPSProps {
  initialScale: number;
}

interface SurveyAPIDataFactoryConfigProps {
  nps?: SurveyAPIDataFactoryConfigNPSProps;
}

export const surveyAPIDataFactory = (
  currentContentState: SurveyContentState,
  settings?: SurveyAPIDataFactoryConfigProps,
) => {
  const {
    cover,
    title,
    description,
    start_at,
    start_at_time,
    finish_at,
    finish_at_time,
    nps_first_label,
    nps_first_value,
    nps_last_label,
    nps_last_value,
    nps_scale,
    type,
    can_comment,
    tag_ids,
    segmentations,
    gamification,
    notifications,
    parent,
    options,
    scheduler_start_date_at,
    scheduler_finish_date_at,
    content_start_time_at,
    content_finish_time_at,
    content_duration,
    content_week_days,
    scheduler_var,
    scheduler_type,
    with_recurrence,
    editorial,
  } = currentContentState;

  const contentCover: CoverParams = {
    id: cover.value || null,
    path: cover.url || null,
  };

  let startAtInAPIFormat = null;
  let finishAtInAPIFormat = null;

  if (start_at.value) {
    startAtInAPIFormat = dateAndTimeToApiDate(
      start_at.value,
      start_at_time.value?.toString() || '',
    );
  }

  if (finish_at.value) {
    finishAtInAPIFormat = dateAndTimeToApiDate(
      finish_at.value,
      finish_at_time.value?.toString() || '',
    );
  }

  let currentNotifications: NotificationSendProps[] = [];

  if (notifications) {
    currentNotifications = notifications
      .filter((item) => item.checked)
      .map((item) => ({ key: item.key, message: item.message }));
  }

  let currentFirstValue = nps_first_value;
  let currentLastValue = nps_last_value;

  if (settings && settings.nps) {
    if (currentFirstValue && currentLastValue) {
      currentFirstValue =
        settings.nps.initialScale > 0
          ? currentFirstValue
          : currentFirstValue - 1;
      currentLastValue =
        settings.nps.initialScale > 0 ? currentLastValue : currentLastValue - 1;
    }
  }

  let scheduderData = null;
  if (with_recurrence) {
    scheduderData = {
      scheduler_start_date_at: scheduler_start_date_at.value
        ? dateAndTimeToApiDate(scheduler_start_date_at.value).split('T')[0]
        : null,
      scheduler_finish_date_at: scheduler_finish_date_at.value
        ? dateAndTimeToApiDate(scheduler_finish_date_at.value).split('T')[0]
        : null,
      content_start_time_at: content_start_time_at.value,
      content_finish_time_at: content_finish_time_at.value,
      content_duration: content_duration.value,
      content_week_days: content_week_days.value,
      scheduler_var: scheduler_var.value,
      scheduler_type: scheduler_type.value,
    };
  }

  const currentEditorial = editorial === 'not_defined' ? null : editorial;

  return {
    cover: contentCover.id,
    title: title.value,
    description: description?.value,
    start_at: startAtInAPIFormat,
    finish_at: finishAtInAPIFormat,
    nps_first_label: nps_first_label?.value,
    nps_first_value: currentFirstValue,
    nps_last_label: nps_last_label?.value,
    nps_last_value: currentLastValue,
    nps_scale,
    type,
    can_comment,
    tags: tag_ids,
    segmentations: segmentations ? [segmentations] : [],
    gamification,
    notifications: currentNotifications,
    parent,
    options: options?.map((option) => ({
      text: option.text,
      is_correct: option.is_correct,
      id: option.id,
    })),
    scheduler: scheduderData,
    editorial: currentEditorial,
  };
};
