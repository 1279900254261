import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Tooltip from 'components/atom/Tooltip';

import { StyledTooltips } from './styles';

const Tooltips: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledTooltips>
      <Text as="h2" className="title">
        {t('Tooltips')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Places')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <div className="section-group">
                <Tooltip
                  tooltipId="tooltip-example-right"
                  message="Tooltip message right"
                  place="right"
                >
                  <Text as="h4" className="section-title">
                    {t('Tooltip right')}
                  </Text>
                </Tooltip>
              </div>
            </div>

            <div className="section-content">
              <div className="section-group">
                <Tooltip
                  tooltipId="tooltip-example-left"
                  message="Tooltip message left"
                  place="left"
                >
                  <Text as="h4" className="section-title">
                    {t('Tooltip left')}
                  </Text>
                </Tooltip>
              </div>
            </div>

            <div className="section-content">
              <div className="section-group">
                <Tooltip
                  tooltipId="tooltip-example-top"
                  message="Tooltip message top"
                  place="top"
                >
                  <Text as="h4" className="section-title">
                    {t('Tooltip top')}
                  </Text>
                </Tooltip>
              </div>
            </div>
            <div className="section-content">
              <div className="section-group">
                <Tooltip
                  tooltipId="tooltip-example-bottom"
                  message="Tooltip message bottom"
                  place="bottom"
                >
                  <Text as="h4" className="section-title">
                    {t('Tooltip bottom')}
                  </Text>
                </Tooltip>
              </div>
            </div>
            <div className="section-content">
              <div className="section-group">
                <Tooltip
                  clickable
                  tooltipId="tooltip-example-clickable"
                  message="Tooltip message clickable"
                  place="bottom"
                >
                  <Text as="h4" className="section-title">
                    {t('Tooltip clickable')}
                  </Text>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledTooltips>
  );
};

export default Tooltips;
