import styled from 'styled-components';

export const StyledMenu = styled.div.attrs(() => ({
  className: 'menu',
}))`
  width: 240px;
  height: 100%;
  background: var(--dark-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .highlight-options,
  .options {
    .button {
      width: 100%;
      justify-content: flex-start;
      padding-left: 34px;
      transition: background 0.2s;

      #button-left-icon {
        color: var(--secondary-color);
      }

      div,
      i {
        font-size: 18px;
      }

      &:disabled,
      &.disabled {
        background: var(--dark-color);
        border: none;
        & > * {
          color: var(--grayscale-300);
        }
      }
    }
  }

  .highlight-options {
    height: max-content;
    padding-top: 50px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--primary-color-300);
  }

  .options {
    flex: 1;
    padding-top: 50px;
    overflow-y: scroll;
    transition: background 0.2s;

    .button {
      padding-left: 26px;
    }
  }
`;
