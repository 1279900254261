import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { clearUser } from 'store/slices/user';
import { clearOrganization } from 'store/slices/organization';

import { logout } from 'services/auth';

// import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import AccountMenu from 'components/organism/AccountMenu';
import Button from 'components/molecule/Button';

import UserState from 'store/slices/user/types';

interface MainHeaderContentProps {
  className?: string;
}

import { StyledMainHeaderContent } from './styles';

const MainHeaderContent: React.FC<MainHeaderContentProps> = ({
  className = '',
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user: UserState = useAppSelector((store) => store.user);
  const organizationTimezoneUTC: string = useAppSelector(
    (store) => store.organization.timezone_utc,
  );
  const clientTimezoneUTC: string = useAppSelector(
    (store) => store.client.timezone_utc,
  );

  return (
    <StyledMainHeaderContent className={`main-header-content ${className}`}>
      {/*<Icon name="search" color="grayscale-200" />*/}
      {/*<Icon name="notification-3-fill-1" color="grayscale-200" />*/}
      <Tag theme="success-100">
        {organizationTimezoneUTC || clientTimezoneUTC}
      </Tag>
      <AccountMenu name={user.name} avatar={user.avatar}>
        <Button
          theme="link-gray-primary"
          leftIcon="logout"
          onClick={() => {
            logout();
            dispatch(clearUser());
            dispatch(clearOrganization());
            navigate('/');
          }}
        >
          {t('Logout')}
        </Button>
      </AccountMenu>
    </StyledMainHeaderContent>
  );
};

export default MainHeaderContent;
