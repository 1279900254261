import styled from 'styled-components';

export const StyledFilterItem = styled.div`
  position: relative;
  border-bottom: 1px solid var(--grayscale-100);

  .filter-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;

    .filter-toggle {
      display: flex;
      align-items: center;

      .icon {
        position: relative;
        font-size: 22px;
        margin-right: 8px;
        top: -2px;
      }
    }

    .filter-actions {
      display: flex;
      align-items: center;
    }
  }

  .filter-content {
    width: 100%;
    padding: 0px 44px;
    padding-bottom: 10px;
    display: none;

    &.open {
      display: block;
    }
  }
`;
