import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Checkbox from 'components/molecule/Checkbox';
import Radio from 'components/molecule/Radio';
import SwitchButton from 'components/atom/SwitchButton';

import { StyledCheckboxes } from './styles';

interface ExampleCheckboxProps {
  text?: string;
  disabled?: boolean;
}

const ExampleCheckbox: React.FC<ExampleCheckboxProps> = ({
  text,
  disabled,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <Checkbox
      text={text}
      checked={checked}
      onChange={() => setChecked(!checked)}
      disabled={disabled}
    />
  );
};

const Checkboxes: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCheckboxes>
      <Text as="h2" className="title">
        {t('Checkboxes and radios')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Checkboxes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('with text')}
              </Text>
              <ExampleCheckbox text="Lorem ipsum" />
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('without text')}
              </Text>
              <ExampleCheckbox />
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('with text, disabled')}
              </Text>
              <ExampleCheckbox text="Lorem ipsum" disabled />
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('without text, disabled')}
              </Text>
              <ExampleCheckbox disabled />
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Radio buttons')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('with all options enabled, first checked')}
              </Text>

              <div className="section-group">
                <Radio name="one" text={t('First')} checked />
                <Radio name="one" text={t('Second')} />
                <Radio name="one" text={t('Third')} />
                <Radio name="one" text={t('Fourth')} />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('with all options disabled')}
              </Text>

              <div className="section-group">
                <Radio name="two" text={t('First')} disabled />
                <Radio name="two" text={t('Second')} disabled />
                <Radio name="two" text={t('Third')} disabled />
                <Radio name="two" text={t('Fourth')} disabled />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('initial option disabled, others enabled')}
              </Text>

              <div className="section-group">
                <Radio name="three" text={t('First')} />
                <Radio name="three" text={t('Second')} />
                <Radio name="three" text={t('Third')} disabled checked />
                <Radio name="three" text={t('Fourth')} />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('only one option available')}
              </Text>

              <div className="section-group">
                <Radio name="four" text={t('First')} disabled />
                <Radio name="four" text={t('Second')} checked />
                <Radio name="four" text={t('Third')} disabled />
                <Radio name="four" text={t('Fourth')} disabled />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Switch buttons')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('enabled')}
              </Text>

              <SwitchButton id="switch-one" />
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('disabled, checked')}
              </Text>

              <SwitchButton id="switch-two" disabled checked />
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('disabled, not checked')}
              </Text>

              <SwitchButton id="switch-two" disabled checked={false} />
            </div>
          </div>
        </section>
      </div>
    </StyledCheckboxes>
  );
};

export default Checkboxes;
