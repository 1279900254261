import styled from 'styled-components';

export const StyledFileButton = styled.div`
  width: max-content;

  label {
    cursor: pointer;

    :hover {
      .button.primary-outline {
        border-color: var(--primary-color);
      }
    }

    .button {
      pointer-events: none;
    }

    input[type='file'] {
      display: none;
    }
  }

  .file-button-error-message {
    margin-top: 6px;
  }
`;
