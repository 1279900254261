import styled from 'styled-components';

export const StyledDashboardLayout = styled.div.attrs(() => ({
  className: 'dashboard-layout',
}))`
  width: 100%;
  height: 100%;

  display: flex;

  .menus {
    height: 100%;
    .menus-wrapper {
      display: flex;
      height: calc(100% - 71px);
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .container {
      width: 100%;
      height: calc(100% - 72px);
      overflow-y: scroll;
    }
  }
`;

export default StyledDashboardLayout;
