import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import { getNotificationConfig } from 'apis/board';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Collapse from 'components/molecule/Collapse';
import Checkbox from 'components/molecule/Checkbox';
import Textarea from 'components/molecule/Textarea';
import Loading from 'components/molecule/Loading';

import {
  NotificationConfigProps,
  NotificationValueProps,
} from 'apis/board/types';

import { StyledNotificationsConfig } from './styles';

interface NotificationsConfigProps {
  notificationConfig: NotificationConfigProps[] | null;
  setNotificationConfig: Dispatch<
    SetStateAction<NotificationConfigProps[] | null>
  >;
  notificationValues: NotificationValueProps[] | null;
  onSave: (newNotificationValues: NotificationValueProps[]) => void;
}

const NotificationsConfig: React.FC<NotificationsConfigProps> = ({
  notificationConfig,
  setNotificationConfig,
  notificationValues,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [currentNotificationValues, setCurrentNotificationValues] = useState<
    NotificationValueProps[]
  >([]);
  const [configLoading, setConfigLoading] = useState(!notificationConfig);

  const loadNotificationConfig = useCallback(() => {
    setConfigLoading(true);

    getNotificationConfig(organizationId)
      .then((response) => {
        setNotificationConfig(response.data);

        if (notificationValues && notificationValues.length > 0) {
          setCurrentNotificationValues(notificationValues);
          return;
        }

        setCurrentNotificationValues(
          response.data.map((item: NotificationConfigProps) => ({
            key: item.key,
            label: item.label,
            message: item.message,
            checked: false,
            placeholder: item.placeholder,
          })),
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('There was an error loading the notifications settings'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
            customLeft: '0px',
          }),
        );
      })
      .finally(() => {
        setConfigLoading(false);
      });
  }, [dispatch, notificationValues, organizationId, setNotificationConfig, t]);

  const handleSave = () => {
    onSave(currentNotificationValues);
    dispatch(closeModal());
  };

  const handleSelect = (key: string, value: boolean) => {
    const newValues = currentNotificationValues.map((item) => {
      if (item.key === key) {
        return {
          key: item.key,
          label: item.label,
          message: item.message,
          checked: value,
          placeholder: item.placeholder,
        };
      }

      return item;
    });

    setCurrentNotificationValues(newValues);
  };

  const handleChangeMessage = (key: string, value: string) => {
    const newValues = currentNotificationValues.map((item) => {
      if (item.key === key) {
        return {
          key: item.key,
          label: item.label,
          message: value,
          checked: item.checked,
          placeholder: item.placeholder,
        };
      }

      return item;
    });

    setCurrentNotificationValues(newValues);
  };

  useEffect(() => {
    if (!notificationConfig) {
      loadNotificationConfig();
      return;
    }

    if (notificationValues && notificationValues.length > 0) {
      setCurrentNotificationValues(notificationValues);
      return;
    }

    setCurrentNotificationValues(
      notificationConfig.map((item: NotificationConfigProps) => ({
        key: item.key,
        label: item.label,
        message: item.message,
        checked: false,
        placeholder: item.placeholder,
      })),
    );
  }, [loadNotificationConfig, notificationConfig, notificationValues]);

  return (
    <StyledNotificationsConfig className="notification-config">
      <Text as="h5" weight="bold">
        {t('Define how notifications about this content will be received')}
      </Text>

      {!configLoading && (
        <>
          <div className="collapses">
            {currentNotificationValues.map((item, index) => (
              <Collapse
                key={index}
                isOpen={item.checked || false}
                setIsOpen={() => handleSelect(item.key, !item.checked)}
                collapseHeader={
                  <div className="checkbox-wrapper">
                    <Checkbox
                      text={item.label}
                      checked={item.checked}
                      onChange={() => undefined}
                    />
                  </div>
                }
              >
                <Textarea
                  value={item.message}
                  onChange={(event) =>
                    handleChangeMessage(item.key, event.target.value)
                  }
                  label={t('Notification message')}
                  limit={150}
                  theme="gray"
                  rows={4}
                  placeholder={item.placeholder}
                />
              </Collapse>
            ))}
          </div>
          <Button theme="primary" rounded="true" onClick={handleSave}>
            {t('Save')}
          </Button>
        </>
      )}

      {configLoading && <Loading />}
    </StyledNotificationsConfig>
  );
};

export default NotificationsConfig;
