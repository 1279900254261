import styled from 'styled-components';

export const StyledSelects = styled.div.attrs(() => ({
  className: 'selects-page',
}))`
  padding: 16px;

  & > .title {
    margin-bottom: 32px;
  }

  section {
    margin-bottom: 32px;

    .section-contents {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-wrap: wrap;

      .section-content {
        width: 40%;
        padding: 8px;
        border-radius: 8px;
        margin-right: 32px;
        margin-bottom: 16px;

        & > h6 {
          margin-bottom: 8px;
        }

        .section-group {
          display: flex;
          align-items: center;

          .select {
            background: none;
            width: 100%;

            .react-select {
              width: 100%;
            }
          }
          .button {
            padding-bottom: 0px;
            padding-top: 0px;
          }
        }
      }
    }
  }
`;
