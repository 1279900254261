import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStaticMap } from 'apis/googlemaps';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { FileUpload } from 'services/upload';

import Input from 'components/molecule/Input';
import Map from 'components/molecule/Map';

import { CheckinContentProps } from './types';
import { CheckinContentFields } from '../../types';
import { Location } from 'components/molecule/Map/types';
import { UploadDataResponse, FileUploadModules } from 'services/upload/types';
import Loading from 'components/molecule/Loading';

import { StyledCheckinContent } from './styles';

const CheckinContent: React.FC<CheckinContentProps> = ({
  checkinContentState,
  setContentState,
  contentStatus,
}) => {
  const { t } = useTranslation();
  const organizationPk: string = useAppSelector(
    (state) => state.organization.pk,
  );
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: CheckinContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeLocation = (selectedLocation: Location) => {
    const { location, lat, lng } = selectedLocation;

    setLoading(true);

    getStaticMap(location, lat, lng).then((response) => {
      const file = new File([response.data], 'map-image.jpg', {
        type: 'image/jpeg',
      });

      const fileUpload = new FileUpload();
      fileUpload
        .send(file, FileUploadModules.BOARD, organizationPk, () => undefined)
        .then((response: UploadDataResponse) => {
          const { fileUrl, moduleId } = response;

          setContentState((updatedState: CheckinContentFields) => ({
            ...updatedState,
            location: location,
            lat: lat,
            lng: lng,
            cover: {
              ...updatedState.cover,
              value: moduleId.toString(),
              url: fileUrl,
              error: {
                hasError: false,
                errorMessage: '',
              },
            },
          }));
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An error occurred while saving location'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const getLocation = () => {
    const { location, lat, lng } = checkinContentState;

    if (!location || !lat || !lng) return null;

    return {
      location,
      lat,
      lng,
    };
  };

  return (
    <StyledCheckinContent>
      <Input
        id="image-survey-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={checkinContentState.title.value?.toString() || undefined}
        onChange={handleChangeTitle}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={checkinContentState.title.error.hasError}
        errorMessage={checkinContentState.title.error.errorMessage}
      />

      <div className="checkin-map-wrapper">
        <Map
          location={getLocation()}
          onChangeLocation={(location) => handleChangeLocation(location)}
          disabled={contentStatus !== 'draft' && contentStatus !== ''}
        />
        {loading && <Loading className="checkin-loading" />}
      </div>
    </StyledCheckinContent>
  );
};

export default CheckinContent;
