import styled from 'styled-components';

export const StyledSelectContentTypeItem = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  padding-left: 18px;

  .icon {
    margin-right: 12px;
  }
`;
