import React from 'react';

import Button from '../../../components/molecule/Button';
import Text from '../../../components/atom/Text';

import { SelectOption } from 'components/molecule/Select/types';

export const optionsLeft: readonly SelectOption[] = [
  {
    value: 'op1',
    label: (
      <Button leftIcon="eye-fill" theme="link-primary">
        Option 1
      </Button>
    ),
  },
  {
    value: 'op2',
    label: (
      <Button leftIcon="eye-fill" theme="link-primary">
        Option 2
      </Button>
    ),
  },
  {
    value: 'op3',
    label: (
      <Button leftIcon="eye-fill" theme="link-primary">
        Option 3
      </Button>
    ),
  },
  {
    value: 'op4',
    label: (
      <Button leftIcon="eye-fill" theme="link-primary">
        Option 4
      </Button>
    ),
  },
];

export const optionsRigth: readonly SelectOption[] = [
  {
    value: 'op1',
    label: (
      <Button theme="link-primary" size="small">
        <Text color="grayscale-200">Option 1</Text>
      </Button>
    ),
  },
  {
    value: 'op2',
    label: (
      <Button theme="link-primary" size="small">
        <Text color="grayscale-200">Option 2</Text>
      </Button>
    ),
  },
];

export const optionsDisable: readonly SelectOption[] = [
  {
    value: 'op1',
    label: (
      <Button theme="link-primary" size="small">
        <Text color="grayscale-200">Option 1</Text>
      </Button>
    ),
  },
];

export const optionsSearcheSelect: readonly SelectOption[] = [
  {
    value: 'op1',
    label: (
      <Button leftIcon="add-outline" theme="link-primary">
        Option 1
      </Button>
    ),
  },
  {
    value: 'op2',
    label: (
      <Button leftIcon="add-solid" theme="link-primary">
        Option 2
      </Button>
    ),
  },
  {
    value: 'op3',
    label: (
      <Button leftIcon="alert-fill" theme="link-primary">
        Option 3
      </Button>
    ),
  },
  {
    value: 'op4',
    label: (
      <Button leftIcon="calendar-2-line" theme="link-primary">
        Option 4
      </Button>
    ),
  },
];

export const optionsSearcheMultiple: readonly SelectOption[] = [
  {
    value: 'op1',
    label: (
      <Button leftIcon="add-outline" theme="link-primary">
        Option 1
      </Button>
    ),
  },
  {
    value: 'op2',
    label: (
      <Button leftIcon="add-solid" theme="link-primary">
        Option 2
      </Button>
    ),
  },
  {
    value: 'op3',
    label: (
      <Button leftIcon="alert-fill" theme="link-primary">
        Option 3
      </Button>
    ),
  },
  {
    value: 'op4',
    label: (
      <Button leftIcon="calendar-2-line" theme="link-primary">
        Option 4
      </Button>
    ),
  },
];
