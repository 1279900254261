import styled from 'styled-components';

interface StyledEditorProps {
  toolbar: boolean;
}

const setBoxShadow = (isShowingToolbar: boolean) => {
  if (!isShowingToolbar) return 'none';

  // see: https://www.tiny.cloud/blog/wysiwyg-css-style/
  return '0 2px 2px -2px rgb(34 47 62 / 10%), 0 8px 8px -4px rgb(34 47 62 / 7%)';
};

export const StyledEditor = styled.div<StyledEditorProps>`
  width: 100%;

  .editor-label {
    margin-top: 1rem;
    font-weight: 500;

    &.hide {
      opacity: 0;
      visibility: 0;
    }
  }

  .input-limit {
    width: 100%;
    text-align: end;
    margin-top: 6px;
    height: 0;
    overflow: hidden;

    animation: collapse 0.2s;
    -webkit-animation: collapse 0.2s;
    -moz-animation: collapse 0.2s;
    -o-animation: collapse 0.2s;
    -ms-animation: collapse 0.2s;
    animation-fill-mode: forwards;
  }

  /* custom TinyMCE properties */
  .tox-tinymce {
    border-width: ${({ toolbar }) => (toolbar ? '2px' : '0px')};

    .tox-editor-container {
      .tox-editor-header {
        box-shadow: ${({ toolbar }) => setBoxShadow(toolbar)};
      }
    }
  }

  .editor-error-message {
    padding-left: 2px;
  }

  @keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }

  @-moz-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }

  @-webkit-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }

  @-o-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }

  @-ms-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }
`;
