import styled from 'styled-components';

export const StyledMainRestrictedLayout = styled.div.attrs(() => ({
  className: 'main-restricted-layout',
}))`
  width: 100%;
  height: 100%;
`;

export default StyledMainRestrictedLayout;
