import { postInitialState } from 'pages/board/MuralContent/components/PostContents/utils';
import { surveyInitialState } from 'pages/board/MuralContent/components/SurveyContents/utils';
import { checkinInitialState } from 'pages/board/MuralContent/components/CheckinContents/utils';

const useContentInitialState = (type: string) => {
  switch (type) {
    case 'post': {
      return { ...postInitialState };
    }
    case 'survey': {
      return { ...surveyInitialState };
    }
    case 'checkin': {
      return { ...checkinInitialState };
    }
    default: {
      return { ...postInitialState };
    }
  }
};

export default useContentInitialState;
