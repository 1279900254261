import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import { getContentConfigs } from 'apis/board';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import PublicationFilter from './components/PublicationFilter';
import SurveyFilter from './components/SurveyFilter';
import CheckinFilter from './components/CheckinFilter';
import Separator from 'components/atom/Separator';
import StatusFilter from './components/StatusFilter';
import StartAtFilter from './components/StartAtFilter';
import FinishAtFilter from './components/FinishAtFilter';
import TagFilter from './components/TagFilter';
import CommentFilter from './components/CommentFilter';
import OwnerFilter from './components/OwnerFilter';
import EditorialFilter from './components/EditorialFilter';
import Loading from 'components/molecule/Loading';

import { CustomFilterAttrsTypes } from '../../types';
import { EditorialProps } from 'apis/board/types';

import { StyledMuralFilter } from './styles';

interface MuralFilterProps {
  customFilter: CustomFilterAttrsTypes;
  onApply: (filterState: CustomFilterAttrsTypes) => void;
  initialState: CustomFilterAttrsTypes;
}

const MuralFilter: React.FC<MuralFilterProps> = ({
  customFilter,
  onApply,
  initialState,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  const [filter, setFilter] = useState<CustomFilterAttrsTypes>({
    ...customFilter,
  });
  const [editorials, setEditorials] = useState<EditorialProps[]>([]);
  const [editorialLoading, setEditorialLoading] = useState(true);

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleClearAll = () => {
    setFilter({ ...initialState });
  };

  const clearAllIsVisible = () => {
    const filterArray = Object.keys(filter).map((key) => {
      return filter[key as keyof CustomFilterAttrsTypes];
    });

    const isVisible = filterArray.some(
      (item: unknown) =>
        (Array.isArray(item) && item.length !== 0) ||
        (!Array.isArray(item) && item !== null),
    );

    return isVisible;
  };

  const handleSave = () => {
    onApply({ ...filter });
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  useEffect(() => {
    setEditorialLoading(true);

    getContentConfigs(organizationId)
      .then((response) => {
        setEditorials(response.data.editorial);
      })
      .finally(() => {
        setEditorialLoading(false);
      });
  }, [organizationId]);

  return (
    <StyledMuralFilter className="mural-filter">
      <div className="mural-filter-header">
        <div className="text">
          <Text as="h4" weight="700">
            {t('Content types')}
          </Text>
          <Text as="h6" weight="500">
            {t('Choose multiple criteria and customize your search')}
          </Text>
        </div>
        <div className="actions">
          {clearAllIsVisible() && (
            <Button
              theme="link-gray-primary"
              size="small"
              onClick={handleClearAll}
            >
              {t('Clear all')}
            </Button>
          )}
        </div>
      </div>

      <div className="mural-filter-content">
        <PublicationFilter filter={filter} setFilter={setFilter} />
        <SurveyFilter filter={filter} setFilter={setFilter} />
        <CheckinFilter filter={filter} setFilter={setFilter} />
        <Text className="general-features-label" as="h4" weight="700">
          {t('General features')}
        </Text>
        <Separator />
        <StatusFilter filter={filter} setFilter={setFilter} />
        <StartAtFilter filter={filter} setFilter={setFilter} />
        <FinishAtFilter filter={filter} setFilter={setFilter} />
        <TagFilter filter={filter} setFilter={setFilter} />
        <CommentFilter filter={filter} setFilter={setFilter} />
        <OwnerFilter filter={filter} setFilter={setFilter} />
        {!editorialLoading && editorials.length > 0 && (
          <EditorialFilter
            filter={filter}
            setFilter={setFilter}
            editorials={editorials}
          />
        )}
        {editorialLoading && <Loading />}
      </div>

      <div className="mural-filter-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button theme="dark" onClick={handleSave}>
          {t('Apply filter')}
        </Button>
      </div>
    </StyledMuralFilter>
  );
};

export default MuralFilter;
