import { useTranslation } from 'react-i18next';

const useBoardTabOptions = (type: string, subtype: string) => {
  const { t } = useTranslation();

  const postOptions = [
    {
      key: 'short',
      content: t('Post'),
      active: subtype === 'short',
    },
    {
      key: 'article',
      content: t('Article'),
      active: subtype === 'article',
    },
    {
      key: 'url',
      content: t('URL'),
      active: subtype === 'url',
    },
  ];

  const surveyOptions = [
    {
      key: 'poll',
      content: t('Poll'),
      active: subtype === 'poll',
    },
    {
      key: 'nps',
      content: t('Likert'),
      active: subtype === 'nps',
    },
    {
      key: 'image',
      content: t('Image upload'),
      active: subtype === 'image',
    },
    {
      key: 'message',
      content: t('Text sending'),
      active: subtype === 'message',
    },
  ];

  const checkinOptions = [
    {
      key: 'checkin',
      content: t('Checkin'),
      active: subtype === 'checkin',
    },
  ];

  if (type === 'post') {
    return postOptions;
  }

  if (type === 'survey') {
    return surveyOptions;
  }

  if (type === 'checkin') {
    return checkinOptions;
  }

  return [];
};

export default useBoardTabOptions;
