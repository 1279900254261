import styled from 'styled-components';

export const StyledEmptyMessages = styled.div.attrs(() => ({
  className: 'empty-messages-page',
}))`
  padding: 16px;

  .title {
    margin-bottom: 32px;
  }

  section {
    margin-bottom: 32px;

    .section-contents {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-wrap: wrap;

      .section-content {
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        margin-right: 32px;
        margin-bottom: 16px;

        h6 {
          margin-bottom: 8px;
        }

        .section-group {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;
