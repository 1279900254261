import styled from 'styled-components';

interface StyledInputProps {
  rounded: boolean;
}

export const StyledInput = styled.div<StyledInputProps>`
  width: 100%;
  background: var(--white-color);

  .input-label {
    margin-bottom: 6px;
    font-weight: 500;

    &.hide {
      opacity: 0;
      visibility: 0;
    }
  }

  .input-content {
    border: 1px solid var(--grayscale-100);
    border-radius: ${({ rounded }) => (rounded ? '100px' : '8px')};
    padding: 6px 14px;

    display: flex;
    align-items: center;

    & > .icon {
      color: var(--grayscale-200);
      margin-right: 6px;
      font-weight: 500;
    }

    input {
      flex: 1;
      border: none;
      font-size: 18px;
      outline: none;
      padding-top: 2px;
      padding-left: 0;
      padding-right: 0;
      color: var(--dark-color);
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      font-weight: 500;

      ::-webkit-input-placeholder {
        /* Edge */
        color: var(--grayscale-200);
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--grayscale-200);
      }

      ::placeholder {
        color: var(--grayscale-200);
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      &[type='time']::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    &:focus-within {
      border-color: var(--primary-color);
    }

    &.has-error {
      border-color: var(--danger-color);
    }

    &.disabled {
      background: var(--gray-color);
      border-color: var(--gray-color);
    }

    &.disabled:focus-within {
      border-color: var(--gray-color);
    }
  }

  .input-limit {
    width: 100%;
    text-align: end;
    margin-top: 6px;
    height: 0;
    overflow: hidden;

    animation: collapse 0.2s;
    -webkit-animation: collapse 0.2s;
    -moz-animation: collapse 0.2s;
    -o-animation: collapse 0.2s;
    -ms-animation: collapse 0.2s;
    animation-fill-mode: forwards;
  }

  .input-error-message {
    margin-top: 6px;
    padding-left: 2px;
    white-space: pre-line;
  }

  .input-time {
    max-width: 72px;
  }

  @keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 14px;
    }
  }

  @-moz-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 14px;
    }
  }

  @-webkit-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 14px;
    }
  }

  @-o-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 14px;
    }
  }

  @-ms-keyframes collapse {
    0% {
      height: 0;
    }
    100% {
      height: 14px;
    }
  }
`;
