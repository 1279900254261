export interface DocItemColor {
  label: string;
  color: string;
}

export interface DocSubSectionItemColor {
  label: string;
  items: DocItemColor[];
}

export interface DocIconSectionType {
  label: string;
  colorsItems: DocSubSectionItemColor[];
}

export const docColorsSection: DocIconSectionType[] = [
  {
    label: 'Custom Buttons',
    colorsItems: [
      {
        label: 'primary',
        items: [
          {
            label: 'primary-color-100',
            color: 'primary-color-100',
          },
          {
            label: 'primary-color',
            color: 'primary-color',
          },
          {
            label: 'primary-color-300',
            color: 'primary-color-300',
          },
          {
            label: 'primary-color-400',
            color: 'primary-color-400',
          },
        ],
      },

      {
        label: 'secondary',
        items: [
          {
            label: 'secondary-color',
            color: 'secondary-color',
          },
          {
            label: 'secondary-color-200',
            color: 'secondary-color-200',
          },
        ],
      },

      {
        label: 'tertiary',
        items: [
          {
            label: 'tertiary-color-1',
            color: 'tertiary-color-1',
          },
          {
            label: 'tertiary-color-2',
            color: 'tertiary-color-2',
          },
          {
            label: 'tertiary-color-3',
            color: 'tertiary-color-3',
          },
        ],
      },
      {
        label: 'suporte',
        items: [
          {
            label: 'support-pastel-color-1',
            color: 'support-pastel-color-1',
          },
          {
            label: 'support-pastel-color-2',
            color: 'support-pastel-color-2',
          },
        ],
      },
    ],
  },
  {
    label: 'Grayscale',
    colorsItems: [
      {
        label: 'grayscale',
        items: [
          {
            label: 'grayscale-100',
            color: 'grayscale-100',
          },
          {
            label: 'grayscale-200',
            color: 'grayscale-200',
          },
          {
            label: 'grayscale-300',
            color: 'grayscale-300',
          },
          {
            label: 'grayscale-400',
            color: 'grayscale-400',
          },
        ],
      },
    ],
  },
  {
    label: 'Dark & White',
    colorsItems: [
      {
        label: 'dark_white',
        items: [
          {
            label: 'white-color',
            color: 'white-color',
          },
          {
            label: 'gray-color',
            color: 'gray-color',
          },
          {
            label: 'dark-color',
            color: 'dark-color',
          },
        ],
      },
    ],
  },

  {
    label: 'Status',
    colorsItems: [
      {
        label: 'status',
        items: [
          {
            label: 'success-color-100',
            color: 'success-color-100',
          },
          {
            label: 'success-color',
            color: 'success-color',
          },
          {
            label: 'success-color-300',
            color: 'success-color-300',
          },
        ],
      },

      {
        label: 'alert',
        items: [
          {
            label: 'warning-color-100',
            color: 'warning-color-100',
          },
          {
            label: 'warning-color',
            color: 'warning-color',
          },
        ],
      },

      {
        label: 'danger',
        items: [
          {
            label: 'danger-color-100',
            color: 'danger-color-100',
          },
          {
            label: 'danger-color',
            color: 'danger-color',
          },
          {
            label: 'danger-color-300',
            color: 'danger-color-300',
          },
        ],
      },
    ],
  },
];
