import React from 'react';

import Button from '../Button';
import Text from 'components/atom/Text';

import { AvailableIcons } from 'components/atom/Icon/types';
import { AvailableSizeTypes, AvailableThemeTypes } from 'styles/button-themes';

import { StyledFileButton } from './styles';

interface FileButtonProps {
  id: string;
  children?: React.ReactNode;
  buttonIcon?: keyof typeof AvailableIcons;
  buttonTheme: keyof typeof AvailableThemeTypes;
  buttonSize?: keyof typeof AvailableSizeTypes;
  errorMessage?: string;
  accept?: string[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const FileButton: React.FC<FileButtonProps> = ({
  id,
  children,
  buttonIcon = '',
  buttonTheme = 'primary-outline',
  buttonSize = 'normal',
  errorMessage = '',
  accept = '*',
  onChange = () => undefined,
  className = '',
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    event.target.value = '';
  };

  return (
    <StyledFileButton className={`file-button ${className}`}>
      <label htmlFor={id}>
        <Button
          theme={buttonTheme}
          leftIcon={buttonIcon}
          rounded="true"
          size={buttonSize}
        >
          {children}
        </Button>
        <input
          type="file"
          id={id}
          onChange={handleInputChange}
          accept={accept.toString()}
        />
      </label>
      {errorMessage && (
        <Text
          as="pre"
          color="danger-color"
          className="file-button-error-message"
        >
          {errorMessage}
        </Text>
      )}
    </StyledFileButton>
  );
};

export default FileButton;
