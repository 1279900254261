import React, { useState } from 'react';

import Text from 'components/atom/Text';
import Image from 'components/molecule/Image';
import Dropdown from 'components/molecule/Dropdown';
import Icon from 'components/atom/Icon';

import { StyledAccountMenu } from './styles';

interface AccountMenuProps {
  name?: string;
  profile?: string;
  avatar?: string | null;
  children?: React.ReactNode;
}

const AccountMenu: React.FC<AccountMenuProps> = ({
  name,
  avatar,
  profile,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledAccountMenu className="account-menu">
      <div className="texts">
        <Text color="grayscale-200">{name}</Text>
        <Text as="small" color="grayscale-200">
          {profile}
        </Text>
      </div>
      <Dropdown
        open={open}
        setOpen={setOpen}
        toggleElement={
          <div className="account-info">
            <Image radius="50%" width="44px" height="44px" src={avatar} />
            <Icon name="arrow-down-s-line" color="grayscale-200" />
          </div>
        }
        toggleButtonTheme="link-dark"
        contentPlace="right"
      >
        {children}
      </Dropdown>
    </StyledAccountMenu>
  );
};

export default AccountMenu;
