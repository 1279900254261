import styled from 'styled-components';

export const StyledOwnerFilter = styled.div`
  .tag-filter-header {
    width: 100%;
    display: flex;
    align-items: center;

    .counter {
      display: flex;
      align-items: center;
      margin-left: 24px;

      .text:first-child {
        margin-right: 8px;
      }
    }
  }

  .tag-filter-content {
    .card {
      width: 100%;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px 0px;

      .icon {
        margin-right: 6px;
      }
    }

    .tag-list {
      width: 100%;
      margin: 24px 0px;
      display: flex;
      flex-wrap: wrap;

      .tag {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;

        .text {
          max-width: 18ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .button {
          padding: 0px;
          min-width: 20px;
          height: 20px;
          margin-left: 6px;
        }
      }
    }
  }
`;
