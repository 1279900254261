import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  getBoardContentSurvey,
  createBoardContentSurvey,
  updateBoardContentSurvey,
  publishBoardContentSurvey,
} from 'apis/board';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { setModalView } from 'store/slices/modal';

import NPSSurveyContent from './components/NPSSurveyContent';
import PollSurveyContent from './components/PollSurveyContent';
import ImageSurveyContent from './components/ImageSurveyContent';
import MessageSurveyContent from './components/MessageSurveyContent';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import Loading from 'components/molecule/Loading';
import SwitchButton from 'components/atom/SwitchButton';
import Icon from 'components/atom/Icon';
import Confirm from 'components/molecule/Confirm';
import ConfirmPublish from '../ConfirmPublish';
import TabMenu from 'components/molecule/TabMenu';
import EditorialConfig from '../EditorialConfig';

import {
  contentSubtypes,
  contentStateFactory,
  parseSchedulerDataToStateFormat,
} from '../../utils';
import { apiDateToDayjsFormat } from 'utils/date';
import { surveyAPIDataFactory } from './utils';
import { schedulerInitialState } from '../PublicationDateModal/components/RecurrenceConfig/utils';

import {
  SurveyContentsProps,
  SurveyContentFields,
  SurveyContentState,
} from './types';
import {
  BoardContentSurveyData,
  BoardContentSurveySendData,
} from 'apis/board/types';
import { RequestFieldError } from 'apis/types';

import { StyledSurveyContents } from './styles';

const SurveyContents: React.FC<SurveyContentsProps> = ({
  contentState,
  setContentState,
  setInitialChangeControl,
  segmentationConfig,
  segmentationLoading,
  loadSegmentationConfig,
  currentSegmentation,
  contentStatus,
  setContentStatus,
  canComment,
  setCanComment,
  setCanEditFinishAt,
  setChangeControl,
  contentTagIds,
  setContentTagIds,
  contentTags,
  setContentTags,
  totalTags,
  setTotalTags,
  gamification,
  setGamification,
  setNotificationChangeControl,
  notificationValues,
  setNotificationValues,
  parent,
  setParent,
  segmentationData,
  setSegmentationId,
  setSegmentationChangeControl,
  getPeriodButtonLabel,
  isEditable,
  showSegmentationModal,
  showGamificationModal,
  showPublicationDateModal,
  showNotificationModal,
  showTagsModal,
  showDependencyModal,
  showSuccessMessage,
  showErrorMessage,
  handleFieldErrors,
  tabs,
  handleChangeTab,
  getCurrentType,
  surveyConfig,
  canEdit,
  setCanEdit,
  withRecurrence,
  setWithRecurrence,
  contentConfigs,
  contentConfigsLoading,
  selectedEditorial,
  setSelectedEditorial,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );
  const { type: contentType = '', subtype = '', contentId } = useParams();

  const renderContent = () => {
    switch (subtype) {
      case 'nps': {
        return (
          <NPSSurveyContent
            npsContentState={contentState}
            setContentState={setContentState}
            NPSConfig={surveyConfig.nps}
            configLoaded={surveyConfig.loaded}
            contentStatus={contentStatus}
          />
        );
      }
      case 'image': {
        return (
          <ImageSurveyContent
            imageContentState={contentState}
            setContentState={setContentState}
          />
        );
      }
      case 'message': {
        return (
          <MessageSurveyContent
            messageContentState={contentState}
            setContentState={setContentState}
          />
        );
      }
      case 'poll': {
        return (
          <PollSurveyContent
            pollContentState={contentState}
            setContentState={setContentState}
            contentStatus={contentStatus}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  const createSurvey = (
    currentContentState: SurveyContentState,
    willBePublished: boolean,
  ) => {
    dispatch(setPageLoading(true));

    const data: BoardContentSurveySendData =
      surveyAPIDataFactory(currentContentState);

    if (subtype === 'poll') {
      const currentSubtype = currentContentState.subtype;
      data.type = currentSubtype || 'single';
    }

    return new Promise<string>((resolve, reject) => {
      createBoardContentSurvey(organizationId, data)
        .then((response) => {
          const { id } = response.data;

          setChangeControl(currentContentState);
          setSegmentationChangeControl(data.segmentations[0]);
          setNotificationChangeControl(
            currentContentState.notifications || null,
          );

          if (!withRecurrence) {
            setContentState({ ...contentState, ...schedulerInitialState });
          }

          if (!willBePublished) {
            showSuccessMessage(t('Content has been saved as a draft'));
          }

          navigate(`/mural/content/${contentType}/${subtype}/${id}`);
          resolve(id);
        })
        .catch((responseError) => {
          const { code, errors } = responseError.response.data;
          if (code === 'field_error') {
            handleFieldErrors(errors);
            return;
          }
          showErrorMessage(t('An error occurred while saving content'));
          reject(responseError);
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    });
  };

  const updateSurvey = (
    currentContentState: SurveyContentState,
    willBePublished: boolean,
  ) => {
    dispatch(setPageLoading(true));

    const data: BoardContentSurveySendData =
      surveyAPIDataFactory(currentContentState);

    if (subtype === 'poll') {
      const currentSubtype = currentContentState.subtype;
      data.type = currentSubtype || 'single';
    }

    return new Promise<string>((resolve, reject) => {
      updateBoardContentSurvey(organizationId, contentId || '', data)
        .then((response) => {
          const { id } = response.data;

          if (contentStatus !== 'draft') {
            navigate('/mural');
            return;
          }

          setChangeControl(currentContentState);
          setSegmentationChangeControl(data.segmentations[0]);
          setNotificationChangeControl(
            currentContentState.notifications || null,
          );

          if (!withRecurrence) {
            setContentState({ ...contentState, ...schedulerInitialState });
          }

          if (!willBePublished) {
            showSuccessMessage(t('Content has been saved as a draft'));
          }

          resolve(id);
        })
        .catch((responseError) => {
          const { code, errors } = responseError.response.data;

          if (code === 'field_error' && errors[0].field === 'options') {
            showErrorMessage(errors[0].error);
            return;
          }
          if (code === 'field_error') {
            handleFieldErrors(errors);
            return;
          }
          if (code === 'invalid' || code.indexOf('invalid') !== -1) {
            showErrorMessage(errors[0]);
            return;
          }

          showErrorMessage(t('An error occurred while saving content'));
          reject(responseError);
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    });
  };

  const publishSurveyContent = (id: string) => {
    dispatch(setPageLoading(true));

    publishBoardContentSurvey(organizationId, id)
      .then(() => {
        showSuccessMessage(t('The content has been published'));
        navigate('/mural');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || code.indexOf('invalid') !== -1) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error' && errors[0].field === 'options') {
          showErrorMessage(errors[0].error);
          return;
        }

        if (code === 'field_error') {
          const fieldErrors = errors.filter(
            (error: RequestFieldError) => error.field !== 'options',
          );
          handleFieldErrors(fieldErrors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while publishing the content'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSave = (saveAndPublish = false) => {
    const activeTab = getCurrentType();
    const type = activeTab ? activeTab.key : 'poll';

    const contentGeneralFields = {
      can_comment: canComment,
      can_reaction: false,
      gamification,
      notifications: notificationValues,
      parent,
      segmentations: segmentationData,
      status: contentStatus,
      tags: contentTags,
      total_tags: totalTags,
      tag_ids: contentTagIds,
      type,
      with_recurrence: withRecurrence,
      editorial: selectedEditorial,
    };

    const currentContentState = contentStateFactory(
      contentState,
      contentGeneralFields,
    );

    if (!contentId) {
      createSurvey(currentContentState, saveAndPublish).then((id) => {
        if (saveAndPublish) {
          publishSurveyContent(id);
        }
      });
      return;
    }

    updateSurvey(currentContentState, saveAndPublish).then((id) => {
      if (saveAndPublish) {
        publishSurveyContent(id);
      }
    });
  };

  const handlePublishChanges = () => {
    dispatch(
      setModalView({
        show: true,
        width: '388px',
        content: (
          <Confirm
            title={t('Publish changes')}
            subtitle={t('Are you sure you want to save the changes?')}
            onConfirm={handleSave}
          />
        ),
      }),
    );
  };

  const handlePublishClick = () => {
    if (
      !contentState.scheduler_start_date_at.value &&
      !contentState.start_at.value
    ) {
      setContentState((updatedState) => ({
        ...updatedState,
        start_at: {
          ...updatedState.start_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (
      !contentState.scheduler_start_date_at.value &&
      !contentState.start_at_time.value
    ) {
      setContentState((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    dispatch(
      setModalView({
        show: true,
        width: '664px',
        content: (
          <ConfirmPublish
            startAt={dayjs(contentState.start_at.value || Date.now()).format(
              'L',
            )}
            startAtTime={contentState.start_at_time.value}
            finishAt={
              contentState.finish_at.value
                ? dayjs(contentState.finish_at.value).format('L')
                : null
            }
            finishAtTime={contentState.finish_at_time.value}
            onConfirm={() => handleSave(true)}
            withRecurrence={withRecurrence}
            scheduleState={{
              scheduler_start_date_at: contentState.scheduler_start_date_at,
              scheduler_finish_date_at: contentState.scheduler_finish_date_at,
              content_start_time_at: contentState.content_start_time_at,
              content_finish_time_at: contentState.content_finish_time_at,
              content_duration: contentState.content_duration,
              content_week_days: contentState.content_week_days,
              scheduler_var: contentState.scheduler_var,
              scheduler_type: contentState.scheduler_type,
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const handleSurveyLoadResponse = useCallback(
    (data: BoardContentSurveyData) => {
      const {
        cover,
        title,
        description,
        start_at,
        finish_at,
        nps_first_label,
        nps_first_value,
        nps_last_label,
        nps_last_value,
        nps_scale,
        initial_scale,
        status,
        tags,
        segmentations,
        can_comment,
        gamification,
        notifications,
        parent,
        type,
        options,
        can_modify_finish_at,
        scheduler,
        can_edit,
        editorial,
      } = data;

      const formattedStartAt = apiDateToDayjsFormat(start_at || '');
      const formattedFinishAt = apiDateToDayjsFormat(finish_at || '');

      const [startAt, startAtTime] = formattedStartAt.split(' ');
      const [finishAt, finishAtTime] = formattedFinishAt.split(' ');

      setContentState((lastContentFields) => {
        const currentFields = lastContentFields as SurveyContentFields;

        let currentFirstValue = null;
        let currentLastValue = null;

        if (nps_scale && initial_scale && nps_first_value && nps_last_value) {
          currentFirstValue =
            initial_scale > 0 ? nps_first_value : nps_first_value + 1;
          currentLastValue =
            initial_scale > 0 ? nps_last_value : nps_last_value + 1;
        }

        const newContentState: SurveyContentFields = {
          subtype:
            type !== 'single' && type !== 'multi'
              ? currentFields.subtype
              : type,
          cover: {
            ...currentFields.cover,
            value: cover && cover.id ? cover.id : '',
            url: cover && cover.path ? cover.path : '',
          },
          title: { ...currentFields.title, value: title || '' },
          description: {
            ...currentFields.description,
            value: description || '',
          },
          start_at: {
            ...currentFields.start_at,
            value: startAt ? dayjs(startAt).toDate() : undefined,
          },
          start_at_time: {
            ...currentFields.start_at_time,
            value: startAtTime ? startAtTime.split(':').join(':') : '',
          },
          finish_at: {
            ...currentFields.finish_at,
            value: finishAt ? dayjs(finishAt).toDate() : undefined,
          },
          finish_at_time: {
            ...currentFields.finish_at_time,
            value: finishAtTime ? finishAtTime.split(':').join(':') : '',
          },
          nps_first_label: {
            ...currentFields.nps_first_label,
            value: nps_first_label || '',
          },
          nps_first_value: currentFirstValue || 1,
          nps_last_label: {
            ...currentFields.nps_last_label,
            value: nps_last_label || '',
          },
          nps_last_value: currentLastValue || 5,
          nps_scale: nps_scale || 5,
          options: options || [],
          ...parseSchedulerDataToStateFormat(scheduler),
        };
        setContentStatus(status);
        setCanComment(can_comment);
        setGamification(gamification);
        setNotificationValues(notifications);
        setNotificationChangeControl(notifications);
        setParent(parent || '');
        setCanEditFinishAt(can_modify_finish_at);
        setCanEdit(can_edit);
        setWithRecurrence(!!scheduler);
        setSelectedEditorial(editorial || 'not_defined');

        if (tags) {
          setContentTags(tags);
          setTotalTags(tags.length);
          setContentTagIds(tags.map((tag) => tag.id));
        }

        if (segmentations) {
          setSegmentationId(segmentations[0]);
        }

        setChangeControl(
          contentStateFactory(newContentState, {
            can_comment,
            can_reaction: false,
            gamification,
            notifications: undefined,
            parent: parent || '',
            segmentations: undefined,
            status,
            tags: tags ? tags : [],
            total_tags: tags ? tags.length : 0,
            tag_ids: tags ? tags.map((tag) => tag.id) : [],
            type,
            with_recurrence: !!scheduler,
            editorial: editorial || 'not_defined',
          }),
        );

        return newContentState;
      });
    },
    [
      setContentState,
      setContentStatus,
      setCanComment,
      setCanEditFinishAt,
      setChangeControl,
      setContentTagIds,
      setContentTags,
      setTotalTags,
      setGamification,
      setNotificationChangeControl,
      setNotificationValues,
      setParent,
      setSegmentationId,
      setCanEdit,
      setWithRecurrence,
      setSelectedEditorial,
    ],
  );

  const getSurvey = useCallback(() => {
    if (!contentId) return;
    dispatch(setPageLoading(true));

    getBoardContentSurvey(organizationId, contentId)
      .then((response) => {
        handleSurveyLoadResponse(response.data);
        const { segmentations } = response.data;
        if (segmentations) {
          loadSegmentationConfig(segmentations[0]);
        }
      })
      .catch(() => {
        navigate('/error-404');
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  }, [
    contentId,
    dispatch,
    handleSurveyLoadResponse,
    navigate,
    organizationId,
    loadSegmentationConfig,
  ]);

  useEffect(() => {
    if (contentType !== 'survey') {
      navigate('/page-404');
      return;
    }

    if (!contentSubtypes.includes(subtype)) {
      navigate('/page-404');
      return;
    }

    if (!canEdit) {
      navigate('/mural');
      return;
    }

    if (contentId) {
      getSurvey();
      return;
    }

    setInitialChangeControl();
  }, [
    contentType,
    contentId,
    subtype,
    navigate,
    contentStatus,
    getSurvey,
    setInitialChangeControl,
    canEdit,
  ]);

  return (
    <StyledSurveyContents>
      <Header
        leftSideContent={
          <div className="main-left-actions">
            <Text as="h6" weight="700">
              {contentId ? t('Edit quick search') : t('Create quick search')}
            </Text>
            <Button
              theme="link-primary"
              rightIcon={!segmentationLoading ? 'arrow-down-s-line' : ''}
              onClick={showSegmentationModal}
              disabled={segmentationLoading || !segmentationConfig}
            >
              {!segmentationLoading && (
                <>
                  {currentSegmentation.length > 0
                    ? t('Target audience (defined)')
                    : t('Target audience (not defined)')}
                </>
              )}
              {segmentationLoading && (
                <Loading type="bubbles" color="grayscale-100" />
              )}
            </Button>
            <Button
              theme="link-primary"
              rightIcon="arrow-down-s-line"
              onClick={showGamificationModal}
              className="gamification-button"
            >
              {t('Gamification')}
            </Button>
            <EditorialConfig
              editorials={contentConfigs.editorial}
              loading={contentConfigsLoading}
              selectedEditorial={selectedEditorial}
              setSelectedEditorial={setSelectedEditorial}
            />
          </div>
        }
        rightSideContent={
          <div className="main-right-actions">
            <Button
              leftIcon={
                contentState.start_at.error.hasError ||
                contentState.finish_at.error.hasError
                  ? 'error-warning-fill'
                  : ''
              }
              theme={
                contentState.start_at.error.hasError ||
                contentState.finish_at.error.hasError
                  ? 'link-danger'
                  : 'link-primary'
              }
              rightIcon="arrow-down-s-line"
              onClick={showPublicationDateModal}
            >
              {getPeriodButtonLabel()}
            </Button>
            {isEditable() ? (
              <Button
                theme="dark"
                size="big"
                onClick={() => handlePublishClick()}
              >
                {t('Publish')}
              </Button>
            ) : (
              <Button theme="dark" size="big" onClick={handlePublishChanges}>
                {t('Publish changes')}
              </Button>
            )}
          </div>
        }
      />
      <Header
        className="tab-header"
        leftSideContent={
          <Button theme="link-gray-primary" onClick={() => navigate('/mural')}>
            {t('Cancel')}
          </Button>
        }
        rightSideContent={
          <Button
            theme="link-gray-primary"
            onClick={() => handleSave()}
            disabled={!isEditable()}
          >
            {t('Save draft')}
          </Button>
        }
      >
        {isEditable() && <TabMenu tabs={tabs} onChangeTab={handleChangeTab} />}
        {!isEditable() && (
          <TabMenu
            tabs={tabs.map((tab) => ({ ...tab, disabled: true }))}
            onChangeTab={handleChangeTab}
          />
        )}
      </Header>
      <div className="mural-content">
        <div className="content default-scroll">
          <div className="center">{renderContent()}</div>
        </div>
        <div className="actions">
          <div className="center">
            <div className="left-side">
              <div className="switch-wrapper">
                <SwitchButton
                  id="comments"
                  checked={canComment}
                  onChange={() => setCanComment(!canComment)}
                />
                <Text as="h6">{t('Comments')}</Text>
              </div>
              {/* <div className="switch-wrapper">
                <SwitchButton id="reactions" />
                <Text as="h6">{t('Reactions')}</Text>
              </div> */}

              <Button
                theme="link-gray-primary"
                rightIcon="arrow-up-s-line"
                onClick={showNotificationModal}
                disabled={!isEditable()}
              >
                {t('Notifications')}
              </Button>
            </div>
            <div className="right-side">
              <Button
                theme="primary-flat"
                rounded="true"
                size="small"
                className="tags-button"
                onClick={showTagsModal}
              >
                {totalTags} {'#'}
                {t('Tag', { count: totalTags })}
              </Button>
              <Button
                theme="primary-flat"
                rounded="true"
                size="small"
                className="dependency-button"
                onClick={showDependencyModal}
                disabled={!isEditable()}
              >
                <Icon name="git-merge-fill" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StyledSurveyContents>
  );
};

export default SurveyContents;
