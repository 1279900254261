import styled from 'styled-components';

export const StyledAccountMenu = styled.div`
  display: flex;
  align-items: center;

  .texts {
    margin-right: 12px;
    text-align: end;
  }

  .account-info {
    display: flex;
    align-items: center;

    .image {
      margin-right: 16px;
    }
  }

  .dropdown-content {
    top: calc(100% + 10px);
  }
`;
