import { DocLoadingsSectionType } from './types';

export const docLoadingsSectionTypes: DocLoadingsSectionType[] = [
  {
    label: 'Spin',
    typeName: 'spin',
  },
  {
    label: 'Balls',
    typeName: 'balls',
  },
  {
    label: 'Bars',
    typeName: 'bars',
  },
  {
    label: 'Bubbles',
    typeName: 'bubbles',
  },
  {
    label: 'Cubes',
    typeName: 'cubes',
  },
  {
    label: 'Cylon',
    typeName: 'cylon',
  },
  {
    label: 'Spinning Bubbles',
    typeName: 'spinningBubbles',
  },
  {
    label: 'Spokes',
    typeName: 'spokes',
  },
];

export const docLoadingsSectionSizes: string[] = ['26px', '52px', '104px'];
