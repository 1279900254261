import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import EmptyMessage from 'components/molecule/EmptyMessage';

import { StyledEmptyMessages } from './styles';

import rectangleImage from 'assets/images/rectangle.png';

const EmptyMessages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledEmptyMessages>
      <Text as="h2" className="title">
        {t('Empty Messages')}
      </Text>

      <div className="empty-messages-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />

          <div className="section-contents">
            <EmptyMessage showImage />
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Example with custom image')}
          </Text>
          <Separator />

          <div className="section-contents">
            <EmptyMessage showImage src={rectangleImage} />
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Example with custom title and description')}
          </Text>
          <Separator />

          <div className="section-contents">
            <EmptyMessage
              showImage
              title={t('This is an custom title')}
              description={t('This is an custom description')}
            />
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Example with custom title, description and icon')}
          </Text>
          <Separator />

          <div className="section-contents">
            <EmptyMessage
              showImage
              title={t('This is an custom title')}
              description={t('This is an custom description')}
              icon="stack-line"
            />
          </div>
        </section>
      </div>
    </StyledEmptyMessages>
  );
};

export default EmptyMessages;
