import styled from 'styled-components';

export const StyledArticlePostContent = styled.div`
  .title {
    margin-bottom: 16px;

    .input-content {
      padding: 0;

      input {
        font-size: 38px;
      }
    }
  }

  .description {
    .textarea-content {
      padding: 0;
    }
  }
`;
