import React from 'react';
import ReactTooltip from 'react-tooltip';

import { StyledTooltip } from './styles';
import { AvailableTooltipPlaceTypes } from './types';

interface TooltipProps {
  keepShow?: string;
  place?: keyof typeof AvailableTooltipPlaceTypes;
  clickable?: boolean | undefined;
  children?: React.ReactNode;
  afterShow?: () => void;
  tooltipId: string;
  message: string;
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  place = 'top',
  clickable = false,
  message,
  tooltipId,
  afterShow,
  children,
  disabled = false,
}) => {
  return (
    <StyledTooltip className="tooltip">
      <div
        data-for={tooltipId}
        data-tip={message}
        data-event={clickable ? 'click focus' : ''}
      >
        {children}
      </div>
      <ReactTooltip
        id={tooltipId}
        className="react-tooltip"
        effect="solid"
        place={place}
        backgroundColor="var(--dark-color)"
        clickable={clickable}
        globalEventOff="click"
        afterShow={afterShow}
        disable={disabled}
      />
    </StyledTooltip>
  );
};

export default Tooltip;
