import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getSchedulePreview } from 'apis/board';

import { useAppSelector } from 'store/hooks';

import { dateAndTimeToApiDate } from 'utils/date';

import Card from 'components/atom/Card';
import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Loading from 'components/molecule/Loading';

import { SchedulerStateProps } from '../PublicationDateModal/components/RecurrenceConfig/types';

import { StyledSchedulePreview } from './styles';

interface SchedulePreviewProps {
  schedulerState: SchedulerStateProps;
}

const SchedulePreview: React.FC<SchedulePreviewProps> = ({
  schedulerState,
}) => {
  const { t } = useTranslation();
  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const [previewItems, setPreviewItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadPreview = useCallback(() => {
    setLoading(true);

    let startAt = '';
    if (schedulerState.scheduler_start_date_at.value) {
      startAt = dateAndTimeToApiDate(
        schedulerState.scheduler_start_date_at.value,
      ).split('T')[0];
    }

    let finishAt = '';
    if (schedulerState.scheduler_finish_date_at.value) {
      finishAt = dateAndTimeToApiDate(
        schedulerState.scheduler_finish_date_at.value,
      ).split('T')[0];
    }

    const params = {
      scheduler_start_date_at: startAt,
      scheduler_finish_date_at: finishAt,
      content_start_time_at: schedulerState.content_start_time_at.value
        ? schedulerState.content_start_time_at.value.toString().substring(0, 5)
        : '',
      content_finish_time_at: schedulerState.content_finish_time_at.value
        ? schedulerState.content_finish_time_at.value.toString().substring(0, 5)
        : '',
      content_duration: schedulerState.content_duration.value,
      content_week_days: schedulerState.content_week_days.value.toString(),
      scheduler_var: schedulerState.scheduler_var.value,
      scheduler_type: schedulerState.scheduler_type.value,
    };

    getSchedulePreview(organizationId, params)
      .then((response) => {
        setPreviewItems(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organizationId, schedulerState]);

  useEffect(() => {
    loadPreview();
  }, [schedulerState, loadPreview]);

  return (
    <StyledSchedulePreview className="scheduler-preview">
      {!loading && previewItems.length !== 0 && (
        <Card>
          <div className="card-header">
            <Icon name="idea" />
            <Text as="h6" weight="700">
              {t('See how publication occurrences will look')}
            </Text>
          </div>
          <div className="card-content">
            {previewItems.map((item, index) => (
              <Text key={index} weight="500">
                {item}
              </Text>
            ))}
          </div>
        </Card>
      )}

      {loading && <Loading />}
    </StyledSchedulePreview>
  );
};

export default SchedulePreview;
