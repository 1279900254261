import styled from 'styled-components';

export const StyledMural = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);

  .page-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .filters {
      width: 100%;
      display: flex;
      align-items: center;

      .select {
        width: 100%;
        max-width: 200px;
        margin-right: 10px;

        .react-select__control {
          padding-left: 0px;
        }
      }

      .input {
        .input-content {
          padding-right: 6px;
          height: 56px;
        }

        .custom-filter-bottom {
          .counter-label {
            width: 16px;
            min-width: 16px;
            height: 16px;
            top: -8px;
            right: -8px;
          }
        }
      }

      .counter {
        width: max-content;
        min-width: 100px;
        margin-left: 10px;
      }
    }

    .table {
      margin-top: 56px;
      .table-wrapper {
        table {
          thead {
            th {
              &:last-child {
                width: 30px;
                max-width: 30px;
                min-width: 30px;
              }
            }
          }
        }
      }
    }

    .empty-message {
      height: 100%;
    }
  }

  .header {
    padding: 22px 54px;

    .text {
      margin-right: 8px;
    }

    .left-side-content {
      display: flex;
      align-items: center;
    }
  }

  .page-content {
    padding: 44px 54px;
  }
`;
