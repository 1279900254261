import styled from 'styled-components';

import background from 'assets/images/background.svg';

export const StyledLogin = styled.div`
  width: 100%;
  height: 100%;
  background: var(--gray-color);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -150px 125%;
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 100%;
    max-width: 484px;
    padding: 64px 46px;

    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 64px;

      & > .tag {
        position: absolute;
        top: -18px;
        right: 8px;
        padding: 8px 12px;
      }

      .image {
        max-width: 204px;
      }
    }

    .form-wrapper {
      .input {
        margin-bottom: 24px;

        .actions {
          .button {
            padding: 0px;
            height: max-content;
            min-width: 22px;
          }

          .icon {
            font-size: 22px;
          }
        }
      }

      .company {
        margin-bottom: 48px;
      }
    }

    .check-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 42px;

      .checkbox {
        margin-right: 14px;
        .text {
          color: var(--grayscale-300);
        }
      }

      .tooltip {
        .icon {
          font-size: 22px;
        }
      }
    }

    .button-actions {
      width: 100%;

      .button {
        width: 100%;
        margin-bottom: 12px;
      }
    }

    .not-account-text {
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        margin-right: 12px;
      }

      .register-button {
        width: max-content;
        padding: 0px;
        margin-bottom: 2px;
      }
    }
  }

  @media (max-width: 720px) {
    .card {
      padding: 32px 16px;

      .logo-wrapper {
        & > .tag {
          top: -25px;
          right: -8px;
        }
      }
    }
  }
`;
