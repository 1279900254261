import styled from 'styled-components';

export const StyledCreationLayout = styled.div.attrs(() => ({
  className: 'creation-layout',
}))`
  width: 100%;
  height: 100%;

  .container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
`;

export default StyledCreationLayout;
