import styled from 'styled-components';

export const StyledHeader = styled.div`
  width: 100%;
  height: 72px;
  background: var(--white-color);
  border-bottom: 1px solid var(--grayscale-100);
  padding: 8px 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    padding: 8px;
  }
`;
