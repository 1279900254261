import googleMapsApi from 'apis/googleMapsApi';
import { GOOGLE_MAPS_API_KEY } from 'settings';

export const getStaticMap = (location: string, lat: number, lng: number) =>
  googleMapsApi.get('staticmap', {
    responseType: 'blob',
    params: {
      center: location,
      zoom: 15,
      size: '600x300',
      maptype: 'roadmap',
      markers: `color:red|${lat},${lng}`,
      key: GOOGLE_MAPS_API_KEY,
    },
  });
