import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import TabMenu from 'components/molecule/TabMenu';
import Text from 'components/atom/Text';
import NPSScale from './components/NPSScale';
import Loading from 'components/molecule/Loading';

import { NPSSurveyContentProps } from './types';
import { SurveyContentFields } from '../../types';
import { Tab } from 'components/molecule/TabMenu/types';

import { StyledNPSSurveyContent } from './styles';

const NPSSurveyContent: React.FC<NPSSurveyContentProps> = ({
  npsContentState,
  setContentState,
  NPSConfig,
  configLoaded,
  contentStatus,
}) => {
  const { t } = useTranslation();

  const [tabs, setTabs] = useState<Tab[]>(
    NPSConfig.scale.map((item) => ({
      key: item.toString(),
      content: item.toString(),
      active: npsContentState.nps_scale === item,
      disabled: contentStatus !== '' && contentStatus !== 'draft',
    })),
  );

  const getStepValue = () => 100 / (npsContentState.nps_scale - 2);

  const getMinValue = () =>
    getStepValue() * (npsContentState.nps_first_value - 1);

  const getMaxValue = () => {
    return npsContentState.nps_last_value === npsContentState.nps_scale
      ? 100
      : getStepValue() * (npsContentState.nps_last_value - 2);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      description: {
        ...updatedState.description,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFirstValue = (value: number) => {
    const newFirstValue = value / getStepValue() + 2;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      nps_first_value: Math.round(newFirstValue - 1),
    }));
  };

  const handleChangeLastValue = (value: number) => {
    const newLastValue = value / getStepValue() + 1;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      nps_last_value: Math.round(newLastValue + 1),
    }));
  };

  const handleChangeFirstLabel = (value: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      nps_first_label: {
        ...updatedState.nps_first_label,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeLastLabel = (value: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      nps_last_label: {
        ...updatedState.nps_last_label,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeScale = (key: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      nps_scale: parseInt(key),
      nps_first_value: 1,
      nps_last_value: npsContentState.nps_scale,
    }));
    setTabs(
      tabs.map((tab: Tab) => {
        if (tab.key === key) {
          tab.active = true;
          return tab;
        }

        tab.active = false;
        return tab;
      }),
    );
  };

  useEffect(() => {
    setTabs(
      NPSConfig.scale.map((item) => ({
        key: item.toString(),
        content: item.toString(),
        active: npsContentState.nps_scale === item,
        disabled: contentStatus !== '' && contentStatus !== 'draft',
      })),
    );
  }, [npsContentState.nps_scale, NPSConfig.scale, contentStatus]);

  return (
    <StyledNPSSurveyContent className="nps-survey-content">
      <Input
        id="nps-survey-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={npsContentState.title.value?.toString() || undefined}
        onChange={handleChangeTitle}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={npsContentState.title.error.hasError}
        errorMessage={npsContentState.title.error.errorMessage}
      />
      <Textarea
        id="short-post-description-textarea"
        className="description"
        theme="post"
        focusedLabel={t('Description')}
        placeholder={`${t('Enter the description')}`}
        value={npsContentState.description.value?.toString() || undefined}
        onChange={handleChangeDescription}
        limit={500}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={npsContentState.description.error.hasError}
        errorMessage={npsContentState.description.error.errorMessage}
        autoResize
      />

      {configLoaded && (
        <>
          <div className="scale-size">
            <Text as="h6">{t('Scale size')}</Text>
            <TabMenu tabs={tabs} onChangeTab={handleChangeScale} />
          </div>
          <NPSScale
            scaleSize={npsContentState.nps_scale}
            minValue={getMinValue()}
            maxValue={getMaxValue()}
            minValueLabel={npsContentState.nps_first_label.value || ''}
            maxValueLabel={npsContentState.nps_last_label.value || ''}
            onChangeMinValue={handleChangeFirstValue}
            onChangeMaxValue={handleChangeLastValue}
            onChangeMinValueLabel={handleChangeFirstLabel}
            onChangeMaxValueLabel={handleChangeLastLabel}
            minPlaceholderLabel={NPSConfig.first_placeholder_label}
            maxPlaceholderLabel={NPSConfig.last_placeholder_label}
            initialScale={NPSConfig.initial_scale}
            contentStatus={contentStatus}
          />
        </>
      )}

      {!configLoaded && <Loading />}
    </StyledNPSSurveyContent>
  );
};

export default NPSSurveyContent;
