import { Location } from './types';

export const convertPlaceResultToLocation = (
  placeResult: google.maps.places.PlaceResult | null,
): Location | null => {
  if (!placeResult) return null;

  const lat: number | undefined =
    placeResult.geometry?.location?.lat() ||
    placeResult.geometry?.viewport?.getCenter().lat();
  const lng: number | undefined =
    placeResult.geometry?.location?.lng() ||
    placeResult.geometry?.viewport?.getCenter().lng();
  const location: string | undefined =
    placeResult.formatted_address ||
    placeResult.name ||
    placeResult.address_components?.map((e) => e.long_name).join(', ') ||
    `${lat}, ${lng}`;

  // but is it possible that we can't get the location? oO
  if (location && lat && lng) return { location, lat, lng };
  return null;
};

export const getPlaceResultsFromQuery = (
  query: string | null,
  fields: string[] = ['place_id', 'formatted_address', 'name', 'geometry'],
) => {
  if (!query) return null;

  // see: https://code.luasoftware.com/tutorials/google-maps/google-places-javascript-api-query-for-places
  // see: https://gist.github.com/bamnet/37aee0e273a065470c77733313996533
  // see: https://github.com/JustFly1984/react-google-maps-api/issues/2331
  const attributionContainer = document.createElement('div');
  const placesService = new window.google.maps.places.PlacesService(
    attributionContainer,
  );
  return new Promise(
    (
      resolve: (result: google.maps.places.PlaceResult[] | null) => void,
      reject: (status: google.maps.places.PlacesServiceStatus) => void,
    ) => {
      placesService.findPlaceFromQuery(
        {
          query,
          fields,
        },
        (
          results: google.maps.places.PlaceResult[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            reject(status);
          }
        },
      );
    },
  );
};

export const getGoogleMapLanguageCode = (locale: string) => {
  if (!locale || locale.length < 4) return 'en';
  return locale.substring(0, 2).toLowerCase();
};

export const getGoogleMapRegionCode = (locale: string) => {
  if (!locale || locale.length < 4) return 'US';
  return locale.substring(locale.length - 2, locale.length).toUpperCase();
};

export const removeExtraPacContainers = () => {
  // see: https://github.com/JustFly1984/react-google-maps-api/issues/2331
  // fixes extra pac-container around even when changing to elsewhere
  const pac = document.getElementsByClassName('pac-container');
  if (pac.length > 1) pac[0].remove();
};
