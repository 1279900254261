import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { dateAndTimeToApiDate } from 'utils/date';

import FilterItem from '../FilterItem';
import DatePicker from 'components/molecule/DatePicker';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';

import { StyledFinishAtFilter } from './styles';

export interface FinishAtFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const FinishAtFilter: React.FC<FinishAtFilterProps> = ({
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(
    !!filter.finish_at_period_begin || !!filter.finish_at_period_end,
  );

  const handleClear = () => {
    const newFilter = {
      ...filter,
      finish_at_period_begin: null,
      finish_at_period_end: null,
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleChangePeriodBegin = (value: Date) => {
    setFilter({
      ...filter,
      finish_at_period_begin: dateAndTimeToApiDate(value),
    });
  };

  const handleChangePeriodEnd = (value: Date) => {
    setFilter({
      ...filter,
      finish_at_period_end: dateAndTimeToApiDate(value),
    });
  };

  return (
    <StyledFinishAtFilter>
      <FilterItem
        title={t('Content finish date')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={
          isOpen &&
          (!!filter.finish_at_period_begin || !!filter.finish_at_period_end)
        }
        onClear={handleClear}
        showCheckAllButton={false}
      >
        <div className="datepicker-wrapper">
          <DatePicker
            label={t('Start')}
            id="start-at-period-begin"
            icon="calendar-2-fill"
            value={
              filter.finish_at_period_begin
                ? dayjs(filter.finish_at_period_begin).toDate()
                : undefined
            }
            onChange={handleChangePeriodBegin}
          />
          <DatePicker
            label={t('Finish')}
            id="start-at-period-end"
            icon="calendar-2-fill"
            value={
              filter.finish_at_period_end
                ? dayjs(filter.finish_at_period_end).toDate()
                : undefined
            }
            onChange={handleChangePeriodEnd}
          />
        </div>
      </FilterItem>
    </StyledFinishAtFilter>
  );
};

export default FinishAtFilter;
