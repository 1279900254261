import React from 'react';
import ReactPaginate from 'react-paginate';

import Button from '../Button';
import Icon from 'components/atom/Icon';

import { StyledPaginationControl } from './styles';

interface PaginationControlProps {
  page?: number;
  pageCount: number;
  onChangePage: (selectedItem: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = ({
  page,
  pageCount,
  onChangePage,
}) => {
  return (
    <StyledPaginationControl className="pagination-control">
      <ReactPaginate
        onPageChange={(newPage) => onChangePage(newPage.selected)}
        forcePage={page}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        pageClassName="link-page-button"
        previousLabel={
          <Button
            className="previous"
            theme="link-page-button-primary"
            rounded="true"
            size="small"
          >
            <Icon name="arrow-left-s-line" />
          </Button>
        }
        breakLabel={
          <Button
            className="page-number more"
            theme="link-page-button"
            rounded="true"
            size="small"
          >
            ...
          </Button>
        }
        nextLabel={
          <Button
            className="next"
            theme="link-page-button-primary"
            rounded="true"
            size="small"
          >
            <Icon name="arrow-right-s-line" />
          </Button>
        }
      />
    </StyledPaginationControl>
  );
};

export default PaginationControl;
