import React from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from 'components/molecule/Footer';
import Button from 'components/molecule/Button';

import { StyledMenu } from './styles';
import { MenuProps } from './types';

const Menu: React.FC<MenuProps> = ({
  highlightOptions = [],
  options = [],
  organizationName = 'USEFLOW',
  onAfterClick,
}) => {
  const navigate = useNavigate();

  const handleClickOption = (to: string | undefined) => {
    if (!to) return;
    navigate(to);
    if (onAfterClick) {
      onAfterClick(to);
    }
  };

  return (
    <StyledMenu>
      <div className="highlight-options">
        {highlightOptions.map((highlightOption, index) => (
          <Button
            key={index}
            radius="0px"
            leftIcon={highlightOption.icon}
            onClick={() => handleClickOption(highlightOption.to)}
            disabled={highlightOption.disabled}
          >
            {highlightOption.label}
          </Button>
        ))}
      </div>
      <div className="options default-scroll">
        {options.map((option, index) => {
          if (option.url) {
            return (
              <Button
                key={index}
                radius="0px"
                disabled={option.disabled}
                onClick={option.onClick}
              >
                {option.label}
              </Button>
            );
          }

          return (
            <Button
              key={index}
              radius="0px"
              to={option.to}
              disabled={option.disabled}
            >
              {option.label}
            </Button>
          );
        })}
      </div>
      <Footer organizationName={organizationName} />
    </StyledMenu>
  );
};

export default Menu;
