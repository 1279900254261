import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Image from 'components/molecule/Image';

import imagePlaceholder from 'assets/images/rectangle.png';
import noInternetGif from 'assets/images/nointernet.gif';

import { StyledImages } from './styles';

const Images: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledImages>
      <Text as="h2" className="title">
        {t('Images')}
      </Text>

      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('With image (src)')}
              </Text>
              <div className="section-group">
                <Image src={imagePlaceholder} width="20%" />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Without image (not found)')}
              </Text>
              <div className="section-group">
                <Image />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Resize image')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                height: 100px
              </Text>
              <div className="section-group">
                <Image src={imagePlaceholder} height="100px" />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                height: 200px
              </Text>
              <div className="section-group">
                <Image src={imagePlaceholder} height="200px" />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                height: 150px, width: 200px
              </Text>
              <div className="section-group">
                <Image src={imagePlaceholder} height="150px" width="200px" />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                height: 150px, width: 300px
              </Text>
              <div className="section-group">
                <Image src={imagePlaceholder} height="150px" width="300px" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Image radius')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                radius: 0
              </Text>
              <div className="section-group">
                <Image
                  src={imagePlaceholder}
                  radius="0"
                  width="100px"
                  height="100px"
                />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                radius: 10%
              </Text>
              <div className="section-group">
                <Image
                  src={imagePlaceholder}
                  radius="10%"
                  width="100px"
                  height="100px"
                />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                radius: 25%
              </Text>
              <div className="section-group">
                <Image
                  src={imagePlaceholder}
                  radius="25%"
                  width="100px"
                  height="100px"
                />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                radius: 50%
              </Text>
              <div className="section-group">
                <Image
                  src={imagePlaceholder}
                  radius="50%"
                  width="100px"
                  height="100px"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Animated image (GIF)')}
          </Text>
          <Separator />

          <div className="section-content">
            <div className="section-group">
              <Image src={noInternetGif} width="300px" height="300px" />
            </div>
          </div>
        </section>
      </div>
    </StyledImages>
  );
};

export default Images;
