import styled from 'styled-components';

export const StyledTagsModal = styled.div`
  h5 {
    margin-bottom: 8px;
  }

  .search-row {
    margin-top: 22px;
    margin-bottom: 12px;

    form {
      display: flex;
      align-items: center;

      width: 100%;
      .input-content {
        padding-left: 0px;
        margin-right: 8px;
      }
    }
  }

  .founded-tags-count {
    margin-bottom: 16px;
  }

  .tag-list,
  .selected-tag-list {
    display: flex;
    flex-wrap: wrap;

    .button {
      margin-right: 16px;
      margin-bottom: 16px;
      text-align: start;
      height: auto;
    }
  }

  .load-more-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  hr {
    margin-bottom: 16px;
  }

  .loading {
    margin: 16px 0px;
  }

  .selected-tag-list {
    margin-bottom: 16px;
    .button {
      .button-content {
        display: flex;
        align-items: center;

        .icon {
          margin-left: 8px;
        }
      }
    }
  }

  .save-button {
    width: 100%;
  }
`;
