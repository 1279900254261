import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import { AvailableTextTypes } from 'components/atom/Text/types';

import { StyledTypography } from './styles';
import { docTypographySizes } from './utils';

const Typography: React.FC = () => {
  const { t } = useTranslation();

  const renderTypography = (
    fontName: string,
    fontWeight: string,
    typeName: string,
    label: string,
    index: number,
  ) => {
    const type = typeName as keyof typeof AvailableTextTypes;

    return (
      <Text key={index} as={type} family={fontName} weight={fontWeight}>
        {label}
      </Text>
    );
  };

  return (
    <StyledTypography>
      <Text as="h2" className="title">
        {t('Typography')}
      </Text>

      <section>
        <Text as="h4" className="section-title">
          {t('Hind - Primary')}
        </Text>
        <Separator />

        <div className="typography-wrapper">
          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Lighter
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Hind', 'lighter', typeName, label, index),
            )}
          </div>

          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Normal
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Hind', 'normal', typeName, label, index),
            )}
          </div>

          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Bold
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Hind', 'bold', typeName, label, index),
            )}
          </div>

          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Bolder
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Hind', 'bolder', typeName, label, index),
            )}
          </div>
        </div>
      </section>

      <section>
        <Text as="h4" className="section-title">
          {t('Volkhov - Secondary')}
        </Text>
        <Separator />

        <div className="typography-wrapper">
          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Lighter
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Volkhov', 'lighter', typeName, label, index),
            )}
          </div>

          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Normal
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Volkhov', 'normal', typeName, label, index),
            )}
          </div>

          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Bold
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Volkhov', 'bold', typeName, label, index),
            )}
          </div>

          <div className="typography-content">
            <Text as="p" color="grayscale-200">
              Bolder
            </Text>
            {Object.entries(docTypographySizes).map(
              ([typeName, label], index) =>
                renderTypography('Volkhov', 'bolder', typeName, label, index),
            )}
          </div>
        </div>
      </section>
    </StyledTypography>
  );
};

export default Typography;
