import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Dropdown from 'components/molecule/Dropdown';
import Button from 'components/molecule/Button';

import { StyledDropdowns } from './styles';
import { AvailableColorTypes } from '../../../styles/global-styles';
import { AvailableThemeTypes } from '../../../styles/button-themes';

interface ExampleDropdownProps {
  dropdownContentColor: string;
  toggleButtonTheme: string;
  contentPlace: string;
}

const ExampleDropdown: React.FC<ExampleDropdownProps> = ({
  dropdownContentColor,
  contentPlace,
  toggleButtonTheme,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const contentColor = dropdownContentColor as keyof typeof AvailableColorTypes;
  const buttonTheme = toggleButtonTheme as keyof typeof AvailableThemeTypes;

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      toggleElement="Toggle"
      dropdownContentColor={contentColor}
      toggleButtonTheme={buttonTheme}
      contentPlace={contentPlace}
    >
      <Button
        theme="link-dark"
        leftIcon="dashboard-fill"
        radius="0px"
        size="big"
      >
        {t('Option 1')}
      </Button>
      <Button
        theme="link-dark"
        leftIcon="dashboard-fill"
        radius="0px"
        size="big"
      >
        {t('Option 2')}
      </Button>
    </Dropdown>
  );
};

const Dropdowns: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledDropdowns>
      <Text as="h2" className="title">
        {t('Dropdowns')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Content place')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                left
              </Text>
              <div className="section-group">
                <ExampleDropdown
                  dropdownContentColor="white-color"
                  toggleButtonTheme="link-dark-grey"
                  contentPlace="left"
                />
              </div>
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                right
              </Text>
              <div className="section-group">
                <ExampleDropdown
                  dropdownContentColor="white-color"
                  toggleButtonTheme="link-dark-grey"
                  contentPlace="right"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Toggle button themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableThemeTypes).map(
              (themeName: string, index: number) => (
                <div key={index} className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {themeName}
                  </Text>
                  <div className="section-group">
                    <ExampleDropdown
                      key={index}
                      dropdownContentColor="white-color"
                      toggleButtonTheme={themeName}
                      contentPlace="left"
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Content colors')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableColorTypes).map(
              (colorName: string, index: number) => (
                <div key={index} className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {colorName}
                  </Text>
                  <div className="section-group">
                    <ExampleDropdown
                      dropdownContentColor={colorName}
                      toggleButtonTheme="link-dark-grey"
                      contentPlace="left"
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        </section>
      </div>
    </StyledDropdowns>
  );
};

export default Dropdowns;
