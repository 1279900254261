import React from 'react';

import { useTranslation } from 'react-i18next';

import Image from 'components/molecule/Image';
import Text from 'components/atom/Text';

import { StyledMuralTableContent } from './styles';

interface MuralTableContentProps {
  title: string;
  description: string;
  src?: string | null;
}

const MuralTableContent: React.FC<MuralTableContentProps> = ({
  title,
  description,
  src,
}) => {
  const { t } = useTranslation();

  return (
    <StyledMuralTableContent>
      <Image src={src} width="124px" height="70px" />
      <div className="texts">
        {title && (
          <Text weight="700" className="title" color="grayscale-400">
            {title}
          </Text>
        )}
        {!title && (
          <Text weight="700" italic className="title" color="grayscale-400">
            {t('No title')}
          </Text>
        )}
        <Text color="grayscale-200" className="description">
          {description}
        </Text>
      </div>
    </StyledMuralTableContent>
  );
};

export default MuralTableContent;
