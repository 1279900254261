import React from 'react';

import Button from 'components/molecule/Button';
import Card from 'components/atom/Card';

import { AvailableColorTypes } from 'styles/global-styles';

import { StyledDropdown } from './styles';
import { AvailableThemeTypes } from '../../../styles/button-themes';

interface DropdownProps {
  open?: boolean;
  setOpen: (value: boolean) => void;
  toggleElement: React.ReactNode;
  dropdownContentColor?: keyof typeof AvailableColorTypes;
  toggleButtonTheme?: keyof typeof AvailableThemeTypes;
  contentPlace?: string;
  children?: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({
  open = true,
  setOpen,
  toggleElement,
  toggleButtonTheme = 'link-dark-gray',
  dropdownContentColor = 'white-color',
  contentPlace = 'left',
  children,
}) => {
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setOpen(false);
      }
    }, 0);
  };

  return (
    <StyledDropdown
      contentPlace={contentPlace}
      dropdownOpen={open}
      onBlur={handleBlur}
      className={`dropdown ${open ? 'open' : ''}`}
    >
      <Button
        className="dropdown-toggle"
        theme={toggleButtonTheme}
        onClick={() => setOpen(!open)}
        size="small"
      >
        {toggleElement}
      </Button>
      <Card
        shadow="true"
        className="dropdown-content"
        color={dropdownContentColor}
      >
        {children}
      </Card>
    </StyledDropdown>
  );
};

export default Dropdown;
