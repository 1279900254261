import React from 'react';

import { StyledCreationLayout } from './styles';

const CreationLayout: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children }) => {
  return (
    <StyledCreationLayout>
      <div className="container default-scroll">{children}</div>
    </StyledCreationLayout>
  );
};

export default CreationLayout;
