import styled from 'styled-components';

export const StyledPublicationDateModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .tab-menu {
    margin-bottom: 20px;
    width: max-content;
  }
`;
