import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAppLoading } from 'store/slices/appLoading';
import { setOrganization } from 'store/slices/organization';
import { setTheme, resetTheme } from 'store/slices/theme';

import { setCurrentOrganizationId } from 'services/auth';

import Header from 'components/atom/Header';
import MainHeaderContent from '../components/MainHeaderContent';

import OrganizationItem from './components/OrganizationItem';
import OrganizationSelect from './components/OrganizationSelect';
import OrganizationMenu from './components/OrganizationMenu';

import {
  SelectedOptionValueType,
  SelectOption,
} from 'components/molecule/Select/types';
import { OrganizationState } from 'store/slices/organization/types';

import { StyledDashboardLayout } from './styles';

const DashboardLayout: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children }) => {
  const dispatch = useAppDispatch();

  const clientName: string = useAppSelector((store) => store.client.name);
  const organizationList: OrganizationState[] = useAppSelector(
    (store) => store.organizations.list,
  );
  const selectedOrganization: OrganizationState = useAppSelector(
    (store) => store.organization,
  );
  const showLoading: boolean = useAppSelector(
    (store) => store.pageLoading.show,
  );

  const [options, setOptions] = useState<readonly SelectOption[]>([]);
  const [selectedOption, setSelectedOption] =
    useState<SelectedOptionValueType>(null);

  const handleChangeOption = (newOption: SelectedOptionValueType) => {
    if (!newOption || !('value' in newOption)) {
      return;
    }

    dispatch(setAppLoading(true));

    setTimeout(() => {
      const newOrganizationId = parseInt(newOption.value.split('__')[1]);
      const [newOrganization] = organizationList.filter(
        (organization) => parseInt(organization.pk) === newOrganizationId,
      );

      dispatch(setOrganization({ ...newOrganization }));
      if (newOrganization.color_scheme) {
        dispatch(setTheme({ ...newOrganization.color_scheme }));
      } else {
        dispatch(resetTheme());
      }
      setCurrentOrganizationId(newOrganization.pk);
      setSelectedOption(newOption);

      dispatch(setAppLoading(false));
    }, 400);
  };

  useEffect(() => {
    if (organizationList.length > 0) {
      const currentOptions = organizationList.map((organization) => ({
        value: `${organization.name}__${organization.pk}`,
        label: (
          <OrganizationItem
            avatar={organization.avatar}
            name={organization.name}
            clientName={organization.client || clientName}
          />
        ),
      }));

      const [currentOption] = currentOptions.filter(
        (item) =>
          item.value ===
          `${selectedOrganization.name}__${selectedOrganization.pk}`,
      );

      setOptions(currentOptions);
      setSelectedOption(currentOption);
    }
  }, [
    clientName,
    organizationList,
    selectedOrganization.pk,
    selectedOrganization.name,
  ]);

  return (
    <StyledDashboardLayout>
      <div className="menus">
        <OrganizationSelect
          value={selectedOption}
          setValue={handleChangeOption}
          options={options}
          loading={showLoading}
        />
        <div className="menus-wrapper">
          <OrganizationMenu selectedOrganization={selectedOrganization} />
        </div>
      </div>

      <div className="content default-scroll">
        <Header
          rightSideContent={
            <MainHeaderContent className="right-side-content" />
          }
        />
        <div className="container default-scroll">{children}</div>
      </div>
    </StyledDashboardLayout>
  );
};

export default DashboardLayout;
