import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue, MultiValue } from 'react-select';
import dayjs from 'dayjs';

import Select from 'components/molecule/Select';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import DatePicker from 'components/molecule/DatePicker';

import {
  operatorOptionsFactory,
  getSelectedEntity,
  getSelectedOperator,
  segmentationValueOptionsFactory,
  convertToValueOption,
  convertOptionToSegmentationOption,
} from './utils';
import { apiDateToDayjsFormat, dateAndTimeToApiDate } from 'utils/date';

import {
  SelectedOptionValueType,
  SelectOption,
} from 'components/molecule/Select/types';
import { SelectOperatorOption, SegmentationRowProps } from './types';
import {
  SegmentationOptionProps,
  SegmentationSettingProps,
  SegmentationValueTypes,
} from '../SegmentationEditor/types';

import { StyledSegmentationRow } from './styles';

const hiddenFieldOperators = ['is_set', 'is_not_set'];

const SegmentationRow: React.FC<SegmentationRowProps> = ({
  rowId,
  firstItem,
  config,
  segmentationRow,
  setSegmentation,
  onRemove,
}) => {
  const { t } = useTranslation();

  const [entityOptions, setEntityOptions] = useState<SelectOption[]>([]);
  const [selectedEntity, setSelectedEntity] =
    useState<SingleValue<SelectOption>>(null);

  const [operatorOptions, setOperatorOptions] = useState<
    SelectOperatorOption[]
  >([]);
  const [selectedOperator, setSelectedOperator] =
    useState<SingleValue<SelectOption>>(null);

  const [inputValues, setInputValues] = useState<string[] | number[]>([]);
  const [dateValues, setDateValues] = useState<string[]>([]);
  const [selectValues, setSelectValues] = useState<SegmentationOptionProps[]>(
    [],
  );

  const [valueTimeOut, setValueTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const handleChangeEntity = (selectedOption: SelectedOptionValueType) => {
    if (!Array.isArray(selectedOption)) {
      const option = selectedOption as SingleValue<SelectOption>;

      const configItem = config.find((item) => item.key === option?.value);
      const entityOperators = configItem?.operators;

      if (entityOperators) {
        const newOperatorOptions = operatorOptionsFactory(entityOperators);
        setOperatorOptions(newOperatorOptions);
        setSelectedOperator(newOperatorOptions[0]);
      }

      setSegmentation((lastState) => {
        const entityIndex = lastState.findIndex(
          (item) => item.id === segmentationRow.id,
        );
        const lastSegmentation = [...lastState];
        const updatedItemData = {
          key: configItem ? configItem.key : '',
          values: [],
          operator: configItem ? configItem.operators[0].key : '',
          type: configItem ? configItem.type : '',
        };

        lastSegmentation[entityIndex] = {
          ...lastSegmentation[entityIndex],
          ...updatedItemData,
        };

        return lastSegmentation;
      });

      setInputValues(['']);
      setDateValues(['']);
      setSelectValues([]);
      setSelectedEntity(option);
    }
  };

  const handleChangeOperator = (selectedOption: SelectedOptionValueType) => {
    if (!Array.isArray(selectedOption)) {
      const option = selectedOption as SingleValue<SelectOption>;

      setSelectValues([]);

      setSegmentation((lastState) => {
        const entityIndex = lastState.findIndex(
          (item) => item.id === segmentationRow.id,
        );
        const lastSegmentation = [...lastState];
        const updatedItemData = {
          values: [],
          operator: option ? option.value : '',
        };

        lastSegmentation[entityIndex] = {
          ...lastSegmentation[entityIndex],
          ...updatedItemData,
        };

        return lastSegmentation;
      });

      setSelectedOperator(option);
    }
  };

  const handleChangeInputValues = (value: string) => {
    if (valueTimeOut) {
      clearTimeout(valueTimeOut);
    }

    const timeOut = setTimeout(() => {
      setSegmentation((lastSegmentation) =>
        lastSegmentation.map((item) => {
          if (item.id === segmentationRow.id) {
            const updatedItemData = { values: [value] };
            item = { ...item, ...updatedItemData };
          }
          return item;
        }),
      );
    }, 400);

    setInputValues([value]);
    setValueTimeOut(timeOut);
  };

  const handleChangeDateValues = (date: Date) => {
    if (valueTimeOut) {
      clearTimeout(valueTimeOut);
    }

    const timeOut = setTimeout(() => {
      setSegmentation((lastSegmentation) =>
        lastSegmentation.map((item) => {
          if (item.id === segmentationRow.id) {
            const updatedItemData = { values: [dateAndTimeToApiDate(date)] };
            item = { ...item, ...updatedItemData };
          }
          return item;
        }),
      );
    }, 400);

    setDateValues([dateAndTimeToApiDate(date)]);
    setValueTimeOut(timeOut);
  };

  const handleChangeSelectValues = (values: SegmentationValueTypes) => {
    if (Array.isArray(values)) {
      const currentValues = values as unknown as MultiValue<SelectOption>;
      const newValues = currentValues.map((item) =>
        convertOptionToSegmentationOption(item),
      );

      setSelectValues(newValues);

      setSegmentation((lastSegmentation) =>
        lastSegmentation.map((item) => {
          if (item.id === segmentationRow.id) {
            const updatedItemData = { values: newValues };
            item = { ...item, ...updatedItemData };
          }
          return item;
        }),
      );
      return;
    }

    const currentValues = values as unknown as SingleValue<SelectOption>;
    const newValues = [convertOptionToSegmentationOption(currentValues)];

    setSelectValues(newValues);

    setSegmentation((lastSegmentation) =>
      lastSegmentation.map((item) => {
        if (item.id === segmentationRow.id) {
          const updatedItemData = { values: newValues };
          item = { ...item, ...updatedItemData };
        }
        return item;
      }),
    );
  };

  const renderInputField = (values: string[] | number[], inputType: string) => {
    return (
      <Input
        type={inputType}
        placeholder={
          inputType === 'number'
            ? t('Enter number here...')
            : t('Enter text here...')
        }
        value={values[0]}
        onChange={(event) => handleChangeInputValues(event.target.value)}
      />
    );
  };

  const renderSelectField = (
    values: SelectOption[],
    selectedEntityConfig: SegmentationSettingProps,
    isMulti: boolean,
  ) => {
    return (
      <Select
        value={values}
        setValue={handleChangeSelectValues}
        options={segmentationValueOptionsFactory(
          selectedEntityConfig.options || [],
        )}
        className="segmentation-value-select"
        theme="default"
        placeholder={t('Select...')}
        apiUrl={selectedEntityConfig.url?.replace('/v2', '')}
        isSearchable
        isMulti={isMulti}
      />
    );
  };

  const renderDateField = (values: string[]) => {
    return (
      <DatePicker
        value={
          values[0]
            ? dayjs(apiDateToDayjsFormat(values[0])).toDate()
            : undefined
        }
        onChange={handleChangeDateValues}
      />
    );
  };

  const getField = (fieldType: string) => {
    const selectedEntityConfig = config.find(
      (item) => item.key === segmentationRow.key,
    );

    if (!selectedEntityConfig || !selectedOperator) return '';

    const operator = selectedOperator as SingleValue<SelectOption>;

    if (hiddenFieldOperators.some((item) => item === operator?.value)) {
      return '';
    }

    switch (fieldType) {
      case 'string': {
        const stringValues = inputValues ? (inputValues as string[]) : [];
        return renderInputField(stringValues, 'text');
      }
      case 'number': {
        const numberValues = inputValues ? (inputValues as number[]) : [];
        return renderInputField(numberValues, 'number');
      }
      case 'objects': {
        const selectValueOption = convertToValueOption(
          selectValues as unknown as SegmentationOptionProps[],
        );
        const isMulti = operator && operator.many ? operator.many : false;
        return renderSelectField(
          selectValueOption,
          selectedEntityConfig,
          isMulti,
        );
      }
      case 'boolean': {
        const selectValueOption = convertToValueOption(
          selectValues as unknown as SegmentationOptionProps[],
        );
        const isMulti = operator && operator.many ? operator.many : false;
        return renderSelectField(
          selectValueOption,
          selectedEntityConfig,
          isMulti,
        );
      }
      case 'date': {
        const stringValues = dateValues ? (dateValues as string[]) : [];
        return renderDateField(stringValues);
      }
      default: {
        return '';
      }
    }
  };

  const getType = () => {
    const entity = config.find((item) => item.key === selectedEntity?.value);
    return entity ? entity.type : '';
  };

  const handleClear = () => {
    setSegmentation((lastState) => {
      const entityIndex = lastState.findIndex(
        (item) => item.id === segmentationRow.id,
      );
      const lastSegmentation = [...lastState];
      const updatedItemData = {
        values: [],
      };

      if (entityIndex !== -1) {
        lastSegmentation[entityIndex] = {
          ...lastSegmentation[entityIndex],
          ...updatedItemData,
        };
      }

      return [...lastSegmentation];
    });

    const { type } = segmentationRow;

    if (type === 'string' || type === 'number') {
      setInputValues(['']);
    }

    if (type === 'date') {
      setDateValues(['']);
    }

    if (type === 'objects' || type === 'boolean') {
      setSelectValues([]);
    }
  };

  const handleRemove = () => {
    onRemove();
  };

  const fieldIsVisible = () =>
    operatorOptions.length > 0 &&
    !hiddenFieldOperators.some((item) => item === selectedOperator?.value);

  useEffect(() => {
    const currentEntityOptions = config.map((item) => ({
      value: item.key,
      label: (
        <Button theme="link-white" size="small">
          {item.title}
        </Button>
      ),
    }));

    const selectedEntity = getSelectedEntity(config, segmentationRow);

    const operators = config.find(
      (item) => item.key === segmentationRow.key,
    )?.operators;

    const currentOperatorOptions = operatorOptionsFactory(operators || []);

    const selectedOperator = getSelectedOperator(config, segmentationRow);

    setEntityOptions(currentEntityOptions);
    setSelectedEntity(selectedEntity);
    setOperatorOptions(currentOperatorOptions);
    setSelectedOperator(selectedOperator);

    if (
      segmentationRow.type === 'string' ||
      segmentationRow.type === 'number'
    ) {
      const currentInputValues = segmentationRow.values as string[] | number[];
      setInputValues(currentInputValues);
    }

    if (segmentationRow.type === 'date') {
      const currentDateValues = segmentationRow.values as string[];
      setDateValues(currentDateValues);
    }

    if (
      segmentationRow.type === 'objects' ||
      segmentationRow.type === 'boolean'
    ) {
      const currentSelectValues =
        segmentationRow.values as SegmentationOptionProps[];
      setSelectValues(currentSelectValues);
    }
  }, [rowId, config, segmentationRow, segmentationRow.values]);

  return (
    <StyledSegmentationRow className="segmentation-row">
      <div className="entity-wrapper">
        {!firstItem && (
          <Tag padding="10px" radius="4px">
            {t('and')}
          </Tag>
        )}
        <Select
          selectKey={segmentationRow.id || ''}
          value={selectedEntity}
          setValue={handleChangeEntity}
          options={entityOptions}
          className="entity-select"
          theme="dark"
          placeholder={t('Select...')}
        />
      </div>
      <div className="operator-wrapper">
        {operatorOptions.length > 0 && (
          <Select
            selectKey={segmentationRow.id || ''}
            value={selectedOperator}
            setValue={handleChangeOperator}
            options={operatorOptions}
            className="operator-select"
            theme="default"
            placeholder={t('Select...')}
          />
        )}
      </div>
      <div className="segmentation-field">{getField(getType())}</div>
      <div className="actions-wrapper">
        {fieldIsVisible() && (
          <Button theme="link-gray-primary" size="small" onClick={handleClear}>
            {t('Clear')}
          </Button>
        )}
        <Button theme="link-gray-primary" size="small" onClick={handleRemove}>
          <Icon name="close" />
        </Button>
      </div>
    </StyledSegmentationRow>
  );
};

export default memo(SegmentationRow);
