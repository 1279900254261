import styled from 'styled-components';

export const StyledTypography = styled.div.attrs(() => ({
  className: 'typography-page',
}))`
  padding: 16px;

  div > .text {
    margin-bottom: 16px;
  }

  .section-title {
    margin-top: 32px;
  }

  .typography-wrapper {
    display: flex;
    margin: 30px;

    .typography-content {
      margin: 10px 50px 50px 30px;
    }
  }
`;
