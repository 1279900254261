import React, { useEffect, useState } from 'react';

import { useAppDispatch } from 'store/hooks';
import { closeMessage } from 'store/slices/toaster';

import Alert from 'components/molecule/Alert';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { AvailableIcons } from 'components/atom/Icon/types';

import { StyledToaster } from './styles';

interface ToasterProps {
  show?: string;
  time?: number;
  icon?: keyof typeof AvailableIcons;
  title?: string;
  description?: string;
  theme?: string;
  customLeft?: string;
  className?: string;
  children?: React.ReactNode;
  hideCloseButton?: boolean;
}

const Toaster: React.FC<ToasterProps> = ({
  show = 'false',
  time = 15000,
  icon = '',
  title = '',
  description = '',
  theme = 'success',
  customLeft = '156px',
  children,
  hideCloseButton = false,
}) => {
  const dispatch = useAppDispatch();

  const [toasterTimeout, setToasterTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  const handleCloseToaster = () => {
    clearTimeout(toasterTimeout);
    setToasterTimeout(undefined);
    dispatch(closeMessage());
  };

  useEffect(() => {
    if (show === 'true') {
      const currentTimeout = setTimeout(() => {
        dispatch(closeMessage());
      }, time);
      setToasterTimeout(currentTimeout);
    }
  }, [show, dispatch, time]);

  return (
    <StyledToaster className="toaster" customLeft={customLeft} show={show}>
      <Alert icon={icon} title={title} description={description} theme={theme}>
        {children}
        {!hideCloseButton && (
          <Button
            theme="link-dark"
            size="small"
            className="alert-close-button"
            onClick={handleCloseToaster}
          >
            <Icon name="close" />
          </Button>
        )}
      </Alert>
    </StyledToaster>
  );
};

export default Toaster;
