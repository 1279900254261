import React from 'react';

import Text from 'components/atom/Text';

import { StyledMuralTableCreatedBy } from './styles';

interface MuralTableCreatedByProps {
  value: string;
}

const MuralTableCreatedBy: React.FC<MuralTableCreatedByProps> = ({ value }) => {
  return (
    <StyledMuralTableCreatedBy>
      <Text as="pre" color="grayscale-200">
        {value}
      </Text>
    </StyledMuralTableCreatedBy>
  );
};

export default MuralTableCreatedBy;
