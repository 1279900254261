import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Separator from 'components/atom/Separator';
import Text from 'components/atom/Text';
import Editor from 'components/molecule/Editor';

import { FileUpload } from 'services/upload';
import { FileUploadModules } from 'services/upload/types';

import { StyledEditors } from './styles';

interface ExampleEditorProps {
  id: string;
  disabled?: boolean;
  toolbar?: boolean;
  resizable?: boolean;
  value?: string;
  label?: string;
  focusedLabel?: string;
  placeholder?: string;
  placeholderAsLabel?: boolean;
  limit?: number;
  hideCharacterCount?: boolean;
}

const ExampleEditor: React.FC<ExampleEditorProps> = ({
  id,
  disabled = false,
  toolbar = false,
  resizable = true,
  value = '',
  label,
  focusedLabel,
  placeholder,
  placeholderAsLabel,
  limit,
  hideCharacterCount,
}) => {
  const { t } = useTranslation();
  const [editorContent, setEditorContent] = useState(value);

  // Example of callback to send uploaded image to somewhere
  const fileUploader = new FileUpload();
  const handleUpload = (image: File, progress: (percent: number) => void) => {
    return fileUploader.send(image, FileUploadModules.BOARD, '4', progress);
  };

  return (
    <div className="editor-wrapper">
      <Editor
        id={id}
        disabled={disabled}
        toolbar={toolbar}
        resizable={resizable}
        value={editorContent}
        label={label}
        focusedLabel={focusedLabel}
        placeholder={placeholder}
        placeholderAsLabel={placeholderAsLabel}
        limit={limit}
        hideCharacterCount={hideCharacterCount}
        onChange={(value) => setEditorContent(value)}
        onUpload={handleUpload}
      />
      <div className="code-preview">
        {editorContent && (
          <>
            <p>{t('Code preview:')}</p>
            <p>{editorContent}</p>
          </>
        )}
      </div>
    </div>
  );
};

const Editors: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledEditors>
      <Text as="h2" className="title">
        {t('Editors')}
      </Text>
      <div className="editors-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />
          <ExampleEditor id="basic-usage-editor" />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With toolbar')}
          </Text>
          <Separator />
          <ExampleEditor id="with-toolbar-editor" toolbar />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With initial text and disabled')}
          </Text>
          <Separator />
          <ExampleEditor
            id="with-initial-text-disabled-editor"
            value={t('This is an initial text set')}
            disabled
          />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With placeholder set')}
          </Text>
          <Separator />
          <ExampleEditor
            id="with-placeholder-set-editor"
            placeholder={t('Example of custom placeholder set')}
          />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With placeholder as label')}
          </Text>
          <Separator />
          <ExampleEditor
            id="with-placeholder-as-label-editor"
            placeholder={t('Example of custom placeholder set')}
            placeholderAsLabel
          />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With label')}
          </Text>
          <Separator />
          <ExampleEditor id="with-label-editor" label={t('Label')} />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With custom focused label')}
          </Text>
          <Separator />
          <ExampleEditor
            id="with-custom-focused-label-editor"
            focusedLabel={t('Custom focused label')}
            placeholder={t('Example of custom placeholder set')}
          />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With maximum of characters set')}
          </Text>
          <Separator />
          <ExampleEditor
            id="with-max-chars-set-editor"
            placeholder={t('You can add up to 10 characters here')}
            limit={10}
          />
        </section>
      </div>
    </StyledEditors>
  );
};

export default Editors;
