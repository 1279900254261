import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import Separator from 'components/atom/Separator';
import { AvailableTextAlignTypes } from 'components/organism/Table/types';

import { StyledTables } from './styles';
import { generateTableData } from './utils';
import { AvailableColorTypes } from 'styles/global-styles';

interface ExampleTableProps {
  numCols: number;
  numRows: number;
  clickable?: boolean;
  scrollable?: boolean;
  textAlignName?: string;
  fontColorName?: string;
  hasPagination?: boolean;
  pageCount?: number;
  loading?: boolean;
}

const ExampleTable: React.FC<ExampleTableProps> = ({
  numCols,
  numRows,
  clickable,
  scrollable,
  textAlignName,
  fontColorName,
  hasPagination,
  pageCount,
  loading,
}) => {
  const [, setPage] = useState(0);
  const { columns, rows } = generateTableData(numCols, numRows);
  const textAlign = textAlignName as keyof typeof AvailableTextAlignTypes;
  const fontColor = fontColorName as keyof typeof AvailableColorTypes;

  return (
    <Table
      columns={columns}
      rows={rows}
      textAlign={textAlign}
      clickable={clickable}
      scrollable={scrollable}
      fontColor={fontColor}
      hasPagination={hasPagination}
      pageCount={pageCount}
      onChangePage={(newPage: number) => setPage(newPage)}
      loading={loading}
    />
  );
};

const Tables: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledTables>
      <Text as="h2" className="title">
        {t('Tables')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage, with pagination')}
          </Text>
          <Separator />

          <ExampleTable numCols={5} numRows={5} hasPagination pageCount={10} />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage, without pagination')}
          </Text>
          <Separator />

          <ExampleTable numCols={5} numRows={5} />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage, scrollable')}
          </Text>
          <Separator />

          <ExampleTable numCols={20} numRows={5} scrollable />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Example showing data loading')}
          </Text>
          <Separator />

          <ExampleTable numCols={5} numRows={5} loading />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Example with no data (empty)')}
          </Text>
          <Separator />

          <ExampleTable numCols={0} numRows={0} />
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Content text alignment examples')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableTextAlignTypes).map(
              (value: string, index: number) => (
                <div key={index} className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {value}
                  </Text>

                  <ExampleTable numCols={2} numRows={2} textAlignName={value} />
                </div>
              ),
            )}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Content font color examples')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableColorTypes).map(
              (value: string, index: number) => (
                <div key={index} className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {value}
                  </Text>
                  <ExampleTable numCols={2} numRows={2} fontColorName={value} />
                </div>
              ),
            )}
          </div>
        </section>
      </div>
    </StyledTables>
  );
};

export default Tables;
