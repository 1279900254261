import styled from 'styled-components';

export const StyledPollSurveyContent = styled.div`
  .poll-type-wrapper {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .select {
      max-width: max-content;

      .button-content {
        margin-top: 0px;
      }
    }
  }

  .title {
    margin-bottom: 16px;
    .input-content {
      padding: 0px;

      input {
        font-size: 38px;
      }
    }
  }

  .description {
    .textarea-content {
      padding: 0px;
    }
  }

  .poll-options {
    margin-top: 32px;
  }
`;
