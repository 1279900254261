import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Card from 'components/atom/Card';

import { StyledCard } from './styles';

const Cards: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <Text as="h2" className="title">
        {t('Cards')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Card types')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Default card')}
              </Text>
              <div className="section-group">
                <Card>Card</Card>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Card with shadow')}
              </Text>
              <div className="section-group">
                <Card shadow="true">Card</Card>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Card without border and shadow')}
              </Text>
              <div className="section-group">
                <Card shadow="false" noBorder="true">
                  Card
                </Card>
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Card colors')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Card with custom color')}
              </Text>
              <div className="section-group">
                <Card color="gray-color" noBorder="true">
                  Card
                </Card>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Card with custom color')}
              </Text>
              <div className="section-group">
                <Card color="primary-color" noBorder="true">
                  <Text as="p" color="white-color">
                    Card
                  </Text>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledCard>
  );
};

export default Cards;
