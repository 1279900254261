import parse, { DOMNode, Element } from 'html-react-parser';

function isDOMNodeElement(domNode: DOMNode): domNode is Element {
  return domNode.type === 'tag';
}

export interface CustomAttributes {
  [tagName: string]: { [attribute: string]: string };
}

export const parseSvg = (value: string, attributes?: CustomAttributes) => {
  return parse(value, {
    replace: (domNode: DOMNode) => {
      if (!isDOMNodeElement(domNode) || !attributes) return;

      if (domNode.name in attributes) {
        domNode.attribs = { ...domNode.attribs, ...attributes[domNode.name] };
      }
    },
  });
};
