import styled from 'styled-components';

export const StyledNotificationsConfig = styled.div`
  h5 {
    margin-bottom: 40px;
  }

  .collapses {
    margin-bottom: 24px;
    .collapse-header {
      padding: 8px 0px;
      .button {
        & * {
          color: var(--grayscale-400);
        }
      }
    }
  }

  .checkbox-wrapper {
    & * {
      pointer-events: none;
    }
  }

  & > .button {
    width: 100%;
  }
`;
