import React from 'react';

import Text from 'components/atom/Text';

import { StyledRadio } from './styles';

interface RadioProps {
  id?: string;
  text?: React.ReactNode;
  className?: string;
  checked?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Radio: React.FC<RadioProps> = ({
  id,
  text,
  className = '',
  checked,
  name,
  disabled,
  onChange,
}) => {
  return (
    <StyledRadio
      className={`radio container ${className} ${disabled ? 'disabled' : ''}`}
    >
      {text && <Text>{text}</Text>}
      <input
        id={id}
        type="radio"
        checked={checked}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </StyledRadio>
  );
};

export default Radio;
