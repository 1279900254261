import styled from 'styled-components';

export const StyledConfirmPublish = styled.div`
  .modal-content {
    margin: 0 0 42px 0;

    h4 {
      margin-bottom: 6px;
    }

    .scheduler-preview {
      margin-top: 42px;
    }

    .publish-dates {
      margin-top: 64px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .point-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 28px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 50%;
            height: 12px;
            top: 5px;
            left: 0px;
            background: var(--grayscale-100);
          }

          &::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 12px;
            top: 5px;
            right: 0px;
            background: var(--grayscale-100);
          }

          .point {
            width: 22px;
            height: 22px;
            background: var(--success-color);
            border-radius: 50%;
            border: 2px solid var(--white-color);
            z-index: var(--z-index-level-1);
          }
        }

        &:first-child .point-wrapper::before {
          border-radius: 22px 0px 0px 22px;
        }

        &:first-child .point-wrapper::after {
          background: var(--success-color);
        }

        &.is-single:first-child .point-wrapper::before {
          background: var(--grayscale-100);
        }

        &:last-child .point-wrapper::after {
          border-radius: 0px 22px 22px 0px;
        }

        &:last-child .point-wrapper::before {
          background: var(--success-color);
        }

        pre {
          margin-bottom: 4px;
          padding: 0px 16px;
        }
      }
    }
  }

  .modal-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
