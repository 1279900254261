import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { createIdentity } from 'apis/sso';

import { closeModal } from 'store/slices/modal';

import AppLoading from 'components/organism/AppLoading';

import { StyledRedirectToLegacy } from './styles';

const RedirectToLegacy: React.FC = () => {
  const [searchParams] = useSearchParams();

  const url = searchParams.get('to');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const organizationPk: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const userPk: number | null = useAppSelector((state) => state.user.id);

  useEffect(() => {
    dispatch(closeModal());

    if (!url) {
      navigate('/');
      return;
    }

    createIdentity({ user: userPk, organization: organizationPk })
      .then((resp) => {
        window.location.href = url.replace('[ID]', resp.data.pk);
      })
      .catch(() => {
        navigate('/');
      });
  }, [navigate, dispatch, url, organizationPk, userPk]);

  return (
    <StyledRedirectToLegacy>
      <AppLoading removeAnimation />
    </StyledRedirectToLegacy>
  );
};

export default RedirectToLegacy;
