import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getCheckins } from 'apis/board';

import { useAppSelector } from 'store/hooks';

import Search from 'components/organism/Search';
import FilterItem from '../FilterItem';
import Text from 'components/atom/Text';
import Card from 'components/atom/Card';
import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import Button from 'components/molecule/Button';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';
import { CheckinResult } from 'apis/board/types';
import { SearchResult } from 'components/organism/Search/types';

import { StyledCheckinFilter } from './styles';

export interface CheckinFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const CheckinFilter: React.FC<CheckinFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  const [isOpen, setIsOpen] = useState(filter.checkin.length > 0);
  const [items, setItems] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SearchResult[]>([]);

  const handleClear = () => {
    const newFilter = { ...filter, checkin: [] };
    setFilter(newFilter);
    setSelectedItems([]);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleLoadCheckins = (
    search: string,
    page: number,
    isLoadMore?: boolean,
  ) => {
    setLoading(true);

    getCheckins(organizationId, {
      search,
      page,
    })
      .then((response) => {
        const { next, results } = response.data;
        setHasMore(!!next);

        if (isLoadMore) {
          setItems((lastItems) => {
            return [
              ...lastItems,
              ...results.map((item: CheckinResult) => ({
                id: item.id,
                label: item.location,
                disabled: selectedItems.some(
                  (selectedItem) => item.id === selectedItem.id,
                ),
              })),
            ];
          });
          return;
        }

        setItems(
          results.map((item: CheckinResult) => ({
            id: item.id,
            label: item.location,
            disabled: selectedItems.some(
              (selectedItem) => item.id === selectedItem.id,
            ),
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdd = (item: SearchResult) => {
    const newCheckin = [...filter.checkin];
    newCheckin.push({
      id: item.id,
      label: item.label,
    });

    setFilter({ ...filter, checkin: newCheckin });
    setSelectedItems([...selectedItems, item]);
  };

  const handleRemove = (item: SearchResult) => {
    setFilter({
      ...filter,
      checkin: [
        ...filter.checkin.filter((selectedItem) => selectedItem.id !== item.id),
      ],
    });
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem.id !== item.id),
    );
  };

  useEffect(() => {
    setSelectedItems(
      filter.checkin.map((item) => ({
        id: item.id,
        label: item.label,
      })),
    );
  }, [filter.checkin]);

  return (
    <StyledCheckinFilter>
      <FilterItem
        title={t('Check-in')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={filter.checkin.length > 0}
        onClear={handleClear}
        showCheckAllButton={false}
      >
        <div className="checkin-filter-header">
          <Search
            onLoad={handleLoadCheckins}
            hasMore={hasMore}
            loading={loading}
            onClick={handleAdd}
            results={items}
          />
          <div className="counter">
            <Text color="dark-color">{selectedItems.length}</Text>
            <Text color="grayscale-200">
              {t('selected', { count: selectedItems.length })}
            </Text>
          </div>
        </div>
        <div className="checkin-filter-content">
          {selectedItems.length === 0 && (
            <Card noBorder="true" color="gray-color">
              <Icon name="map-pin-2-line" color="grayscale-200" />
              <Text color="grayscale-200">
                {t('Search for locations, select and add tags to your list')}
              </Text>
            </Card>
          )}

          <div className="tag-list">
            {selectedItems.map((item, index) => (
              <Tag radius="6px" key={index}>
                <Text weight="700">{item.label}</Text>
                <Button
                  size="small"
                  theme="link-dark"
                  onClick={() => handleRemove(item)}
                >
                  <Icon name="close" />
                </Button>
              </Tag>
            ))}
          </div>
        </div>
      </FilterItem>
    </StyledCheckinFilter>
  );
};

export default CheckinFilter;
