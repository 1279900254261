import { useTranslation } from 'react-i18next';

import {
  OrganizationState,
  LegacyMenuState,
} from 'store/slices/organization/types';
import { MenuOptionProp } from 'components/organism/Menu/types';

export const useLegacyMenuOptions = (
  currentOrganization: OrganizationState,
  onClickLegacyMenuOption: (url: string) => void,
): MenuOptionProp[] => {
  const { t } = useTranslation();

  const translatedLabels: Record<string, string> = {
    task: t('Task'),
    publishing: t('Publishing'),
    alert: t('Alert'),
    pages: t('Pages'),
    contact: t('Contact'),
    forum: t('Forum'),
    survey: t('Survey'),
    library: t('Library'),
    training: t('Training'),
    performance: t('Performance'),
    report: t('Report'),
    ranking: t('Ranking'),
    notifications: t('Notifications'),
    settings: t('Settings'),
    members: t('Members'),
    profile: t('Profile'),
    metlife: t('Metlife'),
    sales: t('Sales'),
    downloads: t('Downloads'),
  };
  const legacyMenuUrls: Record<string, string> =
    currentOrganization.legacy_menu.reduce(
      (accumulator: Record<string, string>, current: LegacyMenuState) => ({
        ...accumulator,
        [current.menu]: current.url,
      }),
      {},
    );

  return Object.keys(translatedLabels)
    .filter((key: string) => key in legacyMenuUrls)
    .map((key: string) => ({
      label: translatedLabels[key],
      url: legacyMenuUrls[key],
      onClick: () => onClickLegacyMenuOption(legacyMenuUrls[key]),
      disabled: false,
    }));
};
