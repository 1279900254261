import styled from 'styled-components';

export const StyledMultiRange = styled.div`
  width: 100%;

  .multi-range-slider {
    box-shadow: none;
    border: none;
    padding: 10px 0px;

    .bar {
      .bar-left,
      .bar-inner,
      .bar-right {
        height: 12px;
        box-shadow: none;
        border: none;
      }
    }

    .thumb {
      &::before {
        border: 1px solid var(--white-color);
        box-shadow: none;
        margin: -4px -12px;
        width: 18px;
        height: 18px;
      }

      .caption {
        * {
          display: none;
        }
      }
    }
  }

  &.large {
    .multi-range-slider {
      padding: 0px;

      .bar {
        .bar-left,
        .bar-inner,
        .bar-right {
          height: 32px;
        }

        .bar-left {
          border-radius: 22px 0px 0px 22px;
        }
        .bar-right {
          border-radius: 0px 22px 22px 0px;
        }
      }

      .thumb {
        &::before {
          border: 4px solid var(--white-color);
          box-shadow: none;
          margin: -1px -12px;
          width: 26px;
          height: 26px;
        }
      }
    }
  }
`;
