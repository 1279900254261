import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAccessToken } from 'services/auth';

const useValidateAccess = () => {
  const navigate = useNavigate();
  const accessToken = getAccessToken();

  const [isValid, setIsValid] = useState(!!accessToken);

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
      setIsValid(false);
      return;
    }
    setIsValid(true);
  }, [accessToken, navigate]);

  return isValid;
};

export default useValidateAccess;
