import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PublicationDateConfig from './components/PublicationDateConfig';
import RecurrenceConfig from './components/RecurrenceConfig';
import TabMenu from 'components/molecule/TabMenu';

import { PublicationDateModalProps } from './types';

import { StyledPublicationDateModal } from './styles';

const PublicationDateModal: React.FC<PublicationDateModalProps> = ({
  contentState,
  setContentState,
  canEditFinishAt,
  withRecurrence,
  setWithRecurrence,
  contentStatus,
}) => {
  const { t } = useTranslation();

  const [menuOptions, setMenuOptions] = useState([
    {
      key: 'without-recurrence',
      content: t('Without recurrence'),
      active: !withRecurrence,
    },
    {
      key: 'with-recurrence',
      content: t('Recurrence'),
      active: withRecurrence,
    },
  ]);

  const onChangeTab = (key: string) => {
    setMenuOptions(
      menuOptions.map((option) => {
        if (option.key === key) {
          option.active = true;
          return option;
        }

        option.active = false;
        return option;
      }),
    );
  };

  return (
    <StyledPublicationDateModal>
      <TabMenu tabs={menuOptions} onChangeTab={onChangeTab} />

      {menuOptions[0].active && (
        <PublicationDateConfig
          contentState={contentState}
          setContentState={setContentState}
          canEditFinishAt={canEditFinishAt}
          setWithRecurrence={setWithRecurrence}
          contentStatus={contentStatus}
        />
      )}

      {menuOptions[1].active && (
        <RecurrenceConfig
          contentState={contentState}
          setContentState={setContentState}
          canEditFinishAt={canEditFinishAt}
          setWithRecurrence={setWithRecurrence}
          contentStatus={contentStatus}
        />
      )}
    </StyledPublicationDateModal>
  );
};

export default PublicationDateModal;
