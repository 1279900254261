import styled from 'styled-components';

export const StyledMuralTableActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .text {
    max-width: 13ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-toggle {
    padding: 8px;
    border-radius: 50%;
  }

  .dropdown-content {
    .button {
      width: 100%;
      border-bottom: 1px solid var(--grayscale-300);
      justify-content: flex-start;
      border-radius: 6px;

      &:last-child {
        border: none;
      }
    }
  }
`;
