import axios, { AxiosRequestConfig } from 'axios';

import { BASE_SSO_URL, SSO_APP_ID, SSO_APP_KEY } from 'settings';

const instance = axios.create({
  baseURL: BASE_SSO_URL,
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.auth = {
    username: SSO_APP_ID,
    password: SSO_APP_KEY,
  };

  return config;
});

export default instance;
