import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectContentTypeItem from 'pages/board/Mural/components/SelectContentTypeItem';

import { SelectOption } from 'components/molecule/Select/types';
import { useAppSelector } from 'store/hooks';
import { AvailableOrganizationModules } from 'utils/organization';

const useSelectTypeOptions = () => {
  const { t } = useTranslation();
  const organizationModules = useAppSelector(
    (store) => store.organization.modules,
  );

  // base options (valid for all organization)
  const selectOptions: SelectOption[] = [
    {
      value: '',
      label: (
        <SelectContentTypeItem
          icon="checkbox-blank-circle-line"
          label={t('All')}
        />
      ),
    },
  ];

  // specific options (by modules)
  if (organizationModules) {
    const hasAdvocacyModuleSet: boolean = organizationModules.includes(
      AvailableOrganizationModules.advocacy,
    );
    const hasCommModuleSet: boolean = organizationModules.includes(
      AvailableOrganizationModules.comm,
    );

    if (hasCommModuleSet) {
      selectOptions.push(
        {
          value: 'post',
          label: (
            <SelectContentTypeItem
              icon="chat-4-fill"
              label={t('Publications')}
            />
          ),
        },
        {
          value: 'survey',
          label: (
            <SelectContentTypeItem
              icon="checkbox-circle-fill"
              label={t('Quick surveys')}
            />
          ),
        },
        {
          value: 'checkin',
          label: (
            <SelectContentTypeItem
              icon="map-pin-2-fill"
              label={t('Check-ins')}
            />
          ),
        },
      );
    }
    if (hasAdvocacyModuleSet) {
      selectOptions.push({
        value: 'task',
        label: (
          <SelectContentTypeItem
            icon="share-fill"
            label={t('Social network actions')}
          />
        ),
      });
    }
  }

  return selectOptions;
};

export default useSelectTypeOptions;
