import styled from 'styled-components';

interface StyledFooterProps {
  background: string;
}

export const StyledFooter = styled.footer.attrs(() => ({
  className: 'footer',
}))<StyledFooterProps>`
  background: var(--${(props) => props.background});
  padding: 20px 24px;
  height: 72px;
  box-sizing: border-box;

  small,
  i {
    color: var(--white-color);
    opacity: 0.75;
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 10px;
    border-radius: 6px;
    background: var(--grayscale-300);
    margin-right: 12px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;
