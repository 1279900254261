import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Avatar from 'components/molecule/Avatar';

import imagePlaceholder from 'assets/images/rectangle.png';

import { AvailableAvatarThemeTypes } from 'styles/avatar-themes';

import { StyledAvatars } from './styles';

const Avatars: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAvatars>
      <Text as="h2" className="title">
        {t('Avatars')}
      </Text>
      <div className="avatars-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Avatar />
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With icon or image')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="p" color="grayscale-200">
                With icon
              </Text>
              <Avatar size="44px" />
            </div>
            <div className="section-content">
              <Text as="p" color="grayscale-200">
                With image
              </Text>
              <Avatar size="44px" src={imagePlaceholder} />
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Predefined sizes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="p" color="grayscale-200">
                44px
              </Text>
              <Avatar size="44px" />
            </div>
            <div className="section-content">
              <Text as="p" color="grayscale-200">
                66px
              </Text>
              <Avatar size="66px" />
            </div>
            <div className="section-content">
              <Text as="p" color="grayscale-200">
                88px
              </Text>
              <Avatar size="88px" />
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Available themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableAvatarThemeTypes).map(
              (value: string, index: number) => {
                const theme = value as keyof typeof AvailableAvatarThemeTypes;

                return (
                  <div key={index} className="section-content">
                    <Text as="p" color="grayscale-200">
                      {value}
                    </Text>
                    <Avatar size="44px" theme={theme} />
                  </div>
                );
              },
            )}
          </div>
        </section>
      </div>
    </StyledAvatars>
  );
};

export default Avatars;
