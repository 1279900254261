import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Textarea from 'components/molecule/Textarea';

import { StyledTextareas } from './styles';

const Textareas: React.FC = () => {
  const { t } = useTranslation();

  const [input1Value, setInput1Value] = useState('');
  const [input2Value, setInput2Value] = useState('');
  const [input3Value, setInput3Value] = useState('');
  const [input4Value, setInput4Value] = useState('');

  const handleChangeInput1 = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setInput1Value(event.target.value);
  };
  const handleChangeInput2 = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setInput2Value(event.target.value);
  };
  const handleChangeInput3 = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setInput3Value(event.target.value);
  };
  const handleChangeInput4 = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setInput4Value(event.target.value);
  };

  return (
    <StyledTextareas>
      <Text as="h2" className="title">
        {t('Textareas')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Textareas')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Textarea props')}
              </Text>
              <div className="section-group">
                <Textarea
                  id="input-test-1"
                  label={t('Label test')}
                  placeholder={t('Placeholder...')}
                  limit={10}
                  value={input1Value}
                  onChange={handleChangeInput1}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Input with error')}
              </Text>
              <div className="section-group">
                <Textarea
                  id="input-test-2"
                  label={t('Label test')}
                  placeholder={t('Placeholder...')}
                  value={input2Value}
                  onChange={handleChangeInput2}
                  hasError={true}
                  errorMessage={t('Error message')}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Disabled')}
              </Text>
              <div className="section-group">
                <Textarea
                  id="input-test-2"
                  label={t('Label test')}
                  placeholder={t('Placeholder...')}
                  value={input2Value}
                  onChange={handleChangeInput2}
                  disabled
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('With placeholder as label')}
              </Text>
              <div className="section-group">
                <Textarea
                  id="input-test-2"
                  placeholder={t('Placeholder...')}
                  placeholderAsLabel
                  value={input3Value}
                  onChange={handleChangeInput3}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('With custom focused label')}
              </Text>
              <div className="section-group">
                <Textarea
                  id="custom-focused-label"
                  placeholder={t('Placeholder...')}
                  focusedLabel={t('Custom focused label')}
                  placeholderAsLabel
                  value={input4Value}
                  onChange={handleChangeInput4}
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Default')}
              </Text>
              <div className="section-group">
                <Textarea placeholder={t('Placeholder...')} />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  hasError={true}
                  errorMessage={t('Error message')}
                />
              </div>
              <div className="section-group">
                <Textarea placeholder={t('Placeholder...')} disabled />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Gray')}
              </Text>
              <div className="section-group">
                <Textarea placeholder={t('Placeholder...')} theme="gray" />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="gray"
                />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="gray"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Post')}
              </Text>
              <div className="section-group">
                <Textarea placeholder={t('Placeholder...')} theme="post" />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="post"
                />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="post"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Shadow')}
              </Text>
              <div className="section-group">
                <Textarea placeholder={t('Placeholder...')} theme="shadow" />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="shadow"
                />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="shadow"
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Secondary dark')}
              </Text>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  theme="secondary-dark"
                />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  value="test"
                  hasError={true}
                  errorMessage={t('Error message')}
                  theme="secondary-dark"
                />
              </div>
              <div className="section-group">
                <Textarea
                  placeholder={t('Placeholder...')}
                  disabled
                  theme="secondary-dark"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledTextareas>
  );
};

export default Textareas;
