export const getPluginOptions = () => [
  'advlist',
  'autolink',
  'directionality',
  'emoticons',
  'link',
  'lists',
  'image',
  'quickbars',
  'wordcount',
];

export const getToolbarOptions = (isShowingToolbar: boolean) => {
  if (!isShowingToolbar) return false;

  return [
    'blocks',
    'bold italic forecolor backcolor',
    'alignleft aligncenter alignright alignjustify',
    'bullist numlist outdent indent',
    'ltr rtl',
  ].join(' | ');
};

export const getQuickbarSelectionOptions = (isShowingToolbar: boolean) => {
  if (isShowingToolbar) {
    return ['bold italic underline', 'quicklink blockquote'].join(' | ');
  }

  return [
    'blocks',
    'bold italic underline forecolor backcolor',
    'quicklink blockquote',
    'alignleft aligncenter alignright alignjustify',
    'bullist numlist outdent indent',
    'ltr rtl',
  ].join(' | ');
};

export const getQuickbarInsertOptions = () => {
  return 'quickimage emoticons hr';
};

export const getDefaultEditorCSSRules = () => {
  // language=css
  return `
    @import url('https://fonts.googleapis.com/css2?family=Hind&display=swap');

    body {
      font-family: Hind, sans-serif;
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      font-style: normal;
      text-align: start;
      font-size: 16px;
      font-weight: 550;
      color: #B4B6CB;
    }

    .mce-content-body {
      margin: 1px;
    }
  `;
};
