import styled from 'styled-components';

import { AvailableColorTypes } from 'styles/global-styles';

interface StyledProgressBarTypes {
  color: keyof typeof AvailableColorTypes;
  completed: number;
}

export const StyledProgressBar = styled.div<StyledProgressBarTypes>`
  height: 12px;
  width: 100%;
  background-color: var(--grayscale-100);
  border-radius: 22px;

  & > div {
    height: 100%;
    width: ${({ completed }) => completed}%;
    background-color: var(--${({ color }) => color});
    border-radius: inherit;
  }
`;
