export const docTypographySizes: { [key: string]: string } = {
  h1: 'Large',
  h2: 'Title 1',
  h3: 'Title 2',
  h4: 'Subtitle 1',
  h5: 'Subtitle 2',
  h6: 'Body 1',
  p: 'Body 2',
  pre: 'Caption',
  small: 'Underline',
};
