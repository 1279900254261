import React from 'react';

import { StyledProgressBar } from './styles';

import { AvailableColorTypes } from 'styles/global-styles';

interface ProgressBarProps {
  color?: keyof typeof AvailableColorTypes;
  completed: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  color = 'primary-color',
  completed,
}) => {
  return (
    <StyledProgressBar
      className="progress-bar"
      color={color}
      completed={completed}
    >
      <div>
        <span></span>
      </div>
    </StyledProgressBar>
  );
};

export default ProgressBar;
