import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/atom/Tooltip';

import { AvailablePlaceTypes } from './types';

interface CopyToClipboardProps {
  children?: React.ReactNode;
  copyId: string;
  message: string;
  successMessage?: string;
  messagePlace?: keyof typeof AvailablePlaceTypes;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  message,
  successMessage,
  messagePlace = 'top',
  copyId,
  children,
}) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(message);
    setCopied(true);
  };

  return (
    <Tooltip
      tooltipId={copyId}
      message={successMessage || t('Copied!')}
      keepShow={copied.toString()}
      place={messagePlace}
      clickable={true}
      afterShow={handleCopy}
    >
      {children}
    </Tooltip>
  );
};

export default CopyToClipboard;
