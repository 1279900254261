import React from 'react';

import { StyledSwitchButton } from './styles';

interface SwitchButtonProps {
  className?: string;
  id: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  className,
  id,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <StyledSwitchButton
      className={`switch-button ${className} ${disabled ? 'disabled' : ''}`}
    >
      <input
        id={id}
        className="switch switch--shadow"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}></label>
    </StyledSwitchButton>
  );
};

export default SwitchButton;
