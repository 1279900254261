import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';
import CopyToClipboard from 'components/molecule/CopyToClipboard';
import { AvailablePlaceTypes } from 'components/molecule/CopyToClipboard/types';

import { StyledCopyToClipboards } from './styles';
import { docClipboardPlaceTypes } from './utils';

const CopyToClipboards: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCopyToClipboards>
      <Text as="h2" className="title">
        {t('Copy To Clipboards')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Success message places')}
          </Text>
          <Separator />

          <div className="section-contents">
            {docClipboardPlaceTypes.map((type, index) => {
              const place = type.placeName as keyof typeof AvailablePlaceTypes;

              return (
                <div key={index} className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t(type.label)}
                  </Text>
                  <div className="section-group">
                    <CopyToClipboard
                      copyId={`copy-test-${index}`}
                      message="Test"
                      successMessage="Copied"
                      messagePlace={place}
                    >
                      <Button size="small" theme="link-gray-primary">
                        {t('Copy')}
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </StyledCopyToClipboards>
  );
};

export default CopyToClipboards;
