// see: https://developers.google.com/maps/documentation/javascript/overview#zoom-levels
export enum GoogleMapZoomLevels {
  World = 1,
  Continent = 5,
  City = 10,
  Streets = 15,
  Buildings = 20,
}

// see: https://developers.google.com/maps/documentation/javascript/maptypes#BasicMapTypes
export enum GoogleMapTypes {
  Roadmap = 'ROADMAP',
  Satellite = 'SATELLITE',
  Hybrid = 'HYBRID',
  Terrain = 'TERRAIN',
}

export interface Location {
  lat: number;
  lng: number;
  location: string; // address
}
