import styled from 'styled-components';

export const StyledDependencyConfig = styled.div`
  .modal-title {
    margin-bottom: 4px;
  }

  .modal-description {
    margin-bottom: 28px;
  }

  .filters {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .select {
      width: 100%;
      max-width: 200px;
      margin-right: 10px;

      .react-select__control {
        padding-left: 0px;
      }
    }

    .input {
      .input-content {
        padding-right: 6px;
        height: 56px;
      }
    }
  }

  .contents-wrapper {
    height: 400px;
    overflow-y: scroll;
  }

  .no-content-message {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 32px 0px;
  }

  .loading {
    padding: 32px 0px;
  }

  .load-more {
    padding: 0px;
    margin-top: 24px;
  }

  .actions {
    margin-top: 24px;
    .button {
      width: 100%;
    }
  }
`;

export const StyledDependencyContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0px;
  padding-right: 10px;
  border-bottom: 1px solid var(--gray-color);

  .radio {
    width: max-content;
    top: -10px;
  }

  label {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    .image {
      margin-right: 12px;
    }

    .texts {
      flex: 1;
      padding-right: 8px;
      .title {
        display: -webkit-box;
        max-width: 264px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .description {
        margin-top: 4px;
      }
    }

    .content-info {
      width: 164px;

      & * {
        max-width: 26ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: end;
      }

      pre:first-child {
        margin-bottom: 4px;
      }
    }
  }
`;
