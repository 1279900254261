import React from 'react';
import { useTranslation } from 'react-i18next';

import Menu from 'components/organism/Menu';
import FastMenu from 'components/organism/FastMenu';
import Header from 'components/atom/Header';
import Logo from 'components/molecule/Logo';

import { StyledDocumentationLayout } from './styles';
import { FastMenuOptionProp } from 'components/organism/FastMenu/types';
import { MenuOptionProp } from 'components/organism/Menu/types';

const DocumentationLayout: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children }) => {
  const { t } = useTranslation();

  const highlightOptions: MenuOptionProp[] = [
    {
      icon: 'home-6-fill',
      label: t('Home'),
      to: '/dashboard',
      disabled: false,
    },
    {
      icon: 'dashboard-fill',
      label: t('Mural'),
      to: '/',
      disabled: false,
    },
    {
      icon: 'level',
      label: t('Reports'),
      to: '/',
      disabled: false,
    },
  ];
  const options: MenuOptionProp[] = [
    {
      icon: '',
      label: t('Typography'),
      to: '/doc/typography',
      disabled: false,
    },
    { icon: '', label: t('Icons'), to: '/doc/icons', disabled: false },
    {
      icon: '',
      label: t('Buttons'),
      to: '/doc/buttons',
      disabled: false,
    },
    {
      icon: '',
      label: t('Colors'),
      to: '/doc/colors',
      disabled: false,
    },
    {
      icon: '',
      label: t('Cards'),
      to: '/doc/cards',
      disabled: false,
    },
    {
      icon: '',
      label: t('Alerts'),
      to: '/doc/alerts',
      disabled: false,
    },
    {
      icon: '',
      label: t('Toasters'),
      to: '/doc/toasters',
      disabled: false,
    },
    {
      icon: '',
      label: t('Loadings'),
      to: '/doc/loadings',
      disabled: false,
    },
    {
      icon: '',
      label: t('Inputs'),
      to: '/doc/inputs',
      disabled: false,
    },
    {
      icon: '',
      label: t('Tooltips'),
      to: '/doc/tooltips',
      disabled: false,
    },
    {
      icon: '',
      label: t('Copy to clipboards'),
      to: '/doc/copy-to-clipboards',
      disabled: false,
    },
    {
      icon: '',
      label: t('Textareas'),
      to: '/doc/textareas',
      disabled: false,
    },
    {
      icon: '',
      label: t('Dropdowns'),
      to: '/doc/dropdowns',
      disabled: false,
    },
    {
      icon: '',
      label: t('Selects'),
      to: '/doc/selects',
      disabled: false,
    },
    {
      icon: '',
      label: t('Checkboxes and radios'),
      to: '/doc/checkboxes',
      disabled: false,
    },
    {
      icon: '',
      label: t('Tags'),
      to: '/doc/tags',
      disabled: false,
    },
    {
      icon: '',
      label: t('Images'),
      to: '/doc/images',
      disabled: false,
    },
    {
      icon: '',
      label: t('Tables'),
      to: '/doc/tables',
      disabled: false,
    },
    {
      icon: '',
      label: t('Empty messages'),
      to: '/doc/empty-messages',
      disabled: false,
    },
    {
      icon: '',
      label: t('Modals'),
      to: '/doc/modals',
      disabled: false,
    },
    {
      icon: '',
      label: t('Avatars'),
      to: '/doc/avatars',
      disabled: false,
    },
    {
      icon: '',
      label: t('Editors'),
      to: '/doc/editors',
      disabled: false,
    },
    {
      icon: '',
      label: t('Datepickers'),
      to: '/doc/datepickers',
      disabled: false,
    },
    {
      icon: '',
      label: t('Ranges'),
      to: '/doc/ranges',
      disabled: false,
    },
    {
      icon: '',
      label: t('Collapses'),
      to: '/doc/collapses',
      disabled: false,
    },
    {
      icon: '',
      label: t('Maps'),
      to: '/doc/maps',
      disabled: false,
    },
  ];

  // Fast menu options
  const topOptions: FastMenuOptionProp[] = [
    {
      icon: 'chat-4-fill',
      path: '/',
      disabled: false,
    },
    {
      icon: 'action',
      path: '/',
      disabled: false,
    },
    {
      icon: 'checkbox-circle-fill',
      path: '/',
      disabled: false,
    },
  ];

  const bottomOptions: FastMenuOptionProp[] = [
    {
      icon: 'user-fill',
      path: '/',
      disabled: false,
    },
    {
      icon: 'question-fill',
      path: '/',
      disabled: false,
    },
    {
      icon: 'settings-4-fill',
      path: '/',
      disabled: false,
    },
  ];

  return (
    <StyledDocumentationLayout>
      <div className="menus-wrapper">
        <FastMenu topOptions={topOptions} bottomOptions={bottomOptions} />
        <Menu highlightOptions={highlightOptions} options={options} />
      </div>
      <div className="content default-scroll">
        <Header leftSideContent={<Logo />} />
        {children}
      </div>
    </StyledDocumentationLayout>
  );
};

export default DocumentationLayout;
