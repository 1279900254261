import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledIcons } from './styles';
import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Icon from 'components/atom/Icon';
import { docIconSection } from './utils';
import { DocIconSectionType } from './types';
import { AvailableIcons } from 'components/atom/Icon/types';

const Icons: React.FC = () => {
  const { t } = useTranslation();

  const renderIcons = (key: string) => (
    <div key={key} className="icon-content">
      <Icon name={key as keyof typeof AvailableIcons} color="grayscale-300" />
      <Text className="icon-name" color="grayscale-200">
        {key}
      </Text>
    </div>
  );

  const renderSection = (section: DocIconSectionType, subsection: boolean) => (
    <section
      key={section.label}
      className={`${subsection ? 'subsection' : 'section'}-wrapper`}
    >
      <Text
        as="h4"
        className={`${subsection ? 'subsection' : 'section'}-title`}
        color={subsection ? 'grayscale-300' : 'dark-color'}
      >
        {t(section.label)}
      </Text>
      {!subsection && <Separator />}

      {section.subsections && (
        <div className="subsection-wrapper">
          {section.subsections.map((section: DocIconSectionType) =>
            renderSection(section, true),
          )}
        </div>
      )}

      {section.iconNames && (
        <div className="icons-wrapper">
          {section.iconNames.map(renderIcons)}
        </div>
      )}
    </section>
  );

  return (
    <StyledIcons>
      <Text as="h2" className="title">
        {t('Icons')}
      </Text>

      <div className="doc-icons-wrapper">
        {docIconSection.map((section: DocIconSectionType) =>
          renderSection(section, false),
        )}
      </div>
    </StyledIcons>
  );
};

export default Icons;
