import styled from 'styled-components';

export const StyledNPSScale = styled.div`
  width: 100%;
  margin-top: 44px;

  & > div {
    width: 100%;
  }

  .nps-labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .range-scale {
    margin-bottom: 10px;
    width: 100%;

    .multi-range-scale {
      width: 100%;
      display: flex;
      justify-content: center;

      .range {
        width: 100%;

        .multi-range-slider {
          .thumb-left::before {
            border-color: var(--danger-color);
            height: 24px;
            width: 24px;
            margin: 0px -14px;
          }
          .thumb-right::before {
            border-color: var(--success-color);
            height: 24px;
            width: 24px;
            margin: 0px -13px;
          }

          .bar-left,
          .bar-right {
            border-radius: 0px;
          }
        }
      }
    }

    .scale {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .scale-item {
        flex: 1;
        height: 66px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;

        .text {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 36px;
            background: var(--grayscale-100);
            top: -44px;
            left: 4px;
          }
        }

        &:first-child::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 32px;
          background: var(--danger-color);
          border-radius: 22px 0px 0px 22px;
          top: -42px;
          left: 0;
        }

        &:last-child::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 32px;
          background: var(--success-color);
          border-radius: 0px 22px 22px 0px;
          top: -42px;
          left: 0;
        }
      }
    }
  }

  .nps-fields {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;

    .nps-field {
      width: 320px;
      display: flex;
      align-items: center;

      & > .text {
        min-width: max-content;
        margin-right: 10px;
        margin-top: -18px;
      }
    }
  }

  .loading {
    width: 100%;
    height: 316px;
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
