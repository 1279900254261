import React from 'react';
import ReactMultiRange from 'multi-range-slider-react';

import { MultiRangeProps } from './types';

import { StyledMultiRange } from './styles';

const MultiRange: React.FC<MultiRangeProps> = ({
  barLeftColor = 'var(--grayscale-100)',
  barInnerColor = 'var(--primary-color-300)',
  barRightColor = 'var(--grayscale-100)',
  thumbLeftColor = 'var(--primary-color)',
  thumbRightColor = 'var(--primary-color)',
  minValue = 25,
  maxValue = 75,
  min = 0,
  max = 100,
  stepOnly = false,
  step = 5,
  subSteps = false,
  onChange,
  type = 'normal',
}) => {
  return (
    <StyledMultiRange className={`multi-range ${type}`}>
      <ReactMultiRange
        ruler={false}
        label={false}
        subSteps={subSteps}
        barLeftColor={barLeftColor}
        barInnerColor={barInnerColor}
        barRightColor={barRightColor}
        thumbLeftColor={thumbLeftColor}
        thumbRightColor={thumbRightColor}
        minValue={minValue}
        maxValue={maxValue}
        min={min}
        max={max}
        stepOnly={stepOnly}
        step={step}
        onChange={onChange}
      />
    </StyledMultiRange>
  );
};

export default MultiRange;
