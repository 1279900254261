import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue, MultiValue } from 'react-select';

import { useAppSelector } from 'store/hooks';

import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import CoverImage from 'components/organism/CoverImage';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import PollOptions from './components/PollOptions';

import { PollSurveyContentProps } from './types';
import { CoverProps } from 'components/organism/CoverImage/types';
import { SurveyContentFields } from '../../types';
import { SelectOption } from 'components/molecule/Select/types';

import { StyledPollSurveyContent } from './styles';

const PollSurveyContent: React.FC<PollSurveyContentProps> = ({
  pollContentState,
  setContentState,
  contentStatus,
}) => {
  const { t } = useTranslation();

  const pollTypes: SingleValue<SelectOption>[] = [
    {
      value: 'single',
      label: (
        <Button theme="link-gray-primary" size="small">
          {t('Single choice')}
        </Button>
      ),
    },
    {
      value: 'multi',
      label: (
        <Button theme="link-gray-primary" size="small">
          {t('Multi choice')}
        </Button>
      ),
    },
  ];

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const getSelectedSubtype = (subtype?: string | null) => {
    if (!subtype) {
      return pollTypes[0];
    }

    return pollTypes.find((type) => type?.value === subtype);
  };

  const handleChangeSubtype = (
    option: SingleValue<SelectOption> | MultiValue<SelectOption>,
  ) => {
    const currentOption = option as SingleValue<SelectOption>;

    if (!currentOption) return;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      subtype: currentOption.value,
    }));
  };

  const handleChangeCover = (cover: CoverProps) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      cover: {
        ...updatedState.cover,
        value: cover.id,
        url: cover.url,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      description: {
        ...updatedState.description,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  return (
    <StyledPollSurveyContent>
      <div className="poll-type-wrapper">
        <Select
          value={getSelectedSubtype(pollContentState.subtype) || pollTypes[0]}
          setValue={handleChangeSubtype}
          options={pollTypes as SelectOption[]}
          className="operator-select"
          theme="no-outline"
          placeholder={t('Select...')}
        />
      </div>

      <CoverImage
        coverUrl={pollContentState.cover.url}
        setCover={handleChangeCover}
        organizationId={organizationId}
        hasError={pollContentState.cover.error.hasError}
        errorMessage={pollContentState.cover.error.errorMessage}
      />

      <Input
        id="image-survey-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={pollContentState.title.value?.toString() || undefined}
        onChange={handleChangeTitle}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={pollContentState.title.error.hasError}
        errorMessage={pollContentState.title.error.errorMessage}
      />
      <Textarea
        id="image-survey-description-textarea"
        className="description"
        theme="post"
        focusedLabel={t('Description')}
        placeholder={`${t('Enter the description')}`}
        value={pollContentState.description.value?.toString() || undefined}
        onChange={handleChangeDescription}
        limit={500}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={pollContentState.description.error.hasError}
        errorMessage={pollContentState.description.error.errorMessage}
        autoResize
      />

      <PollOptions
        options={pollContentState.options}
        setContentState={setContentState}
        contentStatus={contentStatus}
      />
    </StyledPollSurveyContent>
  );
};

export default PollSurveyContent;
