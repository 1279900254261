import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { getBoardContent } from 'apis/board';

import useSelectTypeOptions from 'hooks/board/useSelectTypeOptions';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import Header from 'components/atom/Header';
import Text from 'components/atom/Text';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import Input from 'components/molecule/Input';
import Table from 'components/organism/Table';
import Loading from 'components/molecule/Loading';
import MuralFilter from './components/MuralFilter';

import NewContentModal from './components/NewContentModal';
import {
  MuralTableComments,
  MuralTableContent,
  MuralTableCreatedBy,
  MuralTableInteractions,
  MuralTableStatus,
  MuralTableActions,
} from './components/MuralTable';

import { SelectedOptionValueType } from 'components/molecule/Select/types';
import {
  ColumnProps,
  RowProps,
  SortableValueState,
} from 'components/organism/Table/types';
import {
  BoardContentProps,
  BoardContentTypesEnum,
  MuralFiltersState,
  CustomFilterAttrsTypes,
  CustomFilterSendAttrs,
} from './types';
import { AvailableStatusTypes } from './components/MuralTable/MuralTableStatus/types';
import { AvailableTypes } from 'apis/board/types';

import { abbreviateNumberFormatter } from 'utils/numbers';
import { apiDateToDayjsFormat } from 'utils/date';

import { StyledMural } from './styles';

const customFilterInitialState = {
  publication: [],
  survey: [],
  checkin: [],
  status: [],
  start_at_period_begin: null,
  start_at_period_end: null,
  finish_at_period_begin: null,
  finish_at_period_end: null,
  tag: [],
  can_comment: null,
  comments_operator: null,
  comments_count: null,
  owner: [],
  editorial: [],
};

const Mural: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  const selectOptions = useSelectTypeOptions();

  const [selectedOption, setSelectedOption] = useState<SelectedOptionValueType>(
    selectOptions[0],
  );
  const [boardContentLoading, setBoardContentLoading] = useState(true);
  const [hasContent, setHasContent] = useState(false);
  const [boardTableRows, setBoardTableRows] = useState<RowProps[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [tableLoading, setTableLoading] = useState(false);

  const [muralFilters, setMuralFilters] = useState<MuralFiltersState>({
    page: 0,
    search: '',
    type: '' as keyof typeof AvailableTypes,
    order: [],
  });
  const [customFilter, setCustomFilter] = useState<CustomFilterAttrsTypes>({
    ...customFilterInitialState,
  });

  const showContentOptions = () => {
    dispatch(
      setModalView({
        show: true,
        content: <NewContentModal />,
        width: '704px',
      }),
    );
  };

  const convertFilterToSendData = (filter: CustomFilterAttrsTypes) => {
    return {
      ...filter,
      checkin: filter.checkin.map((item) => item.label),
      owner: filter.owner.map((item) => item.id),
      tag: filter.tag.map((item) => item.id),
    };
  };

  const handleApplyCustomFilter = (filter: CustomFilterAttrsTypes) => {
    const { search, type, order } = muralFilters;
    loadBoardContent(
      1,
      search,
      type,
      order.toString(),
      convertFilterToSendData(filter),
    );
    setCustomFilter(filter);
  };

  const hasCustomFilter = () => {
    const filterArray = Object.keys(customFilter).map((key) => {
      return customFilter[key as keyof CustomFilterAttrsTypes];
    });

    const hasFilter = filterArray.some(
      (item: unknown) =>
        (Array.isArray(item) && item.length !== 0) ||
        (!Array.isArray(item) && item !== null),
    );

    return hasFilter;
  };

  const showMuralFilter = () => {
    dispatch(
      setModalView({
        show: true,
        content: (
          <MuralFilter
            customFilter={customFilter}
            onApply={handleApplyCustomFilter}
            initialState={customFilterInitialState}
          />
        ),
        width: '865px',
        disableBackgroundClick: true,
      }),
    );
  };

  const getPeriodLabel = useCallback(
    (startAt: string | null, finishAt: string | null) => {
      if (!startAt) return '';

      if (!finishAt)
        return `${dayjs(startAt).format('L')} ${t('at')} ${apiDateToDayjsFormat(
          startAt,
        )
          .split(' ')[1]
          .substring(0, 5)}`;

      return `${dayjs(startAt).format('L')} ${t('at')} ${apiDateToDayjsFormat(
        startAt,
      )
        .split(' ')[1]
        .substring(0, 5)} ${t('to')} ${dayjs(finishAt).format('L')} ${t(
        'at',
      )} ${apiDateToDayjsFormat(finishAt).split(' ')[1].substring(0, 5)}`;
    },
    [t],
  );

  const getFormattedDescription = useCallback(
    (tags: number, options: number, type: string): string => {
      const textTags: string =
        tags > 0 ? `${tags} ${t('tag', { count: tags })}` : '';
      let textOpts: string =
        options > 0 ? `${options} ${t('option', { count: options })}` : '';

      if (type === BoardContentTypesEnum.QuickSurvey && options === 0) {
        textOpts = t('No options');
      }

      if (textTags && !textOpts) return textTags;
      else if (!textTags && textOpts) return textOpts;
      else if (textTags && textOpts) return `${textTags} | ${textOpts}`;
      return '';
    },
    [t],
  );

  const getBoardTableRows = useCallback(
    (data: BoardContentProps[]) => {
      return data.map((content) => {
        const {
          id,
          title,
          tags,
          options,
          status,
          start_at,
          finish_at,
          comments,
          interactions,
          owner,
          type,
          subtype,
          cover,
          can_edit,
        } = content;

        return {
          cells: [
            {
              content: (
                <MuralTableContent
                  title={title}
                  description={getFormattedDescription(tags, options, type)}
                  src={cover}
                />
              ),
            },
            {
              content: (
                <MuralTableStatus
                  status={status as keyof typeof AvailableStatusTypes}
                  period={getPeriodLabel(start_at, finish_at)}
                />
              ),
            },
            {
              content: <MuralTableComments total={comments} />,
            },
            {
              content: (
                <MuralTableInteractions
                  total={abbreviateNumberFormatter(interactions)}
                  interaction="smile"
                />
              ),
            },
            {
              content: <MuralTableCreatedBy value={owner} />,
            },
            {
              content: can_edit ? (
                <MuralTableActions
                  contentId={id}
                  contentType={type}
                  contentSubtype={
                    subtype !== 'single' && subtype !== 'multi'
                      ? subtype
                      : 'poll'
                  }
                />
              ) : (
                ''
              ),
            },
          ],
        };
      });
    },
    [getPeriodLabel, getFormattedDescription],
  );

  const loadBoardContent = useCallback(
    (
      page = 1,
      search = '',
      type = '' as keyof typeof AvailableTypes,
      order = '',
      filter = customFilterInitialState as CustomFilterSendAttrs,
    ) => {
      setTableLoading(true);

      getBoardContent(organizationId, {
        page,
        search,
        type,
        order,
        filter,
      })
        .then((response) => {
          const { results, num_pages, recordsTotal } = response.data;

          setPageCount(num_pages);
          setTotalRecords(recordsTotal);
          setBoardTableRows(() => getBoardTableRows(results));
          setMuralFilters((updatedMuralFiltersState) => ({
            ...updatedMuralFiltersState,
            page: page,
          }));

          setBoardContentLoading((loading) => {
            if (loading) {
              setHasContent(() => num_pages !== 0);
            }
            return false;
          });
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate('/');
            return;
          }

          dispatch(
            showMessage({
              title: t('An unexpected error occurred while displaying content'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          setBoardContentLoading(false);
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [dispatch, getBoardTableRows, navigate, organizationId, t],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      const { type, order } = muralFilters;
      loadBoardContent(
        1,
        value,
        type as keyof typeof AvailableTypes,
        order.toString(),
        convertFilterToSendData(customFilter),
      );
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setMuralFilters({ ...muralFilters, search: value });
  };

  const handleChangeType = (option: SelectedOptionValueType) => {
    const currentType = (
      option && 'value' in option ? option.value : 'all'
    ) as keyof typeof AvailableTypes;

    const { search, order } = muralFilters;

    loadBoardContent(
      1,
      search,
      currentType,
      order.toString(),
      convertFilterToSendData(customFilter),
    );
    setMuralFilters({ ...muralFilters, type: currentType });
  };

  const handleChangePage = (newPage: number) => {
    const { search, type, order } = muralFilters;

    loadBoardContent(
      newPage + 1,
      search,
      type as keyof typeof AvailableTypes,
      order.toString(),
      convertFilterToSendData(customFilter),
    );
  };

  const handleOrder = (sortableValue: SortableValueState) => {
    setMuralFilters((updatedMuralFiltersState) => {
      let elementIndex = -1;
      const { page, search, type, order } = updatedMuralFiltersState;

      if (sortableValue.value === 0) {
        elementIndex = order.indexOf(`-${sortableValue.key}`);
        order.splice(elementIndex, elementIndex === -1 ? 0 : 1);
      }

      if (sortableValue.value === 1) {
        elementIndex = order.indexOf(sortableValue.key);
        order.splice(elementIndex, elementIndex === -1 ? 0 : 1);
        order.push(sortableValue.key);
      }

      if (sortableValue.value === -1) {
        elementIndex = order.indexOf(sortableValue.key);
        if (elementIndex !== -1) {
          order[elementIndex] = `-${sortableValue.key}`;
        }
      }

      loadBoardContent(
        page,
        search,
        type,
        order.toString(),
        convertFilterToSendData(customFilter),
      );

      updatedMuralFiltersState.order = order;
      return updatedMuralFiltersState;
    });
  };

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      content: <Text color="grayscale-200">{t('Contents')}</Text>,
      sortable: {
        key: 'title',
        sortableOnClick: handleOrder,
        value: 0,
      },
    },
    {
      content: <Text color="grayscale-200">{t('Status')}</Text>,
      sortable: {
        key: 'created_at',
        sortableOnClick: handleOrder,
        value: 0,
      },
    },
    {
      content: <Text color="grayscale-200">{t('Comments')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Interactions')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Created by')}</Text>,
      sortable: {
        key: 'owner_name',
        sortableOnClick: handleOrder,
        value: 0,
      },
    },
    {
      content: <Text color="grayscale-200"></Text>,
    },
  ]);

  useEffect(() => {
    if (boardContentLoading) {
      loadBoardContent();
    }
  }, [boardContentLoading, loadBoardContent]);

  return (
    <StyledMural>
      <Header
        leftSideContent={
          <div className="left-side-content">
            <Text as="h3" weight="700" color="grayscale-400">
              {t('Professional mural')}
            </Text>
            <Button
              leftIcon="add"
              theme="link-primary"
              onClick={showContentOptions}
            >
              {t('New content')}
            </Button>
          </div>
        }
      />
      <div className="page-content">
        {!boardContentLoading && hasContent && (
          <>
            <div className="filters">
              <Select
                value={selectedOption}
                setValue={setSelectedOption}
                options={selectOptions.filter(
                  (option) =>
                    selectedOption &&
                    'value' in selectedOption &&
                    selectedOption.value !== option.value,
                )}
                className="content-type-select"
                theme="dark"
                onChange={handleChangeType}
              />

              <Input
                placeholder={t('Search by title')}
                icon="search"
                actions={
                  <Button
                    theme="primary-shadow"
                    leftIcon="filter-order"
                    onClick={showMuralFilter}
                    badge={hasCustomFilter() ? ' ' : ''}
                    className="custom-filter-bottom"
                  >
                    {t('Filter')}
                  </Button>
                }
                value={muralFilters.search}
                onChange={handleSearch}
              />

              <Text className="counter" color="grayscale-300" weight="700">
                {abbreviateNumberFormatter(totalRecords)}{' '}
                {t('result', { count: totalRecords })}
              </Text>
            </div>

            <Table
              columns={columns}
              setColumns={setColumns}
              rows={boardTableRows}
              textAlign="start"
              fontColor="grayscale-200"
              hasPagination
              pageCount={pageCount}
              page={muralFilters.page - 1}
              onChangePage={handleChangePage}
              loading={tableLoading}
            />
          </>
        )}

        {boardContentLoading && (
          <Loading width="80px" height="80px" color="primary-color" />
        )}

        {!boardContentLoading && !hasContent && (
          <EmptyMessage
            showImage
            title={t('No content in this community')}
            description={t(
              'Create quick posts, actions and polls to drive community interactions.',
            )}
          >
            <Button
              theme="link-primary"
              size="small"
              onClick={showContentOptions}
            >
              {t('Create content')}
            </Button>
          </EmptyMessage>
        )}
      </div>
    </StyledMural>
  );
};

export default Mural;
