import styled from 'styled-components';

export const StyledMuralTableInteractions = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 4px;
  }

  .text {
    margin-top: 2px;
  }
`;
