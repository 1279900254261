import styled from 'styled-components';

export const StyledCoverImage = styled.div`
  .file-button {
    margin-bottom: 24px;
  }

  .cover-preview {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    min-height: 108px;
    border-radius: 8px;
    background: var(--grayscale-100);

    .cover-preview-actions {
      position: absolute;
      top: 32px;
      left: 32px;
      display: flex;
      align-items: center;

      .button {
        margin-right: 16px;
      }

      .file-button {
        margin-bottom: 0px;
      }

      .button.edit {
        &:hover {
          border-color: var(--white-color) !important;
          box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.05);
        }
        &:active {
          background: var(--white-color);
          & * {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .progress-bar {
    margin-bottom: 24px;
  }
`;
