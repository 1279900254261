import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setUser } from 'store/slices/user';
import { setOrganizations } from 'store/slices/organizations';
import { setOrganization } from 'store/slices/organization';
import { setTheme } from 'store/slices/theme';

import { OrganizationState } from 'store/slices/organization/types';

import { getAccount } from 'apis/auth';

import {
  logout,
  getCurrentOrganizationId,
  setCurrentOrganizationId,
} from 'services/auth';

import useValidateAccess from 'hooks/auth/useValidateAccess';

import AppLoading from 'components/organism/AppLoading';

import { StyledMainRestrictedLayout } from './styles';

interface MainRestrictedLayoutProps {
  children?: React.ReactNode;
}

const MainRestrictedLayout: React.FC<MainRestrictedLayoutProps> = ({
  children,
}) => {
  const effectRan = useRef(false);
  const accessIsValid = useValidateAccess();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hasLoaded: number | null = useAppSelector((store) => store.user.id);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (effectRan.current) {
      return;
    }

    getAccount()
      .then((response) => {
        const { avatar, name, pk, slug, organizations } = response.data;
        dispatch(
          setUser({
            avatar,
            id: pk,
            name,
            slug,
          }),
        );
        dispatch(
          setOrganizations({
            list: organizations || [],
          }),
        );

        const storedOrganizationId = getCurrentOrganizationId();
        let storedOrganization = null;

        if (storedOrganizationId) {
          storedOrganization = organizations.find(
            (organization: OrganizationState) =>
              parseInt(organization.pk) === parseInt(storedOrganizationId),
          );
        }

        const selectedOrganization = storedOrganization || organizations[0];

        setCurrentOrganizationId(selectedOrganization.pk);
        dispatch(setOrganization({ ...selectedOrganization }));

        if (selectedOrganization.color_scheme) {
          dispatch(setTheme({ ...selectedOrganization.color_scheme }));
        }
        setLoaded(true);
      })
      .catch(() => {
        logout();
        navigate('/error-403');
      });

    return () => {
      effectRan.current = true;
    };
  }, [navigate, dispatch, hasLoaded]);

  if (!accessIsValid || !loaded) {
    return <AppLoading removeAnimation />;
  }

  return <StyledMainRestrictedLayout>{children}</StyledMainRestrictedLayout>;
};

export default MainRestrictedLayout;
