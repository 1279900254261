import styled from 'styled-components';

export const StyledPollOption = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;

  .dropdown {
    margin-right: 12px;
    position: absolute;
    left: -52px;

    & > .button {
      width: max-content;
      height: 54px;
    }
  }

  .input {
    .input-content {
      min-height: 54px;
    }

    .actions {
      min-height: 40px;
      .button {
        display: none;
      }
    }

    &:hover {
      .actions {
        .button {
          display: flex;
        }
      }
    }
  }

  .button-check {
    width: 54px;
    height: 54px;
    margin-left: 12px;

    .icon {
      font-size: 28px;
    }

    &.is-correct {
      background: var(--success-color);
      border-color: var(--success-color);

      & * {
        color: var(--white-color);
      }

      &:hover {
        background: var(--success-color);
        border-color: var(--success-color);

        & * {
          color: var(--white-color);
        }
      }
    }

    &.is-correct:disabled {
      background: var(--success-color);
      border-color: var(--success-color);

      & * {
        color: var(--white-color);
      }
    }
  }
`;
