import React from 'react';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import { StyledMuralTableInteractions } from './styles';

interface MuralTableInteractionsProps {
  total: string;
  interaction: string;
}

const MuralTableInteractions: React.FC<MuralTableInteractionsProps> = ({
  total,
}) => {
  return (
    <StyledMuralTableInteractions>
      <Icon name="emotion-line" color="grayscale-200" />
      <Text color="grayscale-400">{total}</Text>
    </StyledMuralTableInteractions>
  );
};

export default MuralTableInteractions;
