import React from 'react';

import Button from '../Button';

import { TabMenuProps } from './types';

import { StyledTabMenu } from './styles';

const TabMenu: React.FC<TabMenuProps> = ({ tabs, onChangeTab }) => {
  const handleTabChange = (key: string) => {
    onChangeTab(key);
  };

  return (
    <StyledTabMenu className="tab-menu">
      {tabs.map((tab, index) => (
        <Button
          key={index}
          theme={tab.active ? 'dark-shadow' : 'link-page-button-gray-primary'}
          rounded="true"
          className={tab.active ? 'active' : ''}
          onClick={() => handleTabChange(tab.key)}
          disabled={tab.disabled}
        >
          {tab.content}
        </Button>
      ))}
    </StyledTabMenu>
  );
};

export default TabMenu;
