import { CSSProperties } from 'react';

import { LoadScriptProps } from '@react-google-maps/api';

import MarkerIcon from 'assets/images/map-pin.svg';

import { GoogleMapZoomLevels } from './types';

/*
  WARNING: As we use useMemo() on Map implementation, those settings
  should be keep out of reach for current GoogleMap instantiation.

  So, all settings from GoogleMap should be kept here, including types.
  Types that we can't import, should be rewritten as current library version.
*/

export enum MapZoomLevelSettings {
  Minimum = GoogleMapZoomLevels.World,
  Maximum = GoogleMapZoomLevels.Buildings,
}

// when map is set, we should init it with a center
// even if we don't use it (we can't set it to null)
export const MAP_DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: 41.1509703,
  lng: -8.6254918,
};

export const mapOptions: google.maps.MapOptions = {
  // other icons on map cannot be clicked by user
  clickableIcons: false,

  // size of map controls on screen, in pixels
  controlSize: 32,

  // toggle Full Screen button
  fullscreenControl: false,

  // toggle buttons to change map to Terrain or Satellite
  mapTypeControl: false,

  // toggle Street View button
  streetViewControl: false,

  // toggle buttons to control map zoom
  // it is disabled because we will draw custom buttons on map
  zoomControl: false,

  // set up gesture to use CTRL+CLICK for map zooming
  gestureHandling: 'cooperative',

  // toggles map being controlled by keyboard
  keyboardShortcuts: false,

  // set min zoom, avoiding underflow when user pan or zoom map
  minZoom: MapZoomLevelSettings.Minimum,

  // set max zoom, avoiding overflow when user pan or zoom map
  maxZoom: MapZoomLevelSettings.Maximum,
};

export const mapContainerStyle: CSSProperties = {
  width: '750px',
  height: '420px',
};

// see: https://github.com/JustFly1984/react-google-maps-api/issues/238
// see: https://github.com/JustFly1984/react-google-maps-api/issues/3011
export const mapLoaderLibraries: LoadScriptProps['libraries'] = ['places'];

export const markerCircleOptions: google.maps.CircleOptions = {
  visible: true,

  // disables user interaction
  clickable: false,
  draggable: false,
  editable: false,

  // customize circle color
  fillColor: '#01102D',
  fillOpacity: 0.12,
  strokeColor: '#B4B6CB',
  strokeOpacity: 1,
};

export const markerOptions: google.maps.MarkerOptions = {
  visible: true,

  // toggles user interaction
  clickable: false,
  draggable: false,
  crossOnDrag: false,

  // which icon will be used on marker
  icon: MarkerIcon,
};
