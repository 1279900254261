import styled from 'styled-components';

export const StyledCheckinContent = styled.div`
  .title {
    margin-bottom: 16px;
    .input-content {
      padding: 0px;

      input {
        font-size: 38px;
      }
    }
  }

  .description {
    .textarea-content {
      padding: 0px;
    }
  }

  .checkin-map-wrapper {
    position: relative;
    .checkin-loading {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;

      display: flex;
      align-items: center;

      background: linear-gradient(
        30deg,
        rgba(255, 255, 255, 0.2),
        rgba(220, 254, 217, 0.2),
        rgba(210, 209, 255, 0.2),
        rgba(255, 248, 188, 0.2)
      );
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
    }
  }
`;
