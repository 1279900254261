import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import FilterItem from '../FilterItem';
import Button from 'components/molecule/Button';
import SwitchButton from 'components/atom/SwitchButton';
import Text from 'components/atom/Text';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';

import { StyledCommentFilter } from './styles';

export interface CommentFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const CommentFilter: React.FC<CommentFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(!!filter.can_comment);

  const handleClear = () => {
    const newFilter = {
      ...filter,
      comments_count: null,
      comments_operator: null,
      can_comment: null,
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleChangeCanComment = (checked: boolean) => {
    setFilter({ ...filter, can_comment: checked });
  };

  const handleAllChange = () => {
    setFilter({ ...filter, comments_count: null, comments_operator: null });
  };

  const handleEQ0Change = () => {
    setFilter({ ...filter, comments_count: 0, comments_operator: 'eq' });
  };

  const handleLTE100Change = () => {
    setFilter({ ...filter, comments_count: 100, comments_operator: 'lte' });
  };

  const handleLTE500Change = () => {
    setFilter({ ...filter, comments_count: 500, comments_operator: 'lte' });
  };

  const handleGT500Change = () => {
    setFilter({ ...filter, comments_count: 500, comments_operator: 'gt' });
  };

  return (
    <StyledCommentFilter>
      <FilterItem
        title={t('Comments')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={false}
        showCheckAllButton={false}
      >
        <div className="switch-wrapper">
          <SwitchButton
            id="can-comment"
            checked={!!filter.can_comment}
            onChange={(event) => handleChangeCanComment(event.target.checked)}
          />
          <Text as="h6">{t('Comments allowed on content')}</Text>
        </div>

        {!!filter.can_comment && (
          <div className="button-list">
            <Button
              theme="dark-outline"
              rounded="true"
              className={
                filter.comments_count === null &&
                filter.comments_operator === null
                  ? 'selected'
                  : ''
              }
              onClick={handleAllChange}
            >
              {t('All')}
            </Button>
            <Button
              theme="dark-outline"
              rounded="true"
              className={
                filter.comments_count === 0 && filter.comments_operator === 'eq'
                  ? 'selected'
                  : ''
              }
              onClick={handleEQ0Change}
            >
              {t('0')}
            </Button>
            <Button
              theme="dark-outline"
              rounded="true"
              className={
                filter.comments_count === 100 &&
                filter.comments_operator === 'lte'
                  ? 'selected'
                  : ''
              }
              onClick={handleLTE100Change}
            >
              {t('To 100')}
            </Button>
            <Button
              theme="dark-outline"
              rounded="true"
              className={
                filter.comments_count === 500 &&
                filter.comments_operator === 'lte'
                  ? 'selected'
                  : ''
              }
              onClick={handleLTE500Change}
            >
              {t('To 500')}
            </Button>
            <Button
              theme="dark-outline"
              rounded="true"
              className={
                filter.comments_count === 500 &&
                filter.comments_operator === 'gt'
                  ? 'selected'
                  : ''
              }
              onClick={handleGT500Change}
            >
              {t('More than 500')}
            </Button>
          </div>
        )}
      </FilterItem>
    </StyledCommentFilter>
  );
};

export default CommentFilter;
