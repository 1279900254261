import React from 'react';

import Image from 'components/molecule/Image';
import Text from 'components/atom/Text';

import { StyledOrganizationItem } from './styles';

interface OrganizationItemProps {
  avatar: string | null;
  name: string;
  clientName: string;
}

const OrganizationItem: React.FC<OrganizationItemProps> = ({
  avatar,
  name,
  clientName,
}) => {
  return (
    <StyledOrganizationItem className="organization-item">
      <Image src={avatar} width="48px" height="48px" radius="50%" />
      <div className="info">
        <Text as="h6" weight="bold">
          {name}
        </Text>
        <Text as="pre">{clientName}</Text>
      </div>
    </StyledOrganizationItem>
  );
};

export default OrganizationItem;
