import React from 'react';

import Text from 'components/atom/Text';
import {
  CellProps,
  ColumnProps,
  RowProps,
} from 'components/organism/Table/types';

const cols = (numCols: number) => {
  return Array.from(Array(numCols).keys()).map((val: number, idx: number) => {
    return { content: <Text key={idx}>Column {val + 1}</Text> } as ColumnProps;
  });
};

const cells = (numCells: number) => {
  return Array.from(Array(numCells).keys()).map((val: number) => {
    return { content: `Cell ${val + 1}` } as CellProps;
  });
};

const rows = (numRows: number, numCols: number, onClick?: () => void) => {
  return Array.from(Array(numCols).keys()).map(() => {
    return { cells: cells(numRows), onClick } as RowProps;
  });
};

export const generateTableData = (numCols: number, numRows: number) => {
  return { columns: cols(numCols), rows: rows(numCols, numRows) };
};
