import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Tag from 'components/atom/Tag';
import { AvailableTagThemes } from 'components/atom/Tag/types';

import { StyledTags } from './styles';

const Tags: React.FC = () => {
  const { t } = useTranslation();

  const renderTag = (themeName: string, radius?: string, padding?: string) => {
    const theme = themeName as keyof typeof AvailableTagThemes;

    return (
      <Tag radius={radius} padding={padding} theme={theme}>
        <Text as="p">Text</Text>
      </Tag>
    );
  };

  return (
    <StyledTags>
      <Text as="h2" className="title">
        {t('Tags')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                default
              </Text>
              {renderTag('default')}
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                without radius
              </Text>
              {renderTag('primary', '0px')}
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                radius: 20px 20px 0px 0px
              </Text>
              {renderTag('primary', '20px 20px 0 0')}
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                without padding
              </Text>
              {renderTag('primary', '0', '0')}
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                padding: 5px 5px 10px 10px
              </Text>
              {renderTag('primary', undefined, '5px 5px 10px 10px')}
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableTagThemes).map(
              (themeName: string, index: number) => (
                <div key={index} className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {themeName}
                  </Text>
                  {renderTag(themeName)}
                </div>
              ),
            )}
          </div>
        </section>
      </div>
    </StyledTags>
  );
};

export default Tags;
