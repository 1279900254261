import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Range from 'components/molecule/Range';
// import SwitchButton from 'components/atom/SwitchButton';
import Button from 'components/molecule/Button';
import Image from 'components/molecule/Image';

import score0 from 'assets/images/icon-score-0.svg';
import score200 from 'assets/images/icon-score-bronze.svg';
import score400 from 'assets/images/icon-score-silver.svg';
import score600 from 'assets/images/icon-score-gold.svg';
import score800 from 'assets/images/icon-score-emerald.svg';
import score1000 from 'assets/images/icon-score-diamond.svg';

import ThemeState from 'store/slices/theme/types';
import { GamificationConfigProps, AvailableScoreTypes } from './types';

import { StyledGamificationConfig } from './styles';

const scoreIcons: AvailableScoreTypes = {
  '0': score0,
  '200': score200,
  '400': score400,
  '600': score600,
  '800': score800,
  '1000': score1000,
};

const GamificationConfig: React.FC<GamificationConfigProps> = ({
  gamification,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme: ThemeState = useAppSelector((state) => state.theme);
  const { primary_color_200, primary_color_300 } = theme;

  const [values, setValues] = useState<number[]>([
    gamification.participation_points / 10,
  ]);
  // const [canSeeResults, setCanSeeResults] = useState(
  //   gamification.can_see_results,
  // );

  const handleSave = () => {
    onSave({
      can_see_results: false,
      participation_points: values[0] * 10,
    });
    dispatch(closeModal());
  };

  return (
    <StyledGamificationConfig className="gamification-config">
      <Text as="h5" weight="bold" className="modal-title">
        {t('Gamification')}
      </Text>
      <Separator />
      <div className="participation-points-wrapper">
        <Text as="h6" weight="bold" color="grayscale-200">
          {t('Participation points')}
        </Text>
        <Text as="p" color="grayscale-200">
          {t(
            'Stimulate your employees with points earned for participation and interaction with the content.',
          )}
        </Text>

        <div className="points-range">
          <div className="points-icons">
            <Image
              width="28px"
              src={
                scoreIcons[
                  (values[0] * 10).toString() as keyof AvailableScoreTypes
                ]
              }
            />
          </div>
          <div className="range-wrapper">
            <Range
              min={0}
              max={100}
              step={20}
              values={values}
              onChange={(newValues) => setValues(newValues)}
              type="large"
              activeColor={primary_color_300}
              thumbColor={primary_color_200}
            />
          </div>
        </div>

        <div className="points-count">
          <Text as="h6" weight="bold" color="grayscale-200">
            {values[0] * 10}
          </Text>
          <Text as="small" color="grayscale-200">
            {t('points')}
          </Text>
        </div>

        <Separator />

        {/* <div className="see-results-wrapper">
          <SwitchButton
            id="allowed-see-results"
            checked={canSeeResults}
            onChange={() => setCanSeeResults(!canSeeResults)}
          />
          <Text as="h6">{t('Participants can see the result')}</Text>
        </div> */}

        <Button
          theme="primary"
          rounded="true"
          className="save-button"
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      </div>
    </StyledGamificationConfig>
  );
};

export default GamificationConfig;
