import styled from 'styled-components';

export const StyledLoadings = styled.div.attrs(() => ({
  className: 'loadings-page',
}))`
  padding: 16px;

  div > .text {
    margin-bottom: 16px;
  }

  .section-title {
    margin-top: 32px;
  }

  .loadings-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .loadings-content {
      min-width: 100px;
      display: flex;
      text-align: center;
      align-content: center;
      flex-direction: column;
      margin: 20px 20px 10px 10px;
    }
  }
`;
