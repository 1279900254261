import styled from 'styled-components';

export const StyledPaginationControl = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ul {
    display: flex;
    align-items: center;

    li {
      margin: 0px 6px;
      &.next,
      &.previous {
        .icon {
          font-size: 24px;
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    li.selected {
      background: var(--grayscale-400);
      & a {
        color: var(--white-color) !important;
      }
      &:hover {
        background: var(--grayscale-300) !important;
      }
    }

    li:not(.previous):not(.next) {
      width: max-content;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      border: none;
      outline: none;
      position: relative;
      transition: background 0.2s;
      /* padding: 8px; */
      height: 40px;
      min-width: 40px;

      a {
        outline: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & * {
        color: var(--dark-color);
      }

      &:hover {
        background: var(--gray-color);
      }

      &:active {
        background: var(--dark-color);
        & * {
          color: var(--white-color);
        }
      }
    }
  }
`;
