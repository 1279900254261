import styled from 'styled-components';

export const StyledMuralTableStatus = styled.div`
  .status-label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .icon {
      margin-right: 4px;
    }
  }

  .period {
    margin-top: 4px;
  }
`;
