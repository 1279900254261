import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from '../Button';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import { StyledConfirm } from './styles';

interface ConfirmProps {
  title?: string;
  subtitle?: string;
  onConfirm?: () => void;
}

const Confirm: React.FC<ConfirmProps> = ({
  title = '',
  subtitle = '',
  onConfirm,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledConfirm>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {title}
        </Text>
        <Text as="p">{subtitle}</Text>
      </div>

      <Separator />

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button theme="primary-outline" onClick={handleConfirm}>
          {t('Continue')}
        </Button>
      </div>
    </StyledConfirm>
  );
};

export default Confirm;
