import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Range from 'components/molecule/Range';
import MultiRange from 'components/atom/MultiRange';

import ThemeState from 'store/slices/theme/types';

import { StyledRanges } from './styles';

const Ranges: React.FC = () => {
  const { t } = useTranslation();

  const theme: ThemeState = useAppSelector((state) => state.theme);

  const { primary_color_200, primary_color_300 } = theme;

  const [values1, setValues1] = useState<number[]>([50]);
  const [values2, setValues2] = useState<number[]>([50]);

  return (
    <StyledRanges>
      <Text as="h2" className="title">
        {t('Ranges')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Types')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Normal')}
              </Text>
              <div className="section-group">
                <Range
                  type="normal"
                  activeColor={primary_color_300}
                  thumbColor={primary_color_200}
                  min={0}
                  max={100}
                  values={values1}
                  onChange={(values) => setValues1(values)}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Large')}
              </Text>
              <div className="section-group">
                <Range
                  type="large"
                  activeColor={primary_color_300}
                  thumbColor={primary_color_200}
                  min={0}
                  max={100}
                  values={values2}
                  onChange={(values) => setValues2(values)}
                />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Multi range')}
              </Text>
              <div className="section-group">
                <MultiRange />
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Multi range large')}
              </Text>
              <div className="section-group">
                <MultiRange type="large" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledRanges>
  );
};

export default Ranges;
