import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import MainRestrictedLayout from 'layouts/RestrictedLayouts/MainRestrictedLayout';

import AuthLayout from 'layouts/PublicLayouts/AuthLayout';
import DocumentationLayout from 'layouts/PublicLayouts/DocumentationLayout';
import ErrorLayout from 'layouts/PublicLayouts/ErrorLayout';
import CreationLayout from 'layouts/RestrictedLayouts/CreationLayout';
import DashboardLayout from 'layouts/RestrictedLayouts/DashboardLayout';

import Alerts from 'pages/documentation/Alerts';
import Avatars from 'pages/documentation/Avatars';
import Buttons from 'pages/documentation/Buttons';
import Cards from 'pages/documentation/Cards';
import Checkboxes from 'pages/documentation/Checkboxes';
import Colors from 'pages/documentation/Colors';
import CopyToClipboards from 'pages/documentation/CopyToClipboards';
import Dropdowns from 'pages/documentation/Dropdowns';
import EmptyMessages from 'pages/documentation/EmptyMessages';
import DocumentationHome from 'pages/documentation/Home';
import Icons from 'pages/documentation/Icons';
import Images from 'pages/documentation/Images';
import Inputs from 'pages/documentation/Inputs';
import Loadings from 'pages/documentation/Loadings';
import Modals from 'pages/documentation/Modals';
import Selects from 'pages/documentation/Selects';
import Tables from 'pages/documentation/Tables';
import Tags from 'pages/documentation/Tags';
import Textareas from 'pages/documentation/Textareas';
import Toasters from 'pages/documentation/Toasters';
import Tooltips from 'pages/documentation/Tooltips';
import Typography from 'pages/documentation/Typography';
import Editors from 'pages/documentation/Editors';
import DatePickers from 'pages/documentation/DatePickers';
import Ranges from 'pages/documentation/Ranges';
import Collapses from 'pages/documentation/Collapses';
import Maps from 'pages/documentation/Maps';

import Error403 from 'pages/general/Error403';
import Error404 from 'pages/general/Error404';
import Error500 from 'pages/general/Error500';
import Login from 'pages/general/Login';
import RedirectToLegacy from 'pages/general/RedirectToLegacy';

import Dashboard from 'pages/board/Dashboard';
import Mural from 'pages/board/Mural';
import MuralContent from 'pages/board/MuralContent';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          {/* Public routes */}
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path="/" element={<Login />} />
          </Route>

          <Route
            element={
              <ErrorLayout>
                <Outlet />
              </ErrorLayout>
            }
          >
            <Route path="*" element={<Error404 />} />
            <Route path="/error-403" element={<Error403 />} />
            <Route path="/error-500" element={<Error500 />} />
          </Route>

          <Route
            element={
              <DocumentationLayout>
                <Outlet />
              </DocumentationLayout>
            }
          >
            <Route path="/doc/home" element={<DocumentationHome />} />
            <Route path="/doc/typography" element={<Typography />} />
            <Route path="/doc/icons" element={<Icons />} />
            <Route path="/doc/buttons" element={<Buttons />} />
            <Route path="/doc/colors" element={<Colors />} />
            <Route path="/doc/cards" element={<Cards />} />
            <Route path="/doc/alerts" element={<Alerts />} />
            <Route path="/doc/toasters" element={<Toasters />} />
            <Route path="/doc/loadings" element={<Loadings />} />
            <Route path="/doc/inputs" element={<Inputs />} />
            <Route path="/doc/textareas" element={<Textareas />} />
            <Route path="/doc/tooltips" element={<Tooltips />} />
            <Route
              path="/doc/copy-to-clipboards"
              element={<CopyToClipboards />}
            />
            <Route path="/doc/dropdowns" element={<Dropdowns />} />
            <Route path="/doc/selects" element={<Selects />} />
            <Route path="/doc/checkboxes" element={<Checkboxes />} />
            <Route path="/doc/tags" element={<Tags />} />
            <Route path="/doc/images" element={<Images />} />
            <Route path="/doc/tables" element={<Tables />} />
            <Route path="/doc/empty-messages" element={<EmptyMessages />} />
            <Route path="/doc/modals" element={<Modals />} />
            <Route path="/doc/avatars" element={<Avatars />} />
            <Route path="/doc/editors" element={<Editors />} />
            <Route path="/doc/datepickers" element={<DatePickers />} />
            <Route path="/doc/ranges" element={<Ranges />} />
            <Route path="/doc/collapses" element={<Collapses />} />
            <Route path="/doc/maps" element={<Maps />} />
          </Route>

          {/* Restricted routes */}
          <Route
            element={
              <MainRestrictedLayout>
                <Outlet />
              </MainRestrictedLayout>
            }
          >
            <Route
              element={
                <DashboardLayout>
                  <Outlet />
                </DashboardLayout>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/mural" element={<Mural />} />
              <Route path="/redirect" element={<RedirectToLegacy />} />
            </Route>

            <Route
              element={
                <CreationLayout>
                  <Outlet />
                </CreationLayout>
              }
            >
              <Route
                path="/mural/content/:type/:subtype/:contentId"
                element={<MuralContent />}
              />
              <Route
                path="/mural/content/:type/:subtype"
                element={<MuralContent />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
