import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/molecule/Checkbox';
import FilterItem from '../FilterItem';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';
import { EditorialProps } from 'apis/board/types';

import { StyledEditorialFilter } from './styles';

export interface EditorialFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
  editorials: EditorialProps[];
}

const EditorialFilter: React.FC<EditorialFilterProps> = ({
  filter,
  setFilter,
  editorials,
}) => {
  const { t } = useTranslation();

  const totalFilterItems = editorials.length;

  const [isOpen, setIsOpen] = useState(filter.editorial.length > 0);

  const handleCheck = (checked: boolean, item: string) => {
    const newEditorial = [...filter.editorial];

    if (checked) {
      newEditorial.push(item);
    } else {
      newEditorial.splice(newEditorial.indexOf(item), 1);
    }

    setFilter({ ...filter, editorial: newEditorial });
  };

  const handleClear = () => {
    const newFilter = { ...filter, editorial: [] };
    setFilter(newFilter);
  };

  const handleCheckAll = () => {
    const newFilter = {
      ...filter,
      editorial: editorials.map((editorial) => editorial.id),
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  return (
    <StyledEditorialFilter>
      <FilterItem
        title={t('Editorial line')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={filter.editorial.length > 0}
        onClear={handleClear}
        showCheckAllButton={
          isOpen && filter.editorial.length < totalFilterItems
        }
        onCheckAll={handleCheckAll}
      >
        {editorials.map((editorial, index) => (
          <Checkbox
            key={index}
            text={editorial.title}
            checked={filter.editorial.indexOf(editorial.id) !== -1}
            onChange={(event) =>
              handleCheck(event.target.checked, editorial.id)
            }
          />
        ))}
      </FilterItem>
    </StyledEditorialFilter>
  );
};

export default EditorialFilter;
