import React from 'react';
import { useTranslation } from 'react-i18next';

// import { useAppDispatch } from 'store/hooks';
// import { setLanguage } from 'store/slices/client';

import Header from 'components/atom/Header';
import Text from 'components/atom/Text';
import EmptyMessage from 'components/molecule/EmptyMessage';

import { StyledDashboard } from './styles';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();

  // const handleChangeLanguage = (value: string) => {
  //   i18n.changeLanguage(value);
  //   dispatch(setLanguage(value));
  // };

  return (
    <StyledDashboard>
      <Header
        leftSideContent={
          <div style={{ display: 'flex' }}>
            <Text as="h3" weight="700" color="grayscale-400">
              {t('Dashboard')}
            </Text>
            {/* <select
              value={i18n.language}
              onChange={(event) => handleChangeLanguage(event.target.value)}
            >
              <option value="en">EN</option>
              <option value="pt-br">PT-BR</option>
            </select> */}
          </div>
        }
      />
      <div className="page-content">
        <EmptyMessage showImage title={t('No content in this community')} />
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
