import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';

import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';

import { StyledModals } from './styles';

const ExampleModalContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="example-modal-content">
      <Text as="h1">{t('This is an title')}</Text>
      <Text as="p">{t('This is an content')}</Text>
    </div>
  );
};

const Modals: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOnAfterClose = () => {
    return window.alert('After close called!');
  };

  const renderButtonModalExample = (
    width: string,
    disableBackgroundClick: boolean,
    onAfterClose: () => void,
    hideCloseButton: boolean,
  ) => {
    const showModal = () => {
      dispatch(
        setModalView({
          show: true,
          width,
          content: <ExampleModalContent />,
          disableBackgroundClick,
          onAfterClose,
          hideCloseButton,
        }),
      );
    };

    return (
      <Button theme="primary" onClick={showModal}>
        {t('Click to open')}
      </Button>
    );
  };

  return (
    <StyledModals>
      <Text as="h2" className="title">
        {t('Modals')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Basic usage')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              {renderButtonModalExample('700px', false, () => undefined, false)}
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Modal sizes')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                200px
              </Text>
              {renderButtonModalExample('200px', false, () => undefined, false)}
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                700px
              </Text>
              {renderButtonModalExample('700px', false, () => undefined, false)}
            </div>
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                900px
              </Text>
              {renderButtonModalExample('900px', false, () => undefined, false)}
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Without close button')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              {renderButtonModalExample('700px', false, () => undefined, true)}
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Without "click on background" to close')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              {renderButtonModalExample('700px', true, () => undefined, false)}
            </div>
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('With callback after closing modal')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              {renderButtonModalExample(
                '700px',
                false,
                handleOnAfterClose,
                false,
              )}
            </div>
          </div>
        </section>
      </div>
    </StyledModals>
  );
};

export default Modals;
