import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Collapse from 'components/molecule/Collapse';

import { StyledCollapses } from './styles';

const Collapses: React.FC = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCollapses>
      <Text as="h2" className="title">
        {t('Collapses')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Types')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Normal')}
              </Text>
              <div className="section-group">
                <Collapse
                  isOpen={isOpen}
                  setIsOpen={() => setIsOpen(!isOpen)}
                  collapseHeader="Collapse button"
                >
                  <Text as="h6" color="grayscale-200">
                    {t('Content')}
                  </Text>
                  <Text as="h6" color="grayscale-200">
                    {t('Content')}
                  </Text>
                </Collapse>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Large')}
              </Text>
              <div className="section-group">{/* <Collapse /> */}</div>
            </div>
          </div>
        </section>
      </div>
    </StyledCollapses>
  );
};

export default Collapses;
