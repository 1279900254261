import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { StyledButtons } from './styles';
import { AvailableSizeTypes, AvailableThemeTypes } from 'styles/button-themes';

const Buttons: React.FC = () => {
  const { t } = useTranslation();

  const renderButton = (
    label: string,
    sizeName: string,
    themeName: string,
    index: number,
  ) => {
    const style =
      themeName === 'link-white' ? { background: 'var(--dark-color)' } : {};
    const theme = themeName as keyof typeof AvailableThemeTypes;
    const size = sizeName as keyof typeof AvailableSizeTypes;

    return (
      <div className="section-content" key={index} style={style}>
        <Text as="h6" color="grayscale-200">
          {label}
        </Text>
        <div className="section-group">
          <Button theme={theme} size={size}>
            Button
          </Button>
          <Button theme={theme} size={size} disabled>
            Button
          </Button>
        </div>
      </div>
    );
  };

  const renderButtonSizes = (sizeName: string, index: number) => {
    return renderButton(sizeName, sizeName, 'primary', index);
  };

  const renderButtonThemes = (themeName: string, index: number) => {
    return renderButton(themeName, 'normal', themeName, index);
  };

  return (
    <StyledButtons>
      <Text as="h2" className="title">
        {t('Buttons')}
      </Text>
      <div className="buttons-wrapper">
        <section>
          <Text as="h4" className="section-title">
            {t('Themes')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableThemeTypes).map(renderButtonThemes)}
          </div>
        </section>

        <section>
          <Text as="h4" className="section-title">
            {t('Sizes')}
          </Text>
          <Separator />

          <div className="section-contents">
            {Object.keys(AvailableSizeTypes).map(renderButtonSizes)}
          </div>
        </section>
        <section>
          <Text as="h4" className="section-title">
            {t('Buttons with icons')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Left icon')}
              </Text>
              <div className="section-group">
                <Button theme="primary" leftIcon="dashboard-fill">
                  Button
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Right icon')}
              </Text>
              <div className="section-group">
                <Button theme="primary" rightIcon="dashboard-fill">
                  Button
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Children icon')}
              </Text>
              <div className="section-group">
                <Button theme="primary">
                  <Icon name="dashboard-fill" color="white-color" />
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Text as="h4" className="section-title">
            {t('Radius')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Rounded')}
              </Text>
              <div className="section-group">
                <Button theme="primary" rounded="true">
                  Button
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Custom radius (0px)')}
              </Text>
              <div className="section-group">
                <Button theme="primary" radius="0px">
                  Button
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Rounded + Children Icon + Small size')}
              </Text>
              <div className="section-group">
                <Button theme="primary" rounded="true" size="small">
                  <Icon name="dashboard-fill" color="white-color" />
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Text as="h4" className="section-title">
            {t('Notification count')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Number')}
              </Text>
              <div className="section-group">
                <Button theme="primary-flat" badge="10">
                  Button
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Text')}
              </Text>
              <div className="section-group">
                <Button theme="primary-flat" badge="hello">
                  Button
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Text as="h4" className="section-title">
            {t('Link and anchor buttons')}
          </Text>
          <Separator />

          <div className="section-contents">
            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Link button')}
              </Text>
              <div className="section-group">
                <Button theme="primary" to="/buttons">
                  Button
                </Button>
              </div>
            </div>

            <div className="section-content">
              <Text as="h6" color="grayscale-200">
                {t('Anchor button')}
              </Text>
              <div className="section-group">
                <Button theme="primary" href="https://google.com">
                  Button
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledButtons>
  );
};

export default Buttons;
