import styled from 'styled-components';

const fontSizes: { [key: string]: string } = {
  h1: '38px',
  h2: '32px',
  h3: '28px',
  h4: '24px',
  h5: '20px',
  h6: '18px',
  p: '16px',
  pre: '14px',
  small: '12px',
};
interface StyledTextProps {
  weight?: string;
  color?: string;
  family?: string;
  italic?: boolean;
  as?: string;
  align?: string;
}

export const StyledText = styled.div<StyledTextProps>`
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  font-weight: ${(props) => props.weight || '500'};
  color: var(--${(props) => props.color || 'dark-color'});
  font-size: ${(props) => (props && props.as ? fontSizes[props.as] : '16px')};
  font-family: ${(props) => props.family || 'Hind, sans-serif'};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  text-align: ${(props) => props.align || 'start'};
`;
