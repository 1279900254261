import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';

import StyledDocumentationHome from './styles';

const Home: React.FC = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <StyledDocumentationHome>
      <Text as="h1" className="title">
        Homepage
        <div>
          <select onChange={handleLanguageChange}>
            <option value="pt">PT-BR</option>
            <option value="en">EN</option>
          </select>
        </div>
      </Text>
    </StyledDocumentationHome>
  );
};

export default Home;
