import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/molecule/Input';
import Dropdown from 'components/molecule/Dropdown';
import Button from 'components/molecule/Button';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import Text from 'components/atom/Text';
import Loading from 'components/molecule/Loading';

import { SearchProps, SearchResult } from './types';

import { StyledSearch } from './styles';

const Search: React.FC<SearchProps> = ({
  placeholder,
  results,
  onLoad,
  onClick,
  loading,
  hasMore,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      if (scrollElement) {
        scrollElement.scrollTop = 0;
      }
      onLoad(value, 1);
      setPage(1);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const handleFocus = () => {
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }

    onLoad(search, page);
    setOpen(true);
    setPage(1);
  };

  const handleBlur = () => {
    setOpen(false);
    setPage(1);
  };

  const loadMore = () => {
    onLoad(search, page + 1, true);
    setPage(page + 1);
  };

  const handleItemClick = (item: SearchResult) => {
    onClick(item);
  };

  return (
    <StyledSearch className="search">
      <Input
        placeholder={placeholder || t('Search')}
        icon="search"
        value={search}
        onChange={handleSearch}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Dropdown
        open={open}
        setOpen={() => undefined}
        toggleElement={<div></div>}
        toggleButtonTheme="link-dark"
        contentPlace="right"
      >
        <div
          className="results-wrapper default-scroll"
          ref={(currentRef) => setScrollElement(currentRef)}
        >
          {results.length > 0 && (
            <div className="results">
              {results.map((item, index) => (
                <Button
                  key={index}
                  theme="link-page-button-gray-primary"
                  radius="0px"
                  onClick={() => handleItemClick(item)}
                  disabled={item.disabled}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          )}

          {!loading && results.length === 0 && (
            <Text as="h6">{t('No options')}</Text>
          )}

          {loading && <Loading type="bubbles" />}
        </div>
      </Dropdown>

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={loadMore}
        disabled={loading || !hasMore}
      />
    </StyledSearch>
  );
};

export default Search;
