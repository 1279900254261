import dayjs from 'dayjs';

export const apiDateToDayjsFormat = (date: string) => {
  return date.replaceAll(/T/gi, ' ').replaceAll(/Z/gi, '');
};

export const dateAndTimeToApiDate = (date: Date, time?: string): string => {
  if (!date || isNaN(date.getTime())) return '';
  return `${dayjs(date).format('YYYY-MM-DD')}T${time ? time : '00:00'}`;
};

export const isValidTimeFormat = (time: string): boolean => {
  const timeRegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
  return timeRegExp.test(time);
};
