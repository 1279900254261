import React from 'react';
import { Range as ReactRange, getTrackBackground } from 'react-range';

import Icon from 'components/atom/Icon';

import { StyledRange } from './styles';

const AvailableRangeTypes = {
  normal: 'normal',
  large: 'large',
};

interface RangeProps {
  type: keyof typeof AvailableRangeTypes;
  activeColor: string;
  thumbColor: string;
  step?: number;
  min: number;
  max: number;
  values: number[];
  onChange: (values: number[]) => void;
}

const Range: React.FC<RangeProps> = ({
  type,
  activeColor,
  thumbColor,
  step = 0.1,
  min,
  max,
  values,
  onChange,
}) => {
  return (
    <StyledRange className={`range ${type}`}>
      <ReactRange
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={(values) => onChange(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="outside-track"
            style={{
              ...props.style,
            }}
          >
            <div
              ref={props.ref}
              className="inside-track"
              style={{
                background: getTrackBackground({
                  values: values,
                  colors: [activeColor, '#EAEAF0'],
                  min: 0,
                  max: 100,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className="thumb"
            style={{
              ...props.style,
              backgroundColor: thumbColor,
            }}
          >
            {type === 'large' && <Icon name="drag" color="white-color" />}
          </div>
        )}
      />
    </StyledRange>
  );
};

export default Range;
