import styled from 'styled-components';

export const StyledMap = styled.div`
  .map-content {
    position: relative;

    .hide {
      display: none;
    }

    .map-zoom-controls {
      position: absolute;
      top: 32px;
      left: 32px;
      display: flex;
      align-items: center;

      & .button-zoom-in {
        z-index: 1;
        border-radius: 8px 0px 0px 8px;
        border-right: 1px solid var(--white-color);
      }

      & .button-zoom-out {
        z-index: 1;
        border-radius: 0px 8px 8px 0px;
      }
    }

    .map-container {
      width: 100% !important;
      min-height: 420px;
      border-radius: 8px;
    }

    .map-search-controls {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0px;
      width: 100%;
      background: rgba(38, 43, 83, 0.25);
      padding: 32px;
      border-radius: 0px 0px 8px 8px;

      & > div {
        margin-right: 14px;
        width: 100%;
      }

      .input {
        border-radius: 8px;
        width: 100%;
      }
    }
  }
`;
