import { getI18NextLanguage } from 'services/language';

const getNumberFormatLocale = (definedLanguage?: string): string[] => {
  return [definedLanguage || getI18NextLanguage() || 'en-US', 'en-US'];
};

export const numberFormatter = (value: number, language?: string): string => {
  return Intl.NumberFormat(getNumberFormatLocale(language)).format(value);
};

export const currencyNumberFormatter = (
  value: number,
  currency: string,
  language?: string,
): string => {
  // currency param: https://www.currency-iso.org/en/home/tables/table-a1.html
  return Intl.NumberFormat(getNumberFormatLocale(language), {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  }).format(value);
};

export const abbreviateNumberFormatter = (
  value: number,
  language?: string,
): string => {
  return Intl.NumberFormat(getNumberFormatLocale(language), {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);
};
