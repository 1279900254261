import styled from 'styled-components';

export const StyledSchedulePreview = styled.div`
  width: 100%;

  .card {
    width: 100% !important;
    padding: 16px !important;

    .card-header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .icon {
        font-size: 22px;
        min-width: 44px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        background: var(--warning-color);
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-bottom: 4px;
        font-family: monospace;
        font-size: 14px;
      }
    }
  }

  .loading {
    margin-bottom: 42px;
    height: 220px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
