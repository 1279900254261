import styled from 'styled-components';

interface StyledAvatarProps {
  size: string;
}

export const StyledAvatar = styled.div<StyledAvatarProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  position: relative;

  .image {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-radius: 50%;
  }

  .icon-avatar {
    width: 100%;
    height: 100%;
    background: var(--grayscale-300);
    opacity: 0.15;
    border-radius: 50%;
  }

  .icon {
    position: absolute;
    color: var(--grayscale-300);
    font-size: 22px;
    top: calc(50% - 11px);
    left: calc(50% - 11px);
    opacity: 1;
  }
`;
