import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { StyledFilterItem } from './styles';

interface FilterItemProps {
  title: string;
  isOpen: boolean;
  onToggleIsOpen: () => void;
  showClearButton: boolean;
  onClear?: () => void;
  showCheckAllButton: boolean;
  onCheckAll?: () => void;
  children: React.ReactNode;
}

const FilterItem: React.FC<FilterItemProps> = ({
  title,
  isOpen,
  onToggleIsOpen,
  showClearButton,
  onClear,
  showCheckAllButton,
  onCheckAll,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <StyledFilterItem className="filter-item">
      <div className="filter-header">
        <div className="filter-toggle">
          <Button
            theme={!isOpen ? 'link-primary' : 'link-danger'}
            size="small"
            onClick={onToggleIsOpen}
          >
            <Icon name={isOpen ? 'less' : 'add'} />
          </Button>
          <Text as="h5">{title}</Text>
        </div>
        <div className="filter-actions">
          {showClearButton && (
            <Button theme="link-gray-primary" size="small" onClick={onClear}>
              {t('Clear')}
            </Button>
          )}
          {showCheckAllButton && (
            <Button theme="link-gray-primary" size="small" onClick={onCheckAll}>
              {t('Check all')}
            </Button>
          )}
        </div>
      </div>
      <div className={`filter-content ${isOpen ? 'open' : ''}`}>{children}</div>
    </StyledFilterItem>
  );
};

export default FilterItem;
