import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/molecule/Select';
import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Dropdown from 'components/molecule/Dropdown';
import Loading from 'components/molecule/Loading';

import {
  SelectProps,
  SelectedOptionValueType,
} from 'components/molecule/Select/types';

import { StyledOrganizationSelect } from './styles';

const OrganizationSelect: React.FC<SelectProps> = ({
  value,
  setValue,
  options,
  loading,
}) => {
  const { t } = useTranslation();

  let currentOptions = undefined;
  const currentOption = value && 'value' in value ? value : null;
  const isMultiOption = options && options.length > 1;

  if (options && currentOption) {
    currentOptions = options.filter(
      (option) => option.value !== currentOption.value,
    );
  }

  const [open, setOpen] = useState(false);

  const handleOpen = (openDropdown: boolean) => {
    if (!isMultiOption) {
      return;
    }
    setOpen(openDropdown);
  };

  const handleChangeValue = (newValue: SelectedOptionValueType) => {
    setValue(newValue);
    setOpen(false);
  };

  return (
    <StyledOrganizationSelect
      className={`organization-select ${!isMultiOption ? 'no-clickable' : ''}`}
    >
      <Dropdown
        open={open}
        setOpen={handleOpen}
        toggleElement={
          <div className="selected-organization">
            {currentOption && !loading ? (
              <>
                {currentOption.label}
                {isMultiOption && (
                  <Icon name="drop" color="primary-color-300" />
                )}
              </>
            ) : (
              <Loading type="bubbles" />
            )}
          </div>
        }
        dropdownContentColor="gray-color"
        toggleButtonTheme="primary-flat"
        contentPlace="left"
      >
        <Select
          value={null}
          setValue={handleChangeValue}
          options={currentOptions}
          className="select-1"
          theme="gray"
          placeholder={
            <div className="search-placeholder">
              <Icon name="search" color="dark-color" />
              <Text color="grayscale-200">{t('Search by name')}</Text>
            </div>
          }
          isSearchable
          menuIsOpen
        />
      </Dropdown>
    </StyledOrganizationSelect>
  );
};

export default OrganizationSelect;
